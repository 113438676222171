import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'nu-stock';
  isFromBrowser:boolean = false;

  constructor(@Inject(PLATFORM_ID) private platform: Object,private router : Router) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platform)) {
      this.router.events.subscribe((val) => {
        // see also 
        $('.modal').modal('hide') // closes all active pop ups.
        $('.modal-backdrop').remove() // removes the grey overlay. 
      });
      //Initialise your charets here
      // alert("");
      this.isFromBrowser = true;
      // this.commonService.generateSession();
    }

  }
}
