<div class="pageWrap">
    <div class="signup_pg sgn_rpnvs signUp_repnsvs">
        <div class="container-fluid">
            <div class="row align-items-start justify-content-center">
                <div class="col-md-8 sticky_col mt-3">
                    <div class="video_thns mt-5 text-center">
                        <!-- <video loading="lazy" muted="muted" src="https://cdnl.iconscout.com/lottie/premium/thumb/payment-successful-5711535-4773520.mp4" autoplay="autoplay" loop="loop" >  </video> -->
                        <img src="assets/images/6134225.jpg" alt="" class="image-fluid">
                        <div class="div_pyment_text mt-4">
                            <h6 class="mb-3">Your Payment is successfull!</h6>
                            <p class="mb-4">Thank you for your payment.An automated payment receipt will be sent to your registered email.</p>
                            <a routerLink="/user/db-orders" class="back_hm_btn">
                                <i class="fas fa-home me-1"></i> Back to Home 
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
