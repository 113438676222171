import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HomePageContentResponse, ResponsePricing, ResponseSubscriptionPlan } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {
  env = environment

  IMAGE_COVER=environment.IMAGE_COVER
  subscriptionData: any[] = []
  pricingdata: any[] = [];
  monthlySubscriptionPlans: any[] = [];
  subscriptionIDforMonthly:any;
  plan:any;
  monthlyPlanID:any;
  PlanID:any;
  subscriptionID:any;
  smallPlan :any [] =[];
  constructor(
    private service: HttpService
  ) { }

  ngOnInit(): void {
    this.getSubscriptionPlanFxn()
    this.getPricingFxn()
    this.getHomePageContent();
  }

  getHomePageContent():void {
    this.service.getHomePageContent({}).subscribe((resp:HomePageContentResponse) => {
      
    
     
      this.prestigious_clients = resp.home_data.prestigious_clients
      
   

    })
  }
  prestigious_clients:any[]=[]
  
  getSubscriptionPlanFxn() {
    this.service.getSubscriptionPlan().subscribe((res: ResponseSubscriptionPlan) => {
      this.subscriptionData = res.data;
      this.subscriptionDataForSmallPlan=this.subscriptionData[0]?.subscription_types?.filter((res:any)=> { 
        if(this.selectPicSize=='small' ){
          return res.subscription_type == 'small'
        }else{
          return res.subscription_type == 'large'
        }
       })

      this.monthlySubscriptionPlans = this.subscriptionData[1]?.subscription_types; //all types of monthely subscription
      this.subscriptionIDforMonthly = this.subscriptionData[1]?._id   //monthely subscription _id
      this.plan = this.monthlySubscriptionPlans[0]?.plan;  //monthely subscription[0] index all plans
      
      this.smallPlan = this.subscriptionDataForSmallPlan[0]?.plan;
      
      this.monthlyPlanID = this.plan[0]?._id;
      this.subscriptionID = this.subscriptionData[0]?._id 
      this.PlanID= this.subscriptionDataForSmallPlan[0]?.plan[0]?._id//normal subscription _id
      
    })
  }
  subscriptionDataForSmallPlan:any[]=[]
 
  getPricingFxn() {
    this.service.getPricing().subscribe((res: ResponsePricing) => {
      this.pricingdata = res.data;
    })
  }
selectSize(event:any,type:any){
  if(type==0){
    this.selectPicSize=event?.target?.value
   this.subscriptionDataForSmallPlan=this.subscriptionData[0]?.subscription_types?.filter((res:any)=> { 
        if(this.selectPicSize=='small'){
          return res.subscription_type == 'small'
        }else{
          return res.subscription_type == 'large'
        }
       })
       this.smallPlan = this.subscriptionDataForSmallPlan[0].plan;
  }else{
    this.selectPicSize1=event?.target?.value
    const obj={
      target:{
        value:this.subtype
      }
    }
    this.monthlyplan(obj)
    
  }
 
  
}

selectPicSize='small'
selectPicSize1='small'
subtype:any='Annual, billed monthly'

  monthlyplan(event:any){
    this.subtype=event?.target?.value
  this.monthlySubscriptionPlans.forEach(element => {
    if(element?.name === event?.target?.value && element?.subscription_type == this.selectPicSize1 ){
       this.plan = element?.plan;    
    }

  });

  }

  tabClickForMonthly(event:any){
    this.plan.forEach((element:any) => {
      if(event.tab.textLabel == element?.downloads)
      {
        this.monthlyPlanID = element?._id;
      }

    })
   }
 
  tabClickForOthers(event:any){
    this.smallPlan.forEach((element:any) => {
      if(event.tab.textLabel == element?.downloads)
      {
        this.PlanID = element?._id;
      }

    })
  }
  loginType:boolean=false
  subscriptionPurchaseFunction(){
    if(!this.service.getToken()){
      
      const obj={
        loginType:true
      }
      this.service.LoggedType(JSON.stringify(obj))
     return
    }
      
    let obj = {
      subscription_id:this.subscriptionID ,
      plan_id: this.PlanID,
    }
    


     this.service.getPaymentLinkOfSubscription(obj).subscribe((res:any)=>{
      if(res.code==200){
        window.open(res.url)
      }

    },(err:any)=>{

      this.service.showErrorMessage({
        message:err
      })
    })


  }


  subscriptionPurchaseFunctionMonthly(){
    if(!this.service.getToken()){
      
      const obj={
        loginType:true
      }
      this.service.LoggedType(JSON.stringify(obj))
     return
    }
    let obj = {
      subscription_id:this.subscriptionIDforMonthly ,
      plan_id: this.monthlyPlanID,
    }
    this.service.getPaymentLinkOfSubscription(obj).subscribe((res:any)=>{
      if(res.code==200){
        window.open(res.url)
      }

    },(err:any)=>{

      this.service.showErrorMessage({
        message:err
      })
    })
    

  }
 
}
