<div class="main_home_wraper">
  <app-header-cmn></app-header-cmn>

  <div class="inner_wrap_sectn">
    <section class="modlevent_details">
      <div class="custom_container">
        <div class="evnttop_dtl position-relative">
          <div class="evntmn_img">
            <img
              [src]="env.EVENTCOVER+this.event?.cover_image"
              class="img-fluid"
            />
          </div>
          <div class="evntmn_cntnt">
            <div class="row justify-content-between align-items-center">
              <div class="col-md-6">
                <div class="evntttl_lctn">
                  <h2>{{this.event?.title}}</h2>
                  <p class="evnt_ognzr">By Nu Stock Images</p>
                  <div class="evnt_lctn">
                    <i class="fas fa-map-marker-alt mr-2"></i>
                    <span
                      >{{this.event?.address}}</span
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="dtime_card">
                  <h5>Date & Time</h5>
                  <p>{{this.event?.datetime | date:'MMM d, y, h:mm:ss a'}}</p>
                  <!-- <a href="#" class="cntct_lnk d-block">+ Add to Calendar</a> -->
                  <a
                    class="btn btn_theme_filled d-block"
                    routerLink="/eventForm/{{this.eventId}}"
                    >Apply Now</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="evntall_dtls">
          <div class="row">
            <div class="col-md-8">
              <div class="evntlft_wrp">
                <div class="evntxt_div">
                  <h5>Description</h5>
                  <p>
                    {{this.event?.description}}
                  </p>
                </div>
                <div class="evntxt_div">
                  <h5>Requirements</h5>
                  <ul class="evnt_rqrmnts" *ngFor="let x of this.event?.requirments">
                    <li>
                      {{x}}
                    </li>
                    
                  </ul>
                </div>
                <div class="evntxt_div">
                  <h5>Hours</h5>
                  <p><strong>Morning Hours:</strong> {{this.event?.morning_hours?.start | date:'shortTime'}} - {{this.event?.morning_hours?.end  | date:'shortTime'}}</p>
                  <p><strong>Evening Hours:</strong>{{this.event?.evening_hours?.start  | date:'shortTime'}} - {{this.event?.evening_hours?.end  | date:'shortTime'}}</p>
                </div>
                <div class="evntxt_div">
                  <h5>How can I contact the organizer with any question?</h5>
                  <p>
                    If you have any questions, feel free to
                    <a routerLink="/contact-us" class="cntct_lnk">contact us</a> or refer the<a routerLink="/faq">FAQ section</a>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="evntlft_wrp evntrt_wrp">
                <div class="evntxt_div">
                  <h5>Event Location</h5>
                  <div class="evnt_loctnmap">
                    <!-- <agm-map [latitude]="this.event?.coordinates."
                        [longitude]="long" (mapReady)="mapReadyHandler($event)">
                        <agm-marker latitude=""
                            longitude=""></agm-marker>
                    </agm-map> -->


                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.207645804598!2d77.67627651500257!3d13.022444992250252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae10e130d385f5%3A0xad013f80ae4eff6b!2s1st%20A%20Cross%20Rd%2C%20Akshaya%20Nagar%201st%20Block%2C%20Akshya%20Nagar%2C%20Ramamurthy%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560016!5e0!3m2!1sen!2sin!4v1658400342052!5m2!1sen!2sin"
                      width="100%"
                      height="300px"
                      style="border: 0"
                      allowfullscreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                  <h4>{{this.event?.title}}</h4>
                  <p>
                   {{this.event?.address}}
                  </p>
                </div>
                <div class="evntxt_div">
                  <h5>Share With Friends</h5>
                  <ul class="shr_icns d-flex">
                    <li>
                      <a href="https://www.fb.com" class="fa fa-facebook fontawsome"></a>

                    </li>
                    <li>
                      <a href="https://www.twitter.com" class="fa fa-twitter fontawsome"></a>

                    </li>
                    <li>
                      <a href="https://www.google.com" class="fa fa-google fontawsome"></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <app-footer></app-footer>
</div>
