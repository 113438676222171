import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CategoriesImagesResponse, Category, CategoryResponse, HomePageContentResponse, ResponsePricing } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Title,Meta } from '@angular/platform-browser';

declare let $: any

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent implements OnInit {
  env = environment
  sizes: any[] = []
  pricingdata: any[] = []
  all_categories !: Category[];
  prestigious_clients: string[] = []
  categories_ids: any[] = [];
  categories_with_images: Category[] = []
  catg_crsl: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    },
    nav: true
  }

  clients_slider: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    margin: 20,
    nav: false,
    center: true,
    autoplay: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 6
      }
    }

  }
  home_content: any;
  latest_uploads: any;
  selectedpicture: any;
  selectedsize: any = '';

  @ViewChild('refModal', { static: true })
  myModal!: ElementRef<any>;
  cartlength: any;
  form2: FormGroup
  serachdata = ''
  constructor(private titleService: Title,private meta: Meta,private service: HttpService, private router: Router, private loader: NgxUiLoaderService, private fb: FormBuilder) {
    this.serachdata = ''
    this.form2 = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],

    })



    
  }
  

  ngOnInit(): void {


      // this.meta.updateTag({ name: 'description', content: "12345Download the best royalty-free images, pictures, vectors and photos at NuStock. Transform your vision with simple licensing and pricing at NuStock."});
      // this.meta.updateTag({ property: 'og:description', content: "Download the best royalty-free images, pictures, vectors and photos at NuStock. Transform your vision with simple licensing and pricing at NuStock."});
      // this.titleService.setTitle("NuStock: Stock Images, Pictures, Photos and Vectors")
      
    this.getHomePageContent();
    this.getAllCategories();
    this.getCart()
    this.getBlogs()


  }
  Blog: any[] = []
  getBlogs() {
    const obj = {
      page: 1,
      per_page: 3

    }
    this.service.getblogs(obj).subscribe((res) => {
      this.Blog = res
    }, (err) => {
      this.service.showErrorMessage({ message: err })
    })
  }

  getCart() {
    this.service.getCart().subscribe((Res) => {
      this.cartlength = Res.data.length
      this.eventEmit(this.cartlength)

    })
  }
  eventEmit(res: any) {
    this.service.updateCartViaNumberApi((JSON.stringify(res)))
  }
  phototype: any = 'image'



  getPhototype(type: any) {
    this.phototype = type
  }

  getAllCategories(): void {

    this.service.categories().subscribe((resp: CategoryResponse) => {

      this.all_categories = resp.data;

    })

  }

  getSize(event: any) {
    this.selectedsize = event.value
  }
  getHomePageContent(): void {
    this.service.getHomePageContent({}).subscribe((resp: HomePageContentResponse) => {
      this.home_content = resp.home_data
      this.latest_uploads = this.home_content.latest_upload
      this.categories_ids = resp.home_data.category_section.map(item => item._id);
      this.prestigious_clients = resp.home_data.prestigious_clients
      this.getCategoriesWithImages();


    })
  }

  getCategoriesWithImages(): void {

    this.service.getCategoriesWithImages({
      limit: Number.MAX_SAFE_INTEGER,
      offset: 0,
      categories_ids: this.categories_ids.toString(),
    }).subscribe((resp: CategoriesImagesResponse) => {

      this.categories_with_images = resp.data;


    })

  }


  imageDetailPage(id: any) {
    this.router.navigate([`/user/moreinfo/${id}`])

  }

  getCategoryTags(item: Category): any {

    var tags: any = []


    for (let i = 0; i < item.category_images.length; i++) {
      for (let j = 0; j < item.category_images[i].tags.length; j++) {
        tags.push(item.category_images[i].tags[j]);
      }
    }

    if (tags.length > 8) {
      let eight_tags = tags.slice(0, 8);
      tags = [...new Set(eight_tags)];
      return tags
    }
    else {

      tags = [...new Set(tags)];
      return tags
    }

  }



  selectPic(id: any) {
    
    this.service.getImageDetail(id).subscribe((resp: any) => {
      if (resp.code == 200) { 
        this.selectedpicture = resp?.image_details?._id;
        this.sizes = resp?.image_details?.available_sizes.map((res: any) => res.image_type)
        this.getPricingFxn(this.sizes)
      }

   
   
    })
  }


  addToCartForVector(imageID: any) {
    let object = {
      collection_img_id: imageID,

    }
    this.service.addCart(object).subscribe((resp: any) => {
      if (resp?.code == 200) {
        this.service.showSuccessMessage({
          message: "Added to Cart"
        })
        this.getCart()
     

      }
    }, (error: HttpErrorResponse) => {
      this.service.showErrorMessage({
        message: error?.error?.errors
      })
    })
  }
  imagesizes: any[] = []
  getPricingFxn(sizes: any) {
    const obj = {
      ids: sizes.toString()
    }
    this.service.getPricing1(obj).subscribe((res: ResponsePricing) => {
      this.imagesizes = res.data;
    })
  }

  addToWishlist(id: any) {

    if (this.service.getToken()) {
      const obj = {
        collection_img_id: id
      }
      this.service.addwhishlist(obj).subscribe((res: any) => {
        if (res.data == "Saved") {
          this.service.showSuccessMessage({ message: "The image saved successfully" })
          this.getHomePageContent()
        } else {
          this.service.showSuccessMessage({ message: "The image remove successfully" })
          this.getHomePageContent()
        }

      })

    } else {

      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))

      this.service.showErrorMessage({ message: "Please Login First" })
      return
    }
  }

  addToCart() {
    const obj = {
      collection_img_id: this.selectedpicture,
      available_size_id: this.selectedsize
    }
    if (this.selectedsize !== '') {
      this.service.addCart(obj).subscribe((res: any) => {
        this.myModal.nativeElement.click();
        this.getCart()
        this.service.showSuccessMessage({ message: "add to cart successfully" })
      })
    }
    else {
      this.service.showErrorMessage({ message: "Please select Size First" })
    }
  }



  async downloadImage(image: any, data: any) {
    const imgName = image;

    this.loader.start();
    const file = await fetch(imgName + '?r=' + Math.floor(Math.random() * 100000)).then(function (response) {
      return response.blob();
    })

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = 'watermark_'+data;
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));
    this.loader.stop();
    setTimeout(() => { // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }

  applyFilter() {
    
      let search = this.serachdata
      let type = this.phototype
      const queryParams = { search: search };
      if(this.serachdata){
        this.router.navigate(['/new-stock-images/' + type],{ queryParams: queryParams })
      }else{
        this.router.navigate(['/new-stock-images/' + type])
      }
     
    

  }

  searchvalue(a:any){
        let search = a
      let type = this.phototype
      const queryParams = { search: search };
      if(a){
        this.router.navigate(['/new-stock-images/' + type],{ queryParams: queryParams })
      }else{
        this.router.navigate(['/new-stock-images/' + type])
      }
  }


 

  onImgError(event: any) {
    event.target.src = this.env.DEFAULT_IMAGE;
  }

  subscribe() {
    if (this.form2.valid) {
      const data = {
        email: this.form2.value.email,
      }
      this.service.newsLetter(data).subscribe((resp: any) => {

        this.service.showErrorMessage({ message: resp.data })
        this.form2.reset()
      }, (err: any) => {
        this.service.showErrorMessage({ message: err })

      })
    } else {

      this.form2.markAllAsTouched()

    }
  }




  viewall(data: any, viewtype: any) {

    let search = data
    let type = viewtype
    this.router.navigate(['/user/Explore/' + search + '/' + type])
  }


  blogNavigate(data:any) {
    window.open(
      data, "_blank");

  }

}
