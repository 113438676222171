import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-successful',
  templateUrl: './successful.component.html',
  styleUrls: ['./successful.component.scss']
})
export class SuccessfulComponent implements OnInit {
  env = environment;
  token:any=''
  refId:any=''
  constructor(private route:ActivatedRoute,private service: HttpService,) { 
    if(this.route.snapshot.queryParams['ref_id']){
      this.refId = this.route.snapshot.queryParams['ref_id'];
    }
    
     this.token = this.service.getToken()
     if(this.refId){
      this.download()
     }
      
    
  }

  ngOnInit(): void {
   

  }

  download(){
    window.open(this.env.SERVER_URL + 'users/download/zip?ref_id=' + this.refId + '&token=' + this.token );
  }

}
