
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { windowWhen } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { CookieService } from "ngx-cookie-service";
import { HelperService } from 'src/app/services/helper/helper.service';
import { Category, CategoryResponse, Loginreceive, Registerrecieve } from 'src/app/models/model';
import { HttpErrorResponse } from '@angular/common/http';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
declare let $:any

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  isShow = true;
  isHide = false;
  user: any = null
  role: any;
  userdetail: any;
  userdetail1: any;
  cartlength: any;
  limit:number = 5;
  offset:number = 0;
  seen:boolean = true;
  isLoggedUser:boolean = false
  form1: FormGroup;

  submitted: boolean = false;
  @ViewChild('refModal', { static: true })
  myModal!: ElementRef<any>; 
  constructor(private service: HttpService, private router: Router, private cookie: HelperService,private fb: FormBuilder,private route: ActivatedRoute) {
    

    this.form1 = this.fb.group({
     
      password1: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/)]],
      password2: ['', [Validators.required]],
      

    }, {
      validators: [PasswordValidator.confirmed('password1', 'password2')],
    }
    )

  
  
    
  }

  ngOnInit(): void {
    this.resetId=this.route.snapshot.paramMap.get('id')
  }
  resetId:any=''




     show: boolean=true;
     show1: boolean=true;
     show2: boolean=true;
     showPassword(inputid: any) {
      let x: any = document.getElementById(inputid);
  
      if (inputid == 'pswd') {
        if (x.type === "password") {
          x.type = "text";
          this.show = false
        } else {
          x.type = "password";
          this.show = true
        }
      } else if (inputid == 'pswd1') {
        if (x.type === "password") {
          x.type = "text";
          this.show1 = false
        } else {
          x.type = "password";
          this.show1 = true
        }
      } else if (inputid == 'pswd2') {
        if (x.type === "password") {
          x.type = "text";
          this.show2 = false
        } else {
          x.type = "password";
          this.show2 = true
        }
      }
  
    }
      numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
    socialUser:any
  

  toggleDisplay() {
    this.isShow = !this.isShow;
    this.isHide = !this.isHide;
  }

  
  
  
 


 


  login(): any {
    this.submitted = true
    if (this.form1.valid) {
      const data = {
        id:this.resetId,
        password: this.form1.value.password1
      }
      this.service.resetPassword(data).subscribe((resp) => {
        this.service.showSuccessMessage({message:'Change Password Successfully' })
        this.router.navigate(['/'])
    
      },(err:any)=>{
       
       
         
        this.service.showErrorMessage({message:err})
      })
    } else {

      this.form1.markAllAsTouched()

    }
  }

}
export class PasswordValidator {
  static confirmed = (controlName: string, matchingControlName: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const input = control.get(controlName);
      const matchingInput = control.get(matchingControlName);

      if (input === null || matchingInput === null) {
        return null;
      }

      if (matchingInput?.errors && !matchingInput.errors.pwdNotSame) {
        return null;
      }

      if (input.value !== matchingInput.value) {
        matchingInput.setErrors({ pwdNotSame: true });
        return ({ pwdNotSame: true });
      } else {
        matchingInput.setErrors(null);
        return null;
      }
    };
  }
}