<div class="main_home_wraper">

	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<h1>Checkout</h1>
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="#">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Checkout</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="contact_us checkout_sctn">
			<div class="custom_container">
				<div class="coupan-main">
					<div class="coupan-content">
						<p>
							<!-- <a class="btn btn-custom w-100 text-left" data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
						    Have a coupon?<span>Click here to enter your code</span>
						  </a> -->
						</p>
						<div class="collapse" id="collapseExample">
							<div class="card">
								<div class="card-body">
									<div class="">
										<div class="grey_form newbtn">
											<div class="row align-items-center">
												<div class="col-sm-6">
													<div class="form-group">
														<p class="pb-2">If you have a coupon code, please apply it
															below.</p>
														<input type="email" [(ngModel)]="couponCode"
															class="form-control" placeholder="Enter Code">
													</div>
												</div>
												<div class="col-sm-6">
													<button class="btn btn_theme_filled mt-4">Apply</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row align-items-start">
					<div class="col-md-7 mb-4">
						<div *ngIf="this.cartdetail.length != 0 && !subscriptionId" class="cart_tabl cntct_form p-0">
							<table class="table">
								<thead>
									<tr>
										<th scope="col">Image</th>
										<th scope="col">Size</th>
										<th scope="col">Price</th>
									</tr>
								</thead>
								<tbody>
									<tr *ngFor="let item of this.cartdetail">
						
										<td>
											<div class="prdct_img position-relative">
						
												<img [src]="env.IMAGE_COVER_WATERMARK+item?.collection_images?.cover_image?.with_watermark"
													class="img-fluid">
												<div class="cncl_img" (click)="deleteItem(item._id)">
													<i class="fa fa-times"></i>
												</div>
											</div>
										</td>
										<td>{{item?.type=='image' ? item?.available_size?.size : "Vector Image"}}</td>
										<td>₹ {{item?.type=='image' ? item?.available_size?.price :
											item?.collection_images?.price}}</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<td></td>
										<th>Subtotal</th>
										<td>₹ {{this.GSTAMOUNT.netPrice?.toFixed(2)}}</td>
									</tr>
									<tr>
										<td></td>
										<th>GST</th>
										<td>₹ {{ fixNumber(this.GSTAMOUNT?.netPrice - this.GSTAMOUNT?.total) ? 
													fixNumber(this.GSTAMOUNT?.netPrice - this.GSTAMOUNT?.total) : 0}}
														({{this.GSTAMOUNT.GST}} %)</td>
									</tr>
									<tr>
										<td></td>
										<th>Total</th>
										<td>₹ {{this.GSTAMOUNT.total}}</td>
									</tr>
								</tfoot>
							</table>
						</div>

						<div *ngIf="subscriptionId" class="cart_tabl cntct_form p-0">
							<table class="table">
								<thead>
									<tr>
										<th scope="col">Images/Videos</th>
										<th scope="col">Discount</th>
										<th scope="col">Price</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>{{planObj?.quantity}}</td>
										<td>{{planObj?.item.discount}} % </td>
										<td>{{planObj?.item.amount}}</td>
								</tbody>
							 
							</table>
						</div>

						<div *ngIf="this.cartdetail.length == 0 && subscriptionId == ''" class="cart_tabl cntct_form p-0">
							<h3 class="text-center m-5">Cart is empty</h3>
						</div>
						<div class="cntct_form_wrp">
							<div class="cntct_form grey_form">
								<h3>Billing details</h3>
								<form [formGroup]="addBillingAddress" id="top">
									<div class="form-row">
										<div class="form-group col-md-6">
											<label>First Name</label>
											<input type="text" class="form-control" placeholder="First Name"
												value="John" formControlName="first_name"
												oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
											<div *ngIf="addBillingAddress.controls['first_name'].touched">
												<p class="danger err-msg "
													*ngIf="addBillingAddress.controls['first_name'].hasError('required')  && !addBillingAddress.controls['first_name'].valid ">
													First Name is required*</p>
											</div>
										</div>
										<div class="form-group col-md-6">
											<label>Last Name</label>
											<input type="text" class="form-control" placeholder="Last Name" value="Doe"
												formControlName="last_name"
												oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
											<div *ngIf="addBillingAddress.controls['last_name'].touched">
												<p class="danger err-msg "
													*ngIf="addBillingAddress.controls['last_name'].hasError('required')  && !addBillingAddress.controls['last_name'].valid ">
													Last Name is required*</p>
											</div>
										</div>

									</div>
									<div class="mb-3 form-check d-flex align-items-center">
										<input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="onCheck($event)" [checked]="has_gst">
										<label class="form-check-label" for="exampleCheck1">Do you have GST Number ?</label>
									</div>
									<div class="form-group" *ngIf="!gst">
										<label>Company name (optional)</label>
										<input type="text" class="form-control" placeholder="Company name (optional)"
											value="Lorem ipsum" formControlName="company_name"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
									</div>

									<div class="form-group" *ngIf="gst">
										<label>Company name</label>
										<input type="text" class="form-control" placeholder="Company name"
											value="Lorem ipsum" formControlName="company_name"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
											<p class="text-danger" *ngIf="addBillingAddress.controls['company_name']?.hasError?.('required')  && addBillingAddress.controls['company_name'].touched">
												Company Name is required*</p>
										</div>



									<div class="form-group" *ngIf="gst">
										<label>GST Number</label>
										<input type="text" class="form-control" placeholder="GST Number" formControlName="gstNo">
										<p class="text-danger" *ngIf="addBillingAddress.controls['gstNo'].hasError('required')  && addBillingAddress.controls['gstNo'].touched">
										GST Number is required*</p>
									</div>

									<div class="form-group">
										<label>Country / Region</label>
										<select class="form-control" placeholder="Select a Country / region"
											formControlName="country" (change)="countrySelected($event)">
											<option *ngFor="let c of countries" [value]="c.name">{{c.name}}</option>
											<!-- <option value="afghanistan">Afghanistan</option>
											<option value="India" selected="">India</option>
											<option value="zimbabwe">Zimbabwe</option> -->
										</select>
										<div *ngIf="addBillingAddress.controls['country'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['country'].hasError('required')  && !addBillingAddress.controls['country'].valid ">
												Country is required*</p>
										</div>
									</div>
									<div class="form-group">
										<label>Street address</label>
										<input type="text" class="form-control mb-2"
											placeholder="House number and street name" value="POBOX 130, BNo.390"
											formControlName="street_address"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
										<div *ngIf="addBillingAddress.controls['street_address'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['street_address'].hasError('required')  && !addBillingAddress.controls['street_address'].valid ">
												Street_address is required*</p>
										</div>
										<input type="text" class="form-control"
											placeholder="Apartment, suite, unit, etc. (optional)"
											value="Street:310, Zone 47" formControlName="street_address_2">
									</div>
									<div class="form-group">
										<label>Town / City</label>
										<input type="text" class="form-control" placeholder="Town / City"
											value="Ludhiana" formControlName="city"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
										<div *ngIf="addBillingAddress.controls['city'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['city'].hasError('required')  && !addBillingAddress.controls['city'].valid ">
												City is required*</p>
										</div>
									</div>
									<div class="form-group">
										<label>State</label>
										<select class="form-control" placeholder="Select an option..."
											formControlName="state">
											<option *ngFor="let s of cities" [value]="s.name">{{s.name}}</option>
											<!-- <option>Andhra Pradesh</option>
											<option selected="">Punjab</option>
											<option>Delhi</option> -->
										</select>
										<div *ngIf="addBillingAddress.controls['state'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['state'].hasError('required')  && !addBillingAddress.controls['state'].valid ">
												State is required*</p>
										</div>
									</div>
									<div class="form-group">
										<label>ZIP</label>
										<input type="text" class="form-control" placeholder="ZIP" value="3454656"
											formControlName="zip" (keypress)="numberOnly($event)">
										<div *ngIf="addBillingAddress.controls['zip'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['zip'].hasError('required')  && !addBillingAddress.controls['zip'].valid ">
												Zip is required*</p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['zip'].hasError('pattern')   ">Invalid
												code </p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['zip'].hasError('minlength')   ">zip
												must be at least 2 characters long. </p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['zip'].hasError('maxlength')   ">zip
												can be max 6 characters long. </p>
										</div>
									</div>
									<div class="form-group">
										<label>Phone</label>
										<input type="text" class="form-control" placeholder="Phone" value="3454656234"
											formControlName="phone" (keypress)="numberOnly($event)">
										<div *ngIf="addBillingAddress.controls['phone'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['phone'].hasError('required')  && !addBillingAddress.controls['phone'].valid ">
												Phone Number is required*</p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['phone'].hasError('pattern')   ">
												Invalid number </p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['phone'].hasError('minlength')   ">
												Number should be 10 digit</p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['phone'].hasError('maxlength')   ">
												Number should be 10 digit</p>
										</div>
									</div>
									<div class="form-group">
										<label>Email address</label>
										<input type="email" class="form-control" placeholder="Email address"
											value="john.doe@gmail.com" formControlName="email">
										<div *ngIf="addBillingAddress.controls['email'].touched">
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['email'].hasError('required')  && !addBillingAddress.controls['email'].valid ">
												Email is required*</p>
											<p class="danger err-msg "
												*ngIf="addBillingAddress.controls['email'].hasError('pattern')   ">
												Invalid email </p>

										</div>
									</div>
									<!-- <div class="actn_bttn mt-3 mb-5">
                                        <button type="submit" class="btn btn_theme_filled" (click)="submit()">Save address</button>
                                    </div> -->
								</form>
							</div>
							<div class="cntct_form grey_form">
								<h3>Additional information</h3>
								<div class="form-group">
									<label>Order notes (optional)</label>
									<textarea class="form-control" rows="5" [(ngModel)]="additionalInformation"
										placeholder="Notes about your order, e.g. special notes for delivery."
										oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"></textarea>
								</div>
							</div>
						</div>
					</div>

					<div class="col-md-5 sticky_col mb-4">
						<div class="plcordr_div fixme">
							<div class="pmnt_optns_div">
								<!-- <p class="mb-0"><small>Sorry, it seems that there are no available payment methods for your state. Please contact us if you require assistance or wish to make alternate arrangements.</small></p> -->
							</div>
							<div class="place_order">
								<p>Your personal data will be used to process your order, support your experience
									throughout this website, and for other purposes described in our <a 
										routerLink="/privacy-policy">privacy policy.</a></p>
								<!-- <button type="button" class="btn btn_theme_filled d-block mt-4" (click)="placeOrder()" >Place Order</button> -->
								<button type="button" class="btn btn_theme_filled d-block mt-4"
									(click)="placeOrderPhonePe()">Place Order </button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>


	<app-footer></app-footer>
</div>