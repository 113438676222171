<div class="main_home_wraper">
	
	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<h1>Privacy Policy</h1>
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
							    <li class="breadcrumb-item"><a href="#">Home</a></li>
							    <li class="breadcrumb-item active" aria-current="page">{{privacyPolicydata[0].heading}}</li>
							  </ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- <section class="contact_us privacy_policy">
			<div class="custom_container" >
				<div class="policy_wrap" *ngFor="let x of privacyPolicydata">
					<h2>{{x.heading}}</h2>
					<p [innerHtml]="x.description"></p>
			    </div>
			</div>
		</section>	 -->
		<section>
			<div class="custom_container"  *ngFor="let x of privacyPolicydata">
				<div class="policy_wrap">
					<h1 >{{x.heading}}</h1>
					  <p>The online Registration Form asks visitors for demographic information (like their PIN code or
						Telephone No.) . Demographic and profile data is also collected at the site. We use this data to
						tailor our visitor&#39;s experience and services at our site, showing them content that we think they
						might be interested in, and displaying the content according to their preferences.
						The customer&#39;s contact information is used to contact the visitor when necessary. Users may
						opt-out of receiving future mailings; see the choice/opt-out section below. We do not divulge the
						visitor&#39;s information to any third party for commercial use. We may use the data for informing the
						users of our services and offerings from time to time.</p>
					
				</div>
			</div>
			<div class="container mt-5">
				<div class="row">
					<div class="col-lg-12">
						<mat-accordion>
							<mat-expansion-panel class="mb-3">
							  <mat-expansion-panel-header>
								<mat-panel-title>
									What information do you collect when I register for something?
								</mat-panel-title>
							  </mat-expansion-panel-header>
							  <p>NU Stock Images offers access to some services that require registration. We may use your
								contact information to send notification to you of NU Stock Images products and services by e-
								mail and/or postal mail. We also may share that information with our clients. Our clients may send
								you promotional material that is related to the topic of the service for which you registered. We
								may also use your IP address internally and share it with third parties.</p>
								<p>What other information do you request?
									We may also request your e-mail address or mailing address for the purpose of conducting a
									survey or to provide additional services (for example, subscriptions to e-mail newsletters,
									announcement lists or information about conferences and trade shows). Whenever we request the
									identity of a visitor, we will clearly indicate the purpose of the inquiry before the information is
									requested. We maintain a strict &quot;No-Spam&quot; policy that means that we do not sell, rent, or
									otherwise give your e-mail address to a third-party, without your consent or as permitted by this
									Privacy Policy. We may from time to time send e-mail announcing new NU Stock Images products
									and services. If you choose to supply your postal address in an online form, you may receive postal
									mailings from other third-party companies.</p>
							</mat-expansion-panel>
			
							<mat-expansion-panel>
							  <mat-expansion-panel-header>
								<mat-panel-title>
									What information do you collect when I purchase something?
								</mat-panel-title>
							  </mat-expansion-panel-header>
							  <p>If you are purchasing something from a NU Stock Images media property, we and any third party
								provider, need to know your name, e-mail address, mailing address, credit card number and
								expiration date. This allows us, or third-party providers, to process and fulfill your order and to
								notify you of your order status. This information may also be used by us to notify you of related
								products and services. Credit card and e-mail information will not be shared or sold to third
								parties for any purpose except as provided in this Privacy Policy without your express permission.
								Wherever NU Stock Images collects personal information we make an effort to include a link to
								this Privacy Policy on that page.</p>
								<p>Will you disclose the information you collect to outside third parties?
									NU Stock Images will disclose personal information and/or an IP address, when required by law or
									in the good-faith belief that such action is necessary to:</p>
									<p>1. Cooperate with the investigations of purported unlawful activities and conform to
										the edicts of the law or comply with legal process served on NU Stock Images,
										2. Protect and defend the rights or property of the NU Stock Images and related
										properties, or visitors to the NU Stock Images and related properties,
										3. Identify persons who may be violating the law, the NU Stock Images legal notice and
										Web site User Agreement, the rights of third parties, or otherwise misusing the NU
										Stock Images Network or its related properties,
										4. Fulfill a user&#39;s order or request.
										5. NU Stock Images uses reasonable precautions to keep the information disclosed to
										us secure. NU Stock Images reserves the right to transfer all such information in
										connection with the sale of all or part of NU Stock Images&#39; capital stock or assets.
										Furthermore, we are not responsible for any breach of security or for any actions of
										any third parties that receive the information. We are not responsible for such third-
										party privacy policies or how they treat information about their users,</p>
										<p>Choice Opt-Out/ Opt-in announcement List Privacy Policy
											When you sign up for a NU Stock Images e-mail newsletter, you are given the opportunity to sign
											up for targeted third-party mailings. Whether you want to receive these announcements is your
											choice. You may stop the arrival of these announcement lists at any time by removing your e-mail
											address from the list by forwarding the announcement list or by following the instructions on the
											e-mails you receive.</p>
											<p>By using the NU Stock Images site, you consent to the collection and use of information by NU
												Stock Images as specified above. We reserve the right to modify this policy. If we decide to
												change our Privacy Policy, we will post those changes on this page so that you are always aware
												of what information we collect, how we use it, and under what circumstances we disclose it.</p>
												<p>Please send any questions about NU Stock Images&#39; Privacy Policy to
													https://nustockimages.com/ &quot;We as a merchant shall be under no liability whatsoever in respect
													of any loss or damage arising directly or indirectly out of the decline of authorization for any
													Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us
													with our acquiring bank from time to time&quot;</p>
							</mat-expansion-panel>

							</mat-accordion>
					</div>
				</div>
			</div>
		</section>
	</div>
	

	<app-footer></app-footer>
</div>