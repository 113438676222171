<div class="pageWrap">
    <div class="signup_pg sgn_rpnvs signUp_repnsvs">
        <div class="container-fluid">
            <div class="row align-items-start justify-content-center">
                <div class="col-md-8 sticky_col mt-3">
                    <div class="video_thns mt-5 text-center">
                        <img src="assets/images/failed-to-make-payment-by-credit-card-concept-illustration-flat-design-eps10-modern-graphic-element-for-landing-page-empty-state-ui-infographic-vector-removebg-preview.png" alt="" class="image-fluid">
                        <div class="div_pyment_text mt-4">
                            <h6 class="mb-3">Oops! Payment Failed</h6>
                            <p class="mb-4">The payment was unsuccessful due to an abnormailty.Plaese try again later or use another payment method</p>
                            <a routerLink="/checkout" class="back_hm_btn">
                                Try Again
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>