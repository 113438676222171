
<div class=" g" tabindex="-1"  aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div >
                <div class="row">
                    <div class="col-md-7">
                        <div class="sign_img">
                            <img src="https://images.pexels.com/photos/1438081/pexels-photo-1438081.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                class="img-fluid" />
                        </div>
                    </div>
                    <div class="col-md-5 set_tab">
                        <div class="sign_tabs">
                          
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="sign_in" role="tabpanel">
                                    <div class="signin_wrap">
                                        <div class="sign_show" [hidden]="isHide">
                                            <div class="signin_cntnt rst_box">

                                                <h3 class="mb-5 mt-4"> Reset Password</h3>
                                                
                                                
                                                <form class="sign_flds" [formGroup]="form1">

                                                    <div class="form-group">
                                                        <div class="divppass">
                                                            <input type="password" class="form-control txt-clr"
                                                                placeholder=" New Password" formControlName="password1"
                                                                type="password" id="pswd" />

                                                                <div *ngIf="form1.controls['password1'].touched">
                                                                    <p class="danger err-msg "
                                                                        *ngIf="form1.controls['password1'].hasError('required')  && !form1.controls['password2'].valid ">
                                                                        Password is required*</p>
                                                                    <p class="danger err-msg "
                                                                        *ngIf="form1.controls['password1'].hasError('pattern') ">
                                                                        The password must be at least 8 characters long, and
                                                                        contain at least 1 capital letter, 1 lowercase letter
                                                                        and 1 numeric character.*
                                                                    </p>
                                                                </div>
                                                            <div class="diviconfa">
                                                                <span class="sign_lck mr-2">
                                                                    <i *ngIf="!this.show" class="fa fa-eye"
                                                                        aria-hidden="true"
                                                                        (click)="showPassword('pswd')"></i>
                                                                    <i *ngIf="this.show" class="fa fa-eye-slash"
                                                                        aria-hidden="true"
                                                                        (click)="showPassword('pswd')"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                  
                                                    <div class="form-group">
                                                        <div class="divppass">
                                                            <input type="password" class="form-control txt-clr"
                                                                placeholder="Confirm New Password" formControlName="password2"
                                                                type="password" id="pswd1" />

                                                                <div *ngIf="form1.controls['password2'].touched">
                                                                    <p class="danger err-msg "
                                                                        *ngIf="!form1.controls['password2'].valid && (form1.controls['password2'].hasError('required'))">
                                                                        Please
                                                                        confirm password*</p>
                                                                    <p class="danger err-msg "
                                                                        *ngIf="form1.hasError('pwdNotSame')">
                                                                        Confirm passwords don't match*
                                                                    </p>
                                                                </div>
                                                            <div class="diviconfa">
                                                                <span class="sign_lck mr-2">
                                                                    <i *ngIf="!this.show1" class="fa fa-eye"
                                                                        aria-hidden="true"
                                                                        (click)="showPassword('pswd1')"></i>
                                                                    <i *ngIf="this.show1" class="fa fa-eye-slash"
                                                                        aria-hidden="true"
                                                                        (click)="showPassword('pswd1')"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                   
                                                </form>
                                            </div>
                                            <div class="sign_actn rst_btn">
                                                <button type="submit" (click)="login()"
                                                    class="btn btn_theme_filled btn_grey">Reset</button>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>