<div class="main_home_wraper">
	
	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<h1>About Us</h1>
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
							    <li class="breadcrumb-item"><a href="#">Home</a></li>
							    <li class="breadcrumb-item active" aria-current="page">About us</li>
							  </ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section class="contact_us about_us">
			<div class="custom_container">
				<div class="row ">
					<div class="col-md-7">
						<div class="about_us_cntnt">
							<p [innerHtml]="this.aboutus_content?.title"></p>
							
							
							<!-- <div class="about_btns mt-5">
								<a class="btn btn_theme_filled"  (click)="click()">Browse Now</a>
							</div> -->
						</div>
					</div>
					<div class="col-md-5">
						<div class="about_grid" >
							<img *ngFor="let image of this.aboutus_content?.images" [src]="ABOUTIMAGES+image" [alt]="image" class="img-fluid">
							

						</div>
						
					</div>
					
				</div>
			</div>
		</section>

		<section class="contact_us about_us">
			<div class="custom_container">
				<div class="row ">
					<div class="col-md-12">
						<div class="about_us_cntnt">
							<p [innerHtml]="this.aboutus_content?.heading"></p>
							<p [innerHtml]="this.aboutus_content?.description"></p>
							
							<!-- <div class="about_btns mt-5">
								<a class="btn btn_theme_filled"  (click)="click()">Browse Now</a>
							</div> -->
						</div>
					</div>
					
				</div>
			</div>
		</section>

		
		

		<section class="all_categories client_logos our_team">
			<div class="custom_container">
				<div class="sctn_hdng">
					<h2>Our team</h2>
				</div>
				<div class="categories_crsl clnts_sldr teams_slider">
					<owl-carousel-o [options]="teams_slider">
						<ng-template carouselSlide *ngFor="let staff of this.aboutus_content?.our_team">
							<div class="mmbr_div">
								<div class="nm_desc_div d-flex align-items-center position-relative">
									<div class="mmbr_img">
										<img [src]="ABOUTIMAGES+staff?.image" [alt]="staff?.image" class="img-fluid">
									</div>
									<div class="mmbr_dtls">
										<h6>{{staff?.position}}</h6>
										<h2 class="mmbr_nm">{{staff?.name}}</h2>
										<p>{{staff?.description}}</p>
									</div>
								</div>
		
								<p>You can also follow him on:</p>
								<div class="mmbr_social_link">
									<ul type="none" class="socl_links_txt d-flex">
										<li>
											<a href="https://www.facebook.com/nustockimages"><i
													class="fab fa-facebook-f"></i></a>
										</li>
										<li>
											<a href="https://www.instagram.com/nustockimages"><i
													class="fab fa-instagram"></i></a>
										</li>
										<li>
											<a href="https://www.linkedin.com/company/nustockimages/"><i
													class="fab fa-linkedin-in"></i></a>
										</li>
										<li>
											<a href="https://twitter.com/nustockimages"><i class="fab fa-twitter"></i></a>
										</li>
										<li>
											<a href="https://www.youtube.com/channel/UCh1yWeYK2vbdiIrCp29JJ6w"><i
													class="fab fa-youtube"></i></a>
										</li>
									</ul>
								</div>
							</div>
		
				
						</ng-template>
				
		
					</owl-carousel-o>
				</div>
			</div>
			
		</section>

		

		<section class="all_categories client_logos">
			<div class="custom_container">
				<div class="sctn_hdng">
					<h2>Our most prestigious clients</h2>
				</div>
				<div class="categories_crsl clnts_sldr">
					<owl-carousel-o [options]="clients_slider">
					    <ng-template carouselSlide *ngFor="let clients of this.aboutus_content?.logos">
					    	<div class="imgs_clnts">
				    			<img [src]="ABOUTIMAGES+clients" [alt]="clients" class="img-fluid">
				    		</div>
					    </ng-template>
					  
					    
					</owl-carousel-o>
				</div>
			</div>
		</section>
	</div>
	

	<app-footer></app-footer>
</div>