import { Component, OnInit } from '@angular/core';
import { PrivacyPolicyResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { Title, Meta } from '@angular/platform-browser';




@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyPolicydata: any;

  constructor(private service:HttpService,private titleService: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("NuStock: Privacy Policy")
    this.meta.updateTag({ property: 'og:description', content: "Know how NuStock prioritizes and safeguards your privacy throughout your journey with us. Trust us to protect your experience with diligence and care."});
    this.meta.updateTag({ name: 'description', content: 'Know how NuStock prioritizes and safeguards your privacy throughout your journey with us. Trust us to protect your experience with diligence and care.' });

   this.privacyPolicy()

  }




  privacyPolicy():any{
    this.service.privacyPolicy().subscribe((res:PrivacyPolicyResponse)=>{
      this.privacyPolicydata=res.data.cms
    })
  }
}
