import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnyARecord } from 'dns';
import { HomePageContentResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  getfooterdata: any;
  year: number = new Date().getFullYear()
  topcategory: any

  constructor(private router: Router, private service: HttpService) { }

  ngOnInit(): void {
    this.getHomePageContent();
  }
  getHomePageContent(): void {
    this.service.getHomePageContent({}).subscribe((resp: HomePageContentResponse) => {
      this.topcategory = resp.home_data.category
    })
  }

  click(data: any): any {
    // let catID=data
    let search = data;
    let type: string;
    this.getfooterdata = data
    switch (this.getfooterdata) {
      case 'aboutus':
        this.router.navigate(['about-us'])
        break;
      case 'contact':
        this.router.navigate(['contact-us'])
        break;
      case 'privacyPolicy':
        this.router.navigate(['privacy-policy'])
        break;
      case 'TermsofService':
        this.router.navigate(['terms-of-service'])
        break;
      case 'terms-conditions':
        this.router.navigate(['terms-conditions'])
        break;
      case 'faq':
        this.router.navigate(['faq'])
        break
      case 'pricing':
        this.router.navigate(['pricing'])
        break;
      case 'myAccount':
        this.router.navigate(['/user/view-profile'])
        break;
      case 'Categories':
        this.router.navigate(['/user/categories'])
        break;
      case 'explore':
        search = 'latestUploads'
        type = 'allImages'
        this.router.navigate(['/user/Explore/' + search + '/' + type])
        this.router.navigate(['/'], {
          skipLocationChange: true
        }).then(res => {
          this.router.navigate(['/user/Explore/' + search + '/' + type])
        })
        break;
    }

  }



  navigateByCategory(data: any) {
    this.router.navigate(['/'], {
      skipLocationChange: true
    }).then(res => {
      this.router.navigate([`/stock-photos/${data}`])
      
    })
    
  }



}
