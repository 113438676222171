import { Component, OnInit } from '@angular/core';
import { ResponseEvents } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-photoevents-list',
  templateUrl: './photoevents-list.component.html',
  styleUrls: ['./photoevents-list.component.scss']
})
export class PhotoeventsListComponent implements OnInit {
  events:any
  env = environment
  page: Number=1;
  offset: Number=0;
  total: Number=0;
  show:boolean=true
  constructor(private service: HttpService) { }

  ngOnInit(): void {
    this.getEvents()
  }
  getEvents(){
    this.service.getEvents().subscribe((res:ResponseEvents)=>{
      this.events=res.data
      this.total=1;
      this.show=false
    })
  }

  pageChanged(event:any){
    this.page=event
    this.offset=(event-1)*10
    this.getEvents()
    }
}
