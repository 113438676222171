<div class="main_home_wraper">
	
	<app-header></app-header>

	<section class="hero_section">
		<div class="custom_container">
			<div class="hero_sctn_cntnt">
				<div class="hero_sctn_txt text-center">
					<h1>{{this.home_content?.img_heading}}</h1>
					<!-- <p>Photo of the day by <a>Nu Stock Images</a> Published in category <a>Home & Interiors</a> -->
											<p>{{this.home_content?.img_description}}</p>

					
					<form class="d-flex srch_form">
		                <div class="slct_srch d-flex">
		                    <div class="srch_drpdwn">
		                        <div class="dropdown">
		                          <button class="btn btn-secondary dropdown-toggle slctdrop_btn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
									{{phototype == 'none' ? 'All' : phototype == 'image'  ? 'Photos' : phototype == 'vector'  ? 'Vector':''}}
								</button>
		                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
		                                <!-- <a class="dropdown-item" (click)="getPhototype('none')">All</a> -->
		                                <a class="dropdown-item" (click)="getPhototype('vector')">Vectors</a>
		                                <a class="dropdown-item" (click)="getPhototype('image')">Photos</a>
		                            </div>
		                        </div>
		                    </div>
		                    <div class="srch_input">
		                        <input class="form-control mr-2 hdr_srch" type="search" (keyup.enter)="applyFilter()" [(ngModel)]="serachdata" placeholder="Search" aria-label="Search" name="search"
								#search="ngModel">      
		                    </div>    
		                </div>
		                <div class="srch_bttn">
		                    <button class="btn btn_theme_filled" type="button" (click)="applyFilter()">Search</button>
		                </div>
		            </form> 

				</div>
				
			</div>
		</div>
	</section>
	<section class="hero_btm_div">
		<div class="custom_container">
			<div class="hero_btm_txt text-center">
				<p>{{this.home_content?.img_bottom_section}}</p>
			</div>	
		</div>
		
	</section>

	<section class="all_categories">
		<div class="custom_container">
			<div class="categories_crsl">
				<owl-carousel-o [options]="catg_crsl">
				    <ng-template carouselSlide *ngFor="let cat of all_categories" >
				    	<div class="catg_card"[style.cursor]="'pointer'"    routerLink="/stock-photos/{{cat?.category_slug}}">
				    		<div class="catg_img" >
				    			<img loading="lazy" appLazyLoad  [src]="env.CATEGORY_IMAGE +''+cat.image" [alt]="cat.image" class="img-fluid" (error)="onImgError($event)" >
				    		</div>
				    		<div class="catg_cntnt">
				    			<span >{{ cat.category_name }}</span>
				    		</div>
				    	</div>
				    </ng-template>
				</owl-carousel-o>
			</div>
		</div>
	</section>

	<section class="browse_collections">
		<div class="custom_container">
			<div class="sctn_hdng">
				<h2>{{this.home_content?.cat_heading}}</h2>
				<p>{{this.home_content?.cat_description}}</p>
			</div>
			<div class="cllctns_wrap">
				<div class="row">
					<div *ngFor="let item of categories_with_images | slice:0:3" class="col-lg-4 col-md-6 col-sm-12">
						<div class="cllctns_div">
							<div class="img_cllctn" [style.cursor]="'pointer'"  routerLink="stock-photos/{{item.category_slug}}">
								<a class="cllctn_gllry " [ngClass]="item.category_images.length == 0 || item.category_images.length == 1 ? 'cllctn_sngl_img' : ''">
									<ng-container *ngIf="item.category_images && item.category_images.length > 2">
										<a class="gllry_img" *ngFor="let item3 of item.category_images | slice:0:3" >

											<img loading="lazy" appLazyLoad  [src]="env.IMAGE_COVER_WATERMARK+item3?.cover_image?.with_watermark" [alt]="item3.cover_image?.with_watermark" class="img-fluid" (error)="onImgError($event)">

											<!-- <img loading="lazy" appLazyLoad  [src]="env.IMAGE_COVER+''+item3?.cover_image?.without_watermark" [alt]="item3?.cover_image?.without_watermark" class="img-fluid"> -->
										</a>
									</ng-container>
									<ng-container *ngIf="item.category_images && item.category_images.length == 2"> 
										<a class="gllry_img" *ngFor="let item3 of item.category_images  | slice:0:2" >

											<img loading="lazy" appLazyLoad  [src]="env.IMAGE_COVER_WATERMARK+item3?.cover_image?.with_watermark" [alt]="item3.cover_image?.with_watermark" class="img-fluid" (error)="onImgError($event)">

											<!-- <img loading="lazy" appLazyLoad  [src]="env.IMAGE_COVER+''+item3?.cover_image?.without_watermark" [alt]="item3?.cover_image?.without_watermark" class="img-fluid"> -->
										</a>
									</ng-container>
									<ng-container *ngIf="item.category_images && item.category_images.length == 1"> 
										
										<a class="gllry_img" *ngFor="let item3 of item.category_images  | slice:0:1" >

											<img loading="lazy" appLazyLoad  [src]="env.IMAGE_COVER_WATERMARK+item3?.cover_image?.with_watermark" [alt]="item3.cover_image?.with_watermark" class="img-fluid" (error)="onImgError($event)">

										</a>
									</ng-container>
									<ng-container *ngIf="item.category_images && item.category_images.length == 0"> 
										<div class="gllry_img">
											<img loading="lazy" appLazyLoad  [src]="env.DEFAULT_IMAGE" [alt]="env.DEFAULT_IMAGE" class="img-fluid" (error)="onImgError($event)">
										</div>
									</ng-container>
										
									<!-- <div class="gllry_img">
										<img loading="lazy" appLazyLoad  src="https://images.pexels.com/photos/327098/pexels-photo-327098.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" class="img-fluid">
									</div>
									<div class="gllry_img">
										<img loading="lazy" appLazyLoad  src="https://images.pexels.com/photos/3026805/pexels-photo-3026805.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" class="img-fluid">
									</div> -->
								</a>
							</div>
							<div class="cllctn_hdng">
								<a>{{ item.category_name }}</a>
							</div>
							<div class="cllctn_tags" *ngIf="getCategoryTags(item).length">
								<ul>
									<li *ngFor="let item2 of getCategoryTags(item)">
										<a (click)="searchvalue(item2)" value='item2'>{{item2}}</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					
				</div>
				<div class="sectn_bttn text-center mt-5">
					<button class="btn btn_theme_filled" type="button" routerLink="/stock-photos/categories/">Explore More</button>
				</div>
			</div>
		</div>
	</section>


	<section class="latest_uploads ltst_dsgn">

		<div class="custom_container">

			<div class="sctn_hdng">
				<h2>Latest uploads</h2>
			</div>

			<div class="imgs_grp img_grp_new">

				<ul class="pia-imgs">
					<li style="height: auto; margin-bottom: 5px;" class=" MuiImageListItem-root MuiImageListItem-masonry  colm_grid_item colm_grid_itemm "  *ngFor="let image of this.latest_uploads" class="Imageitems_horizontal" >
						<a >
							<!-- [ngClass]="image?.orientation == 'vertical' ? 'Imageitems_vertical' : 'Imageitems_horizontal'" -->
							<div class="colm_grid_div" >
								<div class="colm_grid_img" >
									<a class="gllry_img" >
										<img loading="lazy" appLazyLoad [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark" class="img-fluid" [style.cursor]="'pointer'" routerLink="/moreinfo/{{image?.image_unique_id}}">
									</a>
								</div>
								<!-- <div class="" >
									
								</div> -->

								<div class="inner-content">
									<div class="inner-content-body">
										<div class="img_badge">
											<ul class="d-flex justify-content-end">
												<li class="img_dwnld ml-2" *ngIf="!image?.wishlists">
													<span data-title="Save" (click)="addToWishlist(image._id)"><i class="far fa-bookmark "></i></span>
												</li> 
												<li class="img_dwnld ml-2" *ngIf="image?.wishlists">
													<span data-title="Save" (click)="addToWishlist(image._id)"><i class="fas fa-bookmark"></i></span>
												</li> 
												<li class="img_dwnld ml-2">
													<span data-title="Similar Images"  routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
												</li>
												<li class="img_dwnld ml-2" *ngIf="image?.type=='vector'">
													<span data-title="Add to Cart" (click)="selectPic(image?.image_unique_id)"><mat-icon data-toggle="modal"
														data-target="#exclsv_rts">add_shopping_cart</mat-icon></span>
												</li>
												<li class="img_dwnld ml-2" *ngIf="image?.type!='vector'">
													<span data-title="Add to Cart"
														(click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
												</li>
											
											</ul>
										</div>
									</div>
								</div>
								
								<div class="custom-btm overlay_div">
									<div class="img_actn d-flex justify-content-between align-items-center">
										<!-- <div class="img_usr_div d-flex align-items-center">
											<div class="usr_img mr-2">
												<img loading="lazy" appLazyLoad   [src]="this.env.USER_PROFILE+image?.image_user_data?.profile_image"
												class="img-fluid" (error)="onImgError($event)">
											</div>
											<div class="usr_nm">
												<span>By {{image?.image_user_data?.first_name ? image?.image_user_data?.first_name + image?.image_user_data?.last_name: 'N/A'}}</span>
											</div>
										</div> -->
										<span class="font_16">{{image?.image_unique_id}}</span>

										<!-- <a class="btn btn_optn bg-white" (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)">
											<span
												><i class="fas fa-angle-double-down mr-2"></i
											></span>
											<span>Try</span>
										</a> -->

										<div class="font_16">
											<span title="Try" (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i class="fa fa-arrow-down"></i></span>
										</div>
						
									</div>
								</div>
								
							</div>
							
						</a>
					</li>
				</ul>

			</div>

			<div class="sectn_bttn text-center mt-5">
				<button class="btn btn_theme_filled" type="button" routerLink="/new-stock-images">Explore More</button>
			</div>

		</div>
	</section>



	<section class="subscribe_sctn">
		<div class="custom_container">
			<div class="row">
				<div class="col-md-7">
					<div class="subscribe_txt">
						<h2>Get the best visual content right here right now!</h2>
						<p>Build your brand from scratch and bring it to the limelight that it deserves. Develop exclusive
							content with the most eye-catching, authentic and in-vogue image library. Bring the visual
							imagination to reality and set yourself apart from the rest.
							Subscribe to our plans &amp; build a compelling brand identity!</p>
					</div>
				</div>
				<div class="col-md-5">
					<div class="subscb_mail">
						<form [formGroup]="form2">
							<div class="subscrb_input">
								<span class="snd_icn"><i class="fa fa-paper-plane"></i></span>
								<input type="email" class="form-control" placeholder="Enter your email ..." formControlName="email" > 
								<div *ngIf="form2.controls['email'].touched">
									<p class="danger err-msg"
										*ngIf="!form2.controls['email'].valid && (form2.controls['email'].hasError('required'))">
										Email is required*</p>
									<p *ngIf="form2.controls['email'].hasError('pattern') "
										class="danger err-msg"> Email is not valid*
									</p>
								</div>
							</div>
							<div class="subscrb_btn">
								<a class="btn btn_subs" (click)="subscribe()">Subscribe now</a>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</section>

	 <section class="blogs_sctn">
		<div class="custom_container">
			<div class="sctn_hdng">
				<h2>Blogs</h2>
			</div>
			<div class="ltst_uplds_wrap">
				<div class="blog_crds" >
					<div class="row">
						<div class="col-md-4" *ngFor="let blog of this.Blog">
							<div class="blog_card">
								<div class="blg_img">
									<a >
										<img loading="lazy" appLazyLoad  [src]="blog?._embedded['wp:featuredmedia'][0]?.['source_url']" class="img-fluid" (click)="blogNavigate(blog?.link)">
									</a>
									
								</div>
								<div class="blg_dtls">
									<div class="blgctg_dt">
										<a href="#" class="blg_ctgr mr-3">Places</a>
										<span>{{blog?.modified | date}}</span>
									</div>
									<div class="blg_ttl">
										<h5 (click)="blogNavigate(blog?.link)">{{blog?.title?.rendered}} </h5>
									</div>
									<div class="contn_txt">
										<a (click)="blogNavigate(blog?.link)" class="">Continue reading</a>
									</div>
								</div>
							</div>
						</div>
						<!-- <div class="col-md-4">
							<div class="blog_card">
								<div class="blg_img">
									<img loading="lazy" appLazyLoad  src="https://images.pexels.com/photos/12700785/pexels-photo-12700785.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" class="img-fluid">
								</div>
								<div class="blg_dtls">
									<div class="blgctg_dt">
										<a href="#" class="blg_ctgr mr-3">Places</a>
										<span>July 18, 2022</span>
									</div>
									<div class="blg_ttl">
										<h5>Nullam vel pellentesque massa, porttitor tempor risus</h5>
									</div>
									<div class="contn_txt">
										<a href="#" class="">Continue reading</a>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-4">
							<div class="blog_card">
								<div class="blg_img">
									<img loading="lazy" appLazyLoad  src="https://images.pexels.com/photos/210019/pexels-photo-210019.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" class="img-fluid">
								</div>
								<div class="blg_dtls">
									<div class="blgctg_dt">
										<a href="#" class="blg_ctgr mr-3">Places</a>
										<span>July 18, 2022</span>
									</div>
									<div class="blg_ttl">
										<h5>Integer eget dui maximus, faucibus tellus nec, condimentum enim</h5>
									</div>
									<div class="contn_txt">
										<a href="#" class="">Continue reading</a>
									</div>
								</div>
							</div>
						</div> -->
					</div>					
				</div>
				<div class="sectn_bttn text-center mt-5">
					<!-- <a class="btn btn_theme_filled" routerLink="/blogs">Explore More</a> -->
				</div>
			</div>
		</div>
	</section>

	

	<app-footer></app-footer>
</div>
<div
    class="modal fade payout_modllp"
    id="exclsv_rts"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header align-items-center justify-content-end">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Buy Exclusive Rights
                </h5> -->
                <button #refModal
                    type="button"
                    class="btn close_modl"
                    data-dismiss="modal"
                >
                    Close
                </button>
            </div>
            <div class="modal-body">
                <div class="payoutmodl_wrap">
                    <div class="address_form grey_form">
                        <div class="sngl_img_info">
							<div class="pricing_card text-center sngl_prcng_crd imgs_paycard">
								<div class="plan_cntnt">
									<div class="pln_nm">
										<h4>Single Image Pricing</h4>
										<p>
											Lorem ipsum dolor sit amet, consectetur
											adipisicing elit, sed do eiusmod tempor
											incididunt.
										</p>
									</div>
									<div class="snglimg_prcng">
										<div class="cart_tabl table_sizess cntct_form p-0 table-responsive">
											<table class="table">
												<thead>
													<tr>
														<th scope="col"></th>
														<th scope="col">
															Image Type
														</th>
														<th scope="col">
															Resolution
														</th>
														<th scope="col">
															Dimension (Pixels)
														</th>
														<th scope="col">
															Size (Inches)
														</th>
														<th scope="col">
															Price (Rs.)*
														</th>
														<th scope="col">
															File Format
														</th>
													</tr>
												</thead>
												<tbody>
													<tr *ngFor="let list of this.imagesizes;">
														<td>
															<mat-radio-button

																[value]="list?._id" (change)="getSize($event)"

																

															></mat-radio-button>
														</td>
														<!-- <td *ngIf="list?.image_type?.includes('extra_large')">Extre Large</td> -->
														<td >{{list?.image_type | titlecase}}</td>
														<td>{{list?.resolution}}</td>
														<td>{{list?.size}}</td>
														<td>{{list?.size_inches}}</td>
														<td>{{list?.price}}</td>
														<td>{{list?.format}}</td>
													  </tr>
													
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn_theme_filled" (click)="addToCart()">Buy</button>
            </div>
        </div>
    </div>
</div>