import { Component, OnInit } from '@angular/core';
import { UserProfileResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit {
  profile: any;
  user_id: any;

  constructor( private service: HttpService,) { }

  ngOnInit(): void {
   this. getUserProfile()
  }

  getUserProfile() {

    this.service.getUserProfile().subscribe((resp: UserProfileResponse) => {
      this.profile = resp.data;
      this.user_id=resp.data._id
    })
  }


}
