import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { CollectionImage, SingleImageDataResponse } from 'src/app/models/model';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private Cookie:CookieService) { }

  /**
   * Get first image data from the Image array
   * @param {Array} images - images data
   * @param {Object} extra - If extra params coming
   */  
  public getSingleImageData(images:CollectionImage[], extra?:any): SingleImageDataResponse {

    if(images.length > 0){
      return {
        data : images[0],
      }
    }else{
      return {
        data : null,
      }
    }


  }
  setCookie(role:any){
    this.Cookie.set('role',role)
  }

  getCookie() {
    let role = this.Cookie.get('role')
    return role

  }
  deleteCookie(){
    this.Cookie.delete('role', '/', 'localhost', false, 'Lax');
  }
 

}
