<div class="main_home_wraper">
  <app-header-cmn></app-header-cmn>

  <div class="inner_wrap_sectn">
    <section class="contact_top">
      <div class="custom_container">
        <div class="contactus_wrap">
          <div class="pg_hdng">
            <h1>Upcoming Casting Events</h1>
            <div class="pg_brdcrmb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Upcoming Casting Events
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="contact_us blogs_sctn events_lstng">
      <div class="custom_container">
        <div class="ltst_uplds_wrap">
          <div class="blog_crds">
            <div class="row" >
              <div class="col-md-4" *ngFor="let event of events ">
                <div class="blog_card">
                  <div class="blg_img">
                    <img
                      [src]="env.EVENTCOVER+event.cover_image"
                      class="img-fluid"
                    />
                  </div>
                  <div class="blg_dtls">
                    <div class="blgctg_dt">
                      <span>{{event.datetime | date:'MMM d, y'}}</span>
                    </div>
                    <div class="blg_ttl">
                      <h5 routerLink="/budding-Models/{{event._id}}">
                        {{event.title}}
                      </h5>
                      <p>
                       {{event.description}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            
            </div>
            
          <div class="pagntn_dsn text-center mt-5" *ngIf="events.length!=0">
            <ul class="pagination">
              <pagination-controls (pageChange)="pageChanged($event)" [responsive]="true"  ></pagination-controls>
            </ul>
          </div>

          <div class="text-center" *ngIf="events.length==0">
            

            <h2 class=" mt-3" ><strong>No Data Found</strong></h2>
          
          
          </div>
          
          <div class="bg_clr" *ngIf="show">
            <img class="loading" src="assets/images/NUBlack.png" alt="">
        </div>
      </div>
        </div>
      </div>
    </section>
  </div>

  <app-footer></app-footer>
</div>
