import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CheckoutComponent } from './checkout/checkout.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
// import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { IndexComponent } from './index/index.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { TermsnconditionsComponent } from './termsnconditions/termsnconditions.component';
import { PhotoeventsListComponent } from './photoevents-list/photoevents-list.component';
import { BuddingModelsComponent } from '../user/budding-models/budding-models.component';
import { EventFormComponent } from './event-form/event-form.component';
import { CategoriesComponent } from './categories/categories.component';
import { EmailVerifiedComponent } from './email-verified/email-verified.component';
import { PricingComponent } from './pricing/pricing.component';
import { ImageDetailsComponent } from './image-details/image-details.component';
import { ExploreComponent } from './explore/explore.component';
import { UnsuccessfulComponent } from './unsuccessful/unsuccessful.component';
import { SuccessfulComponent } from './successful/successful.component';
import { PaymentcancelComponent } from './paymentcancel/paymentcancel.component';
import { ResetComponent } from './reset/reset.component';
import { ExploreImagesComponent } from './explore-images/explore-images.component';
import { ExploreShootComponent } from './explore-shoot/explore-shoot.component';
import { ExploreModelComponent } from './explore-model/explore-model.component';
import { ExploreSimilarComponent } from './explore-similar/explore-similar.component';
import { ExploreCategoryComponent } from './explore-category/explore-category.component';
import { ExploreSimilarTagsComponent } from './explore-similar-tags/explore-similar-tags.component';
import { ExploreSearchComponent } from './explore-search/explore-search.component';
import { AuthGuard } from '../auth/auth.guard';

const routes: Routes = [
  {
    path: "",
    component: IndexComponent
  },
  {
    path: 'contact-us',
    pathMatch: 'full',
    component: ContactUsComponent
  },
  {
    path: 'pricing',
    pathMatch: 'full',
    component: PricingComponent
  }, {
    path: 'about-us',
    pathMatch: 'full',
    component: AboutUsComponent
  }, {
    path: 'terms-conditions',
    pathMatch: 'full',
    component: TermsnconditionsComponent
  },
  {
    path: 'privacy-policy',
    pathMatch: 'full',
    component: PrivacyPolicyComponent
  },
  {
    path: 'terms-of-use',
    pathMatch: 'full',
    component: TermsOfServiceComponent
  },
  {
    path: 'faq',
    pathMatch: 'full',
    component: FaqComponent
  },
  //  {
  //   path: 'how-its-work',
  //   pathMatch: 'full',
  //   component:  HowItWorksComponent
  // },
  {
    path: 'pricing',
    pathMatch: 'full',
    component: PricingComponent
  }, 
  {
    path: 'checkout',
    pathMatch: 'full',
    component: CheckoutComponent,
    canActivate: [AuthGuard]
  },
 
  
  {
    path: 'checkout/:subscriptionId',
    pathMatch: 'full',
    component: CheckoutComponent,
    canActivate: [AuthGuard]
  },

  {
    path: 'photoEventsList',
    pathMatch: 'full',
    component: PhotoeventsListComponent
  }, {
    path: 'budding-Models/:id',
    pathMatch: 'full',
    component: BuddingModelsComponent
  }, {
    path: 'eventForm/:id',
    pathMatch: 'full',
    component: EventFormComponent
  }, {
    path: 'stock-photos/categories',
    pathMatch: 'full',
    component: CategoriesComponent
  }, {
    path: 'new-stock-images',
    pathMatch: 'full',
    component: ExploreImagesComponent

  }, {
    path: 'stock-photos/:type/:type2',
    pathMatch: 'full',
    component: ExploreCategoryComponent
  }, {
    path: 'stock-photos/:type',
    pathMatch: 'full',
    component: ExploreCategoryComponent
  }, {
    path: 'entireshoot/:type',
    pathMatch: 'full',
    component: ExploreShootComponent
  }, {
    path: 'samemodelimages/:type',
    pathMatch: 'full',
    component: ExploreModelComponent
  }
  , {
    path: 'similarImages/:type',
    pathMatch: 'full',
    component: ExploreSimilarComponent
  }, {
    path: 'similarTags/:selectedTags/:allTags',
    pathMatch: 'full',
    component: ExploreSimilarTagsComponent
  }, {
    path: 'new-stock-images/:type',
    pathMatch: 'full',
    component: ExploreSearchComponent
  }
  , {
    path: 'EmailVerified',
    pathMatch: 'full',
    component: EmailVerifiedComponent
  }, {
    path: 'moreinfo/:id',
    pathMatch: 'full',
    component: ImageDetailsComponent
  },
  // {
  //   path: 'Explore/:data/:type',
  //   pathMatch: 'full',
  //   component: ExploreComponent
  // },
  {
    path: 'payment/success',
    pathMatch: 'full',
    component: SuccessfulComponent
  },
  {
    path: 'payment/failed',
    pathMatch: 'full',
    component: UnsuccessfulComponent
  },
  {
    path: 'payment/cancel',
    pathMatch: 'full',
    component: PaymentcancelComponent
  },
  {
    path: 'change-password/:id',

    component: ResetComponent
  },

];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HomeRoutingModule { }
