export const environment = {
  production: true,
  // SERVER_URL : "https://developers.promaticstechnologies.com:3009/",
  SERVER_URL : "https://api.nustockimages.com:3009/",
  // IMAGE_COVER : "https://nustock.s3.amazonaws.com/public/cover/",
  IMAGE_COVER : "https://nustock-visible.s3.amazonaws.com/public/watermark/",
  IMAGE_COVER_WATERMARK : "https://nustock-visible.s3.amazonaws.com/public/watermark/",
  USER_PROFILE : "https://nustock-visible.s3.amazonaws.com/public/user_profile/",
  USER_COVER : "https://nustock-visible.s3.amazonaws.com/public/user_cover_image/",
  DEFAULT_IMAGE: "https://nustock-visible.s3.amazonaws.com/public/default/1683809311890Nu-stock-Default-image.png",
  CATEGORY_IMAGE: "https://nustock-visible.s3.amazonaws.com/public/categories/",
  PRESTIGIOUS_IMAGE: "https://nustock-visible.s3.amazonaws.com/public/home/",
  ABOUTIMAGES:"https://nustock-visible.s3.amazonaws.com/public/about_us_images/",
  EVENTCOVER:"https://nustock-visible.s3.amazonaws.com/public/event_cover_image/",
  ExploreImageSize:'https://api.nustockimages.com/php/resize.php?url=https://nustock-visible.s3.amazonaws.com/public/watermark/',
  firebase : {
    apiKey: "AIzaSyDeZEXayqklRdA5tBZ_uT_wRNh7oyfF2OU",
    authDomain: "nustock-378510.firebaseapp.com",
    projectId: "nustock-378510",
    storageBucket: "nustock-378510.appspot.com",
    messagingSenderId: "731997725121",
    appId: "1:731997725121:web:bb0d0bdde1780ec5ce0f40",
    measurementId: "G-2WPZRJKGG9"
  }
};
