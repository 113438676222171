<div class="main_home_wraper">
	
	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<h1>FAQ</h1>
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
							    <li class="breadcrumb-item"><a href="#">Home</a></li>
							    <li class="breadcrumb-item active" aria-current="page">FAQ</li>
							  </ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="contact_us faq_pg">
			<div class="custom_container">
				<div class="faq_wrap">
					<div class="row">
						<div class="col-md-12">
							<div class="faq_div" *ngFor="let x of FaqResponseData">
								<mat-accordion>
								  <mat-expansion-panel>
								    <mat-expansion-panel-header>
								      <mat-panel-title>
										{{x.question}}
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <p>{{x.answer}}</p>
								  </mat-expansion-panel>

								  <!-- <mat-expansion-panel>
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        Why don’t you have photos?
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
								    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
								    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
								    consequat.</p>
								  </mat-expansion-panel>

								  <mat-expansion-panel>
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        How do I download free photos?
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
								    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
								    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
								    consequat.</p>
								  </mat-expansion-panel> -->
								</mat-accordion>
							</div>
						</div>
						<!-- <div class="col-md-6">
							<div class="faq_div">
								<mat-accordion>
								  <mat-expansion-panel>
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        Why don’t you have photos?
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
								    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
								    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
								    consequat.</p>
								  </mat-expansion-panel>

								  <mat-expansion-panel>
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        How do I download free photos?
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
								    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
								    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
								    consequat.</p>
								  </mat-expansion-panel>

								  <mat-expansion-panel>
								    <mat-expansion-panel-header>
								      <mat-panel-title>
								        How do I download free photos?
								      </mat-panel-title>
								    </mat-expansion-panel-header>
								    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
								    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
								    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
								    consequat.</p>
								  </mat-expansion-panel>
								</mat-accordion>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</section>	
	</div>
	

	<app-footer></app-footer>
</div>