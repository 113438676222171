import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { Category, CategoryResponse, Loginreceive, Registerrecieve } from 'src/app/models/model';
import { HelperService } from 'src/app/services/helper/helper.service';
import { HttpService } from 'src/app/services/http/http.service';
declare let $:any
@Component({
  selector: 'app-header-cmn',
  templateUrl: './header-cmn.component.html',
  styleUrls: ['./header-cmn.component.scss']
})
export class HeaderCmnComponent implements OnInit {

  isShow = true;
  isHide = false;
  user: any = null
  role: any;
  userdetail: any;
  userdetail1: any;
  cartlength: any;
  limit:number = 5;
  offset:number = 0;
  seen:boolean = true;
  isLoggedUser:boolean = false
  form1: FormGroup;
  form2: FormGroup;
  submitted: boolean = false;
  @ViewChild('refModal', { static: true })
  myModal!: ElementRef<any>; 
  href: string='';
  //  @ViewChild('refModal1', { static: true })
  // myModal1!: ElementRef<any>; 
  @ViewChild('refModal1') myModal1: ElementRef | undefined;
  constructor(private service: HttpService, private router: Router, private cookie: HelperService,private fb: FormBuilder ,public afAuth: AngularFireAuth) {
    // if (this.service.getToken()) {
    //   this.isLoggedUser = true;
    // }

    // this.user = this.service.loggedUserDetails();
    this.LoginType()
    this.form1 = this.fb.group({
      first_name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      last_name: ['', [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      email: ['', [Validators.required,  Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10)]],
      password1: ['', [Validators.required, Validators.minLength(8), Validators.pattern(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$/)]],
      password2: ['', [Validators.required]],
      check: [false, [Validators.requiredTrue]],

    }, {
      validators: [PasswordValidator.confirmed('password1', 'password2')],
    }
    )
    this.form2 = this.fb.group({
      email: ['', [Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required]],
    })
  
  }
  
  ngOnInit(): void {
    document.addEventListener('click', this.handleDocumentClick.bind(this));
    this.href = this.router.url;
    this.getAllCategories()
    if(this.service.getToken()){
      this.isLoggedUser = true;
      this.getNotification();
    }
    this.role = this.cookie.getCookie()
 
    this.getCart()
    this.cartNumber()
    this.userDetails()
    this.user = this.service.loggedUserDetails();
   
  }





  textInputFormControl = new FormControl('');


  onSubmit(): any {
    localStorage.setItem('email',this.textInputFormControl.value)
    const data = {
      email: this.textInputFormControl.value
    }

    this.service.forgotPassword(data).subscribe((resp) => {
      this.service.showSuccessMessage({
        message: 'Link sent to email'
      })
      
    },(err)=>{
      this.service.showSuccessMessage({
        message: err
      })
    })
  }


  LoginType(){
     this.service.checkLoggedType.subscribe((res:any)=>{
      const loginType=JSON.parse(res)
      if(loginType.loginType==true){
        $("#sign_modl").modal('show');
      }
    })
    

}

contribute(){
  if(!this.service.getToken()){
this.service.showSuccessMessage({message:'Please Login First'})
    $("#sign_modl").modal('show');

  }else{
    this.router.navigate(['/user/contribute'])
  }
}

  notificationDatalist:any [] =[];
  unseenNotification:any = 0;
  getNotification(){
    let obj = {
      limit:this.limit,
      offset:this.offset,
      seen:this.seen,
    }
    this.service.getNotifications(obj).subscribe((resp:any) => {
  
    if(resp?.code === 200){
    this.notificationDatalist = resp?.data;
    this.unseenNotification = resp?.unseen_notification;
    }
    },(event:HttpErrorResponse) => {
      this.service.showErrorMessage({
        message:event?.error?.errors?.msg
      })
    })
  }

  showDropdown :boolean= false;

  paginate(){
    this.showDropdown = true;
    this.limit +=10
    this.offset=0
    this.getNotification()
  } 
  handleDocumentClick(event: MouseEvent) {
    const dropdownMenu:any = document.querySelector('.dropdown-menu');
    if (!dropdownMenu.contains(event.target as Node)) {
      this.showDropdown = false;
    }
  }
  userDetails(){
    if(this.service.updateUserDetails){
      this.service.updateUserDetails.subscribe((data: any) => {
        localStorage.setItem('user_details',data)
        this.userdetail = localStorage.getItem('user_details')
        this.userdetail1 = JSON.parse(this.userdetail)
        this.user=this.userdetail1
      })
    }else{
      this.userdetail = localStorage.getItem('user_details')
      this.userdetail1 = JSON.parse(this.userdetail)
    }
    
  }
  
  async GoogleAuth() {

    const provider = new GoogleAuthProvider();
    try {
      const data: any = await this.afAuth.signInWithPopup(provider);
      const obj = {
        social_id: data?.additionalUserInfo?.profile?.id,
        login_type: 'google',
        role: 'user',
        last_name: data?.additionalUserInfo?.profile?.family_name,
        first_name: data?.additionalUserInfo?.profile?.given_name,
        email: data?.additionalUserInfo?.profile?.email

      }
      this.service.socialLogin(obj).subscribe((resp) => {
        localStorage.setItem('remember_me', 'yes');
        localStorage.setItem('user_details', JSON.stringify(resp.user))
        localStorage.setItem('token', resp.token);
        this.myModal.nativeElement.click();
        this.service.setCookies({
          key: 'role',
          value: resp.user.role
        })
        this.service.showSuccessMessage({
          message: "Login Successfully"
        })
        this.refresh()
        this.ngOnInit()
      }, (err) => {
        this.service.showSuccessMessage({
          message: err
        })
      })

    } catch (error: any) {
      if (error.code === 'auth/popup-closed-by-user') {
        this.service.showErrorMessage({ message: error })

      } else {
        this.service.showErrorMessage({message:error})
      }
    }


















  }
  refresh() {
    this.router.navigate(['/'], {
      skipLocationChange: true
    }).then(res => {
      this.router.navigate([this.href])
    })
  }

  async FacebookAuth() {
    const provider = new FacebookAuthProvider();
    try{
      const data:any = await this.afAuth.signInWithPopup(provider);
      const obj={
        social_id:data?.additionalUserInfo?.profile?.id,
        login_type:'facebook',
        role:'user',
        last_name:data?.additionalUserInfo?.profile?.first_name,
        first_name:data?.additionalUserInfo?.profile?.last_name,
        email:data?.additionalUserInfo?.profile?.email

      }
      this.service.socialLogin(obj).subscribe((resp)=>{
        localStorage.setItem('remember_me', 'yes');
        localStorage.setItem('user_details', JSON.stringify(resp.user))
        localStorage.setItem('token', resp.token);
        this.myModal.nativeElement.click();
        this.service.setCookies({
          key: 'role',
          value: resp.user.role
        })
        this.service.showSuccessMessage({
          message : "Login Successfully"
        })
        this.refresh()
        this.ngOnInit()
      },(err)=>{
        this.service.showSuccessMessage({
          message : err
        })
      })
    
    }catch(error:any){
      if (error.code === 'auth/popup-closed-by-user') {
        this.service.showErrorMessage({message:error})
     
      } else {
        this.service.showErrorMessage({message:error})
      }
    }
 
  }
     show: boolean=true;
     show1: boolean=true;
     show2: boolean=true;
     showPassword(inputid: any) {
      let x: any = document.getElementById(inputid);
  
      if (inputid == 'pswd') {
        if (x.type === "password") {
          x.type = "text";
          this.show = false
        } else {
          x.type = "password";
          this.show = true
        }
      } else if (inputid == 'pswd1') {
        if (x.type === "password") {
          x.type = "text";
          this.show1 = false
        } else {
          x.type = "password";
          this.show1 = true
        }
      } else if (inputid == 'pswd2') {
        if (x.type === "password") {
          x.type = "text";
          this.show2 = false
        } else {
          x.type = "password";
          this.show2 = true
        }
      }
  
    }
      numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
    socialUser:any
  

  toggleDisplay() {
    this.isShow = !this.isShow;
    this.isHide = !this.isHide;
  }

  
  getAllCategories():void{

    this.service.categories().subscribe((resp:CategoryResponse) => {

      this.all_categories = resp.data;

    })

  }
  all_categories !: Category[];
  logout() {
    window.localStorage.clear();
    window.sessionStorage.clear();
    this.cookie.deleteCookie()
    this.router.navigate(['/'])

  }


  
  navigate(){
    this.router.navigate(['checkout'])
  }
  getCart(){
    this.service.getCart().subscribe((Res)=>{
      this.cartlength=Res.data.length
    })
  }

  cartNumber() {
    this.service.updateCartNumber.subscribe((data: any) => {
      this.cartlength = data
    })
  }
  showSidebar:boolean=false
   openNav(type:any) {
    if(type=='open'){
      this.showSidebar=true
    }else{
      this.showSidebar=false
    }
    
  }
  activeCate:any=''

  getCat(id: any,name:any) {
    this.activeCate = id
    let obj={
      id:id,
      name:name
    }
    if(this.href.includes('/user/moreinfo')){      
 let search=id
    let type='categoryImages'
    this.router.navigate(['/user/Explore/'+search+'/'+type])
    }else{
      this.service.servicegetActiveCat(JSON.stringify(obj))

    }
   
    this.showSidebar = false
  }
  seenNotifications(){
    this.service.seeAllNotification({id:''}).subscribe(()=>{
      this.ngOnInit()
    })
  }

  submit(): any {
    if (this.form1.valid) {

      const obj = {
        first_name: this.form1.value.first_name,
        last_name: this.form1.value.last_name,
        email: this.form1.value.email,
        phone: this.form1.value.phone,
        password: this.form1.value.password1
      }

      $("#sign_modl").modal("hide");

      this.service.register(obj).subscribe((res: Registerrecieve) => {
        this.service.showSuccessMessage({
          message : "Registered Successfully"
        })
      })
      this.form1.reset();
      this.router.navigate(['/'])

    }
    else {
      this.form1.markAllAsTouched()
    }
  }


  login(): any {
    this.submitted = true
    if (this.form2.valid) {
      const data = {
        email: this.form2.value.email,
        password: this.form2.value.password
      }
      this.service.login_data(data).subscribe((resp: Loginreceive) => {
    
        localStorage.setItem('remember_me', 'yes');
        localStorage.setItem('user_details', JSON.stringify(resp.user))
        localStorage.setItem('token', resp.token);
   
        this.myModal.nativeElement.click();
        
        
        this.service.setCookies({
          key: 'role',
          value: resp.user.role
        })
        this.ngOnInit()
      },(err:any)=>{
        const error=err.error
        if(error.needVerifiedEmail){
          this.router.navigate(['/EmailVerified'])
        }
      })
    } else {

      this.form2.markAllAsTouched()

    }
  }
 
}
export class PasswordValidator {
  static confirmed = (controlName: string, matchingControlName: string) => {
    return (control: AbstractControl): ValidationErrors | null => {
      const input = control.get(controlName);
      const matchingInput = control.get(matchingControlName);

      if (input === null || matchingInput === null) {
        return null;
      }

      if (matchingInput?.errors && !matchingInput.errors.pwdNotSame) {
        return null;
      }

      if (input.value !== matchingInput.value) {
        matchingInput.setErrors({ pwdNotSame: true });
        return ({ pwdNotSame: true });
      } else {
        matchingInput.setErrors(null);
        return null;
      }
    };
  }
}