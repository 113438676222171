<div class="main_home_wraper">

    <!-- <app-header-cmn></app-header-cmn> -->

    <div class="inner_wrap_sectn">
        <section class="contact_us privacy_policy">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <div class="policy_wrap emil_vrf text-center">
                            <h1>Verify Your Email</h1>
                            <p class="mt-3 need_verfiy">
                                You Will Need To Verify Your Email To Complete registration
                            </p>
                            <div class="div_illustration_email text-center mt-3 mb-3">
                                <img src="assets/images/Email-Verified.svg" alt="">
                            </div>
                            <p class="mt-2 email_text">
                                An Email Has Been Send To Register mail With a Link To Verify Your Account.If You
                                Have Not Received the Email After a Few Minutes, Please Check Your Spam Folder
                            </p>
                            <div class="sectn_bttn text-center mt-5 mb-3">
                                <!-- <a class="btn btn_theme_filled mr-3">Resend Email</a> -->
                                <a class="btn btn_theme_filled OtLine_btn" routerLink="/">Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>


    <!-- <app-footer></app-footer> -->
</div>