import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CategoriesImagesResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  categories_with_images:any[]=[]
  env = environment
  constructor(private service: HttpService,private router:Router) { }

  ngOnInit(): void {
   
    this.getCategoriesWithImages();
    
  }
  // getCategoriesWithImages(): void {

  //   this.service.getCategoriesWithImages({
  //     limit : Number.MAX_SAFE_INTEGER,
  //     offset : 0,
  //   }).subscribe((resp:CategoriesImagesResponse) => {

  //     this.categories_with_images = resp.data;
  //   })

  // }

   getCategoriesWithImages(): void {

    this.service.getCategoriesWithImages({
      limit : Number.MAX_SAFE_INTEGER,
      offset : 0,
    }).subscribe((resp:CategoriesImagesResponse) => {

      this.categories_with_images = resp.data;

    })

  }



  getCategoryTags(item:any): any{

    var tags:any = []
    for(let i = 0; i < item.category_images.length; i++){
        for(let j = 0; j < item.category_images[i].tags.length; j++){
            tags.push(item.category_images[i].tags[j]);
        }
    }

    if(tags.length > 8){
      let eight_tags = tags.slice(0, 8);
      tags = eight_tags
      return tags
    }
    else{
      return tags
    }

  }
  serachdata: any='';
  phototype:any='none'
  
  applyFilter() {
    if(this.serachdata){
      let search=this.serachdata
      let type=this.phototype
      this.router.navigate(['/user/Explore/'+search+'/'+type])  
     }

  }
  getPhototype(type:any){
    this.phototype=type
  }
  navigateCategory(id:any,name:any){
    let obj={
      id:id,
      name:name
    }
    this.service.servicegetActiveCat(JSON.stringify(obj))
    let search=id
    let type='categoryImages'
    this.router.navigate(['/user/Explore/'+search+'/'+type])
  }


  onImgError(event:any) { 
    event.target.src = this.env.DEFAULT_IMAGE;
  }


    searchvalue(a:any){
        let search = a
      let type = this.phototype
    
      
      const queryParams = { search: search };
      if(a){
        this.router.navigate(['/new-stock-images/' + type],{ queryParams: queryParams })
      }else{
        this.router.navigate(['/new-stock-images/' + type])
      }
  }
}
