import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-explore-similar',
  templateUrl: './explore-similar.component.html',
  styleUrls: ['./explore-similar.component.scss']
})
export class ExploreSimilarComponent implements OnInit {
  total: any
  exploreData: any
  filterKeywords: any[] = []
  name = new FormControl('');
  phototype: any = 'none'
  limit = 45
  offset = 0
  env = environment
  show: boolean = true
  @ViewChild('refModal', { static: true })
  myModal!: ElementRef<any>;
  wishlistdata: any[] = []
  constructor(private route: ActivatedRoute, private service: HttpService, private loader: NgxUiLoaderService, private router: Router) { }

  ngOnInit(): void {
    this.type = this.route.snapshot.paramMap.get('type')

    this.searchingText = 'Similar Images'
    this.keyFilters()
    this.getSimilar()
    if (this.service.getToken()) {
      this.getWishList()
    }
  }

  addToWishlist(id: any) {

    if (this.service.getToken()) {
      const obj = {
        collection_img_id: id
      }
      this.service.addwhishlist(obj).subscribe((res: any) => {

        if (res.data == "Saved") {
          this.service.showSuccessMessage({ message: "The image saved successfully" })
          this.getWishList()
        } else {
          this.service.showSuccessMessage({ message: "The image remove successfully" })
          this.getWishList()
        }

      })

    } else {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: "Please Login First" })
    }
  }
  getSize(event: any) {
    this.selectedsize = event.value
  }

  getWishList() {
    this.service.getWishList().subscribe((res: any) => {

      this.wishlistdata = res?.data.map((res: any) => res?.collection_img_id?._id)

    })
  }



  type: any = ''


  getSimilar(isScrolltoTop = false) {
    const obj: any = {
      keywords: this.type,
      limit: this.limit,
      offset: this.offset,


    }
    if (this.phototype != 'none' && this.phototype) {
      obj.type = this.phototype
    }
    if (this.name.value) {
      obj.search = this.name.value

    } if (this.filterKeywords.length > 0) {
      obj.keywords = this.filterKeywords
    }
    this.service.getSimilarImages(obj).subscribe((res) => {
      this.exploreData = res.data
      this.total = res.count
      this.show = false

      if(isScrolltoTop){
        window.scrollTo(0, 0) 
      }

    })
  }


  getPhototype(type: any) {

    this.phototype = type


  }


  // applyFilter(): any {
  //   this.page = 1
  //   let obj: any
  //   obj = {
  //     limit: this.limit,
  //     offset: 0,
  //     Keywords:this.type

  //   }
  //   this.searchingText = this.name.value

  //   if (this.phototype != 'none' && this.phototype) {
  //     obj.type = this.phototype
  //   }

  //   if (this.name.value) {
  //     obj.search = this.name.value
  //   }
  //   if (this.filterKeywords.length > 0) {
  //     obj.keywords = this.filterKeywords
  //   }


  //   this.service.getSimilarImages(obj).subscribe((resp) => {
  //     this.exploreData = resp?.data
  //     this.total = resp.count

  //   })
  // }
  applyFilter() {

    let search = this.name.value
    let type = this.phototype
    const queryParams = { search: search };
    if (this.name.value) {
      this.router.navigate(['/new-stock-images/' + type], { queryParams: queryParams })
    } else {
      this.router.navigate(['/new-stock-images/' + type])
    }



  }


  searchingText: any = ''
  keysWords: any[] = []
  keyFilters() {
    this.service.keyFilters().subscribe((res) => {
      this.keysWords = res.data
    })
  }

  page: number = 1

  onCheckboxChange(data: any) {
    this.page = 1
    let obj: any = {
      limit: this.limit,
      offset: 0,
      Keywords: this.type
    }
    if (data.checked) {
      if (this.phototype != 'none' && this.phototype) {
        obj.type = this.phototype
      }
      this.filterKeywords.push(data.source.value)
      obj.keywords = this.filterKeywords

      if (this.name.value != '') {
        obj.search = this.name.value
      }

      this.service.getSimilarImages(obj).subscribe((res) => {
        this.exploreData = res?.data
        this.total = res.count;
        this.show = false
      })

    } else {
      let arr1 = this.filterKeywords.indexOf(data.source.value)
      this.filterKeywords.splice(arr1, 1)
      if (this.filterKeywords?.length > 0) {
        obj.keywords = this.filterKeywords
      }
      if (this.phototype != 'none' && this.phototype) {
        obj.type = this.phototype
      }
      if (this.name.value != '') {
        obj.search = this.name.value
      }
      this.service.getSimilarImages(obj).subscribe((res) => {
        this.exploreData = res?.data
        this.total = res.count;
        this.show = false
      })

    }


  }

  getChecked(tags: any): boolean {
    if (this.filterKeywords.includes(tags)) {
      return true
    } else {
      return false
    }
  }

  cartlength: any;
  eventEmit(res: any) {
    this.service.updateCartViaNumberApi((JSON.stringify(res)))
  }
  getCart() {
    this.service.getCart().subscribe((Res) => {

      this.cartlength = Res?.data?.length
      this.eventEmit(this.cartlength)

    })
  }
  selectedsize: any = '';


  addToCartForVector(imageID: any) {
    let object = {
      collection_img_id: imageID,

    }
    this.service.addCart(object).subscribe((resp: any) => {
      if (resp?.code == 200) {
        this.service.showSuccessMessage({
          message: "Added to Cart"
        })
        this.getCart()
        this.selectedsize = ''

      }
    }, (error: HttpErrorResponse) => {
      this.service.showErrorMessage({
        message: error?.error?.errors
      })
    })
  }
  selectedpicture: any;
  pricingdata: any[] = []
  selectPic(id: any) {

    this.service.getImageDetail(id).subscribe((resp: any) => {
      if (resp.code == 200) {
        this.selectedpicture = resp?.image_details?.id;
        let sizes = resp?.image_details.available_sizes.map((res: any) => res.image_type)
        const obj = {
          ids: sizes.toString()
        }
        this.service.getPricing1(obj).subscribe((res) => {
          this.pricingdata = res.data;
        })
      }


    })
  }


  async downloadImage(image: any, name: any) {

    const imgName = image;

    this.loader.start();
    const file = await fetch(imgName + '?r=' + Math.floor(Math.random() * 100000)).then(function (response) {
      return response.blob();
    })

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = 'watermark_' + name;
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));
    this.loader.stop();
    setTimeout(() => { // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }

  addToCart() {
    const obj = {
      collection_img_id: this.selectedpicture,
      available_size_id: this.selectedsize
    }
    if (this.selectedsize !== '') {
      this.service.addCart(obj).subscribe((res: any) => {

        this.myModal.nativeElement.click();
        this.getCart()
        this.service.showSuccessMessage({ message: "add to cart successfully" })
      })
    }
    else {
      this.service.showErrorMessage({ message: "Please select Size First" })
    }
  }


  viewall(data: any, viewtype: any) {


    let search = data
    let type = viewtype
    this.router.navigate(['/'], {
      skipLocationChange: true
    }).then(res => {
      this.router.navigate(['/user/Explore/' + search + '/' + type])
    })

  }


  pageChanged(event: any) {
    this.page = event
    this.limit = 45,
      this.offset = (event - 1) * 45
    this.getSimilar(true)
  }

}
