<div class="main_home_wraper">
  <app-header-cmn></app-header-cmn>

  <div class="inner_wrap_sectn">
    <section class="contact_top">
      <div class="custom_container">
        <div class="contactus_wrap">
          <div class="pg_hdng">
            <h1>Apply for Event</h1>
            <div class="pg_brdcrmb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item"><a href="#">Home</a></li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Apply for Event
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="contact_us">
      <div class="custom_container">
        <div class="row">
          <div class="col-md-9">
            <div class="cntct_form_wrp">
              <div class="cntct_form grey_form">
                <h3 class="mb-3">Fill your details</h3>
                <form [formGroup]="applyEventForm" (ngSubmit)="eventSubmit()">

                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label>First Name</label>
                      <input type="text" class="form-control" placeholder="First Name" formControlName="first_name"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                      <div
                        *ngIf="(applyEventForm.controls.first_name?.touched || applyEventForm.controls.first_name?.dirty)
                      && applyEventForm.controls.first_name?.invalid && applyEventForm.controls.first_name?.errors?.required"
                        style="color:red">
                        Please enter first name
                      </div>
                      <div style="color: #f90202" *ngIf="applyEventForm.controls['first_name'].errors?.pattern">
                        Please enter only alphabets
                      </div>
                    </div>

                    <div class="form-group col-md-6">
                      <label>Last Name</label>
                      <input type="text" class="form-control" placeholder="Last Name" formControlName="last_name"
                        oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                      <div
                        *ngIf="(applyEventForm.controls.last_name?.touched || applyEventForm.controls.last_name?.dirty)
                      && applyEventForm.controls.last_name?.invalid && applyEventForm.controls.last_name?.errors?.required"
                        style="color:red">
                        Please enter last name
                      </div>
                      <div style="color: #f90202" *ngIf="applyEventForm.controls['last_name'].errors?.pattern">
                        Please enter only alphabets
                      </div>
                    </div>

                  </div>

                  <div class="form-group">
                    <label>Company name (optional)</label>
                    <input class="form-control" value="" placeholder="Company Name" formControlName="company_name"
                      oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                    <div
                      *ngIf="(applyEventForm.controls.company_name?.touched || applyEventForm.controls.company_name?.dirty)
                    && applyEventForm.controls.company_name?.invalid && applyEventForm.controls.company_name?.errors?.required"
                      style="color:red">
                      Please enter company name
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Country (optional)</label>
                    <!-- <input type="text" class="form-control" placeholder="Country (optional)" /> -->
                    <select class="form-control" formControlName="country">
                      <option disabled selected>Selected Country</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="d">D</option>
                    </select>
                    <div *ngIf="(applyEventForm.controls.country?.touched || applyEventForm.controls.country?.dirty)
                    && applyEventForm.controls.country?.invalid && applyEventForm.controls.country?.errors?.required"
                      style="color:red">
                      Please enter country
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Town / City</label>
                    <!-- <input type="text" class="form-control" placeholder="Town / City" /> -->
                    <select class="form-control" formControlName="city_town">
                      <option disabled selected>Selected City</option>
                      <option value="a">A</option>
                      <option value="b">B</option>
                      <option value="c">C</option>
                      <option value="d">D</option>
                    </select>
                    <div
                      *ngIf="(applyEventForm.controls.city_town?.touched || applyEventForm.controls.city_town?.dirty)
                    && applyEventForm.controls.city_town?.invalid && applyEventForm.controls.city_town?.errors?.required"
                      style="color:red">
                      Please enter city/town
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Address</label>
                    <textarea name="" id="" class="form-control" placeholder="Enter address" formControlName="address"
                      oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"></textarea>
                    <div *ngIf="(applyEventForm.controls.address?.touched || applyEventForm.controls.address?.dirty)
                    && applyEventForm.controls.address?.invalid && applyEventForm.controls.address?.errors?.required"
                      style="color:red">
                      Please enter address
                    </div>
                  </div>

                  <!-- <div class="form-group">
                    <label>Country (optional)</label>
                    <input type="text" class="form-control" placeholder="Country (optional)" />
                  </div> -->

                  <div class="form-group">
                    <label>Postcode</label>
                    <input class="form-control" value="" placeholder="Postcode" formControlName="postcode"
                      oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                    <div
                      *ngIf="(applyEventForm.controls.postcode?.touched || applyEventForm.controls.postcode?.dirty)
                        && applyEventForm.controls.postcode?.invalid && applyEventForm.controls.postcode?.errors?.required"
                      style="color:red">
                      Please enter postcode
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Phone</label>
                    <input class="form-control" value="" placeholder="Phone" formControlName="phone"
                      oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"
                      oninput="this.value = this.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');">
                    <p style="color: red"
                      *ngIf="applyEventForm.controls['phone'].hasError('required') &&applyEventForm.controls['phone'].touched">
                      Please enter contact number
                    </p>
                    <p style="color: red"
                      *ngIf="!applyEventForm.controls['phone'].hasError('required') && applyEventForm.controls['phone'].hasError('pattern')">
                      Please enter valid digit contact number
                    </p>
                    <p style="color: red"
                      *ngIf="!applyEventForm.controls['phone'].hasError('required') && applyEventForm.controls['phone'].hasError('minlength')">
                      Please enter the digits minimum 7 or maximum 15
                    </p>
                    <p style="color: red"
                      *ngIf="!applyEventForm.controls['phone'].hasError('required') && applyEventForm.controls['phone'].hasError('maxlength')">
                      Please enter the digits minimum 7 or maximum 15
                    </p>
                  </div>

                  <div class="form-group">
                    <label>Email address</label>
                    <input class="form-control" value="" placeholder="Email" formControlName="email"
                      oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
                    <div *ngIf="!applyEventForm.controls['email'].valid && applyEventForm.controls['email'].touched">
                      <p style="color: #f90202"
                        *ngIf="applyEventForm.controls['email'].touched && applyEventForm.controls['email'].hasError('required')">
                        Please enter email
                      </p>
                      <div style="color: #f90202" *ngIf="applyEventForm.controls['email'].errors?.pattern">
                        Email must be a valid email address</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label>Upload Portfolio</label>
                    <ngx-dropzone (change)="onSelect($event)"  accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps">
                      <ngx-dropzone-label>Upload Portfolio!</ngx-dropzone-label>

                      <ng-container *ngFor="let f of files">
                        <!-- pdf -->
                        <ng-container *ngIf="mimeType != 'image'">
                          <ngx-dropzone-preview [removable]="true" (removed)="onRemove(f)">
                            <ngx-dropzone-label>
                              <img src="assets/images/pdf.png" style="height: 100px;width:80px">
                            </ngx-dropzone-label>
                          </ngx-dropzone-preview>
                        </ng-container>

                        <!-- image -->
                        <ng-container *ngIf="mimeType == 'image'">
                          <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview"  [removable]="true" (removed)="onRemove(f)"
                            [file]="f">
                            <ngx-dropzone-label></ngx-dropzone-label>
                          </ngx-dropzone-image-preview>
                        </ng-container>
                      </ng-container>

                    </ngx-dropzone>

                  </div>

                  <button type="submit" class="btn btn_theme_filled mt-4">
                    Submit
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>

  <app-footer></app-footer>
</div>