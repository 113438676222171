<div class="main_home_wraper">
  <app-header-cmn></app-header-cmn>

  <section class="pricing_sectn">
    <div class="custom_container">
      <div class="hero_sctn_cntnt">
        <div class="hero_sctn_txt text-center">
          <h2>Keep ahead of the curve, tell better stories and explore the largest visual archive!</h2>
          <p>Choose the right subscription plan for your brand!</p>
          <div class="pricing_wrap">
            <div class="row justify-content-center align-items-center">
              <div class="col-md-4" *ngFor="let list of subscriptionData;let i=index">
                <div [ngClass]=" list?.type == 'monthly' ? 'pricing_card1' : 'pricing_card text-center' ">
                  <div class="plan_cntnt">

                    <div class="pln_nm">
                      <h3>{{list?.title | titlecase}}</h3>
                      <h4>{{list?.type | titlecase}}</h4>
                      <p class="td-width td-tundi">{{list?.description | titlecase}} </p>
                    </div>


                    <div class="plan_tabs">
                      <p class="dwnld_nm">{{list?.sub_description |titlecase}}</p>
                      <div class="clnt_logos">
                        <div class="row" *ngIf="list.type=='custom'">
                          <div class="col-md-4"*ngFor="let client of prestigious_clients">
                            <div class="mb-5">
                              <img loading="lazy" appLazyLoad [src]="env.PRESTIGIOUS_IMAGE +''+client" [alt]="client" class="img-fluid" >
                            </div> 
                          </div>
                          <!-- <div class="col-md-4"*ngFor="let client of prestigious_clients">
                            <div class="mb-5">
                              <img loading="lazy" appLazyLoad [src]="env.PRESTIGIOUS_IMAGE +''+client" [alt]="client" class="img-fluid" >
                            </div> 
                          </div> -->
                        </div>
                      </div>
                      <div class="mnthly_optns grey_form" *ngIf="list.type!='custom'">
                      <div class="form-group">
                        <select class="form-control" (change)="selectSize($event,i)">
                          <option value="small">Small size  (1000X1500)</option>
                          <option value="large">Large size  (5400X8100)</option>

                        </select>
                      </div>
                    </div>
                      <mat-tab-group mat-align-tabs="center" (selectedTabChange)="tabClickForOthers($event)" *ngIf="list?.type =='small' "  animationDuration="0ms">
                        <mat-tab label="{{l?.downloads}}" *ngFor="let l of this.subscriptionDataForSmallPlan[0]?.plan">
                          <div class="plan_price">
                            <p>Discount : {{l?.discount}}</p>
                             <h2>₹{{l?.price}}.00</h2>
                          </div>
                        </mat-tab>
                      </mat-tab-group>
                        <mat-tab-group mat-align-tabs="center" class="monthly_tab_arrow" (selectedTabChange)="tabClickForMonthly($event)" *ngIf="list?.type == 'monthly'" animationDuration="0ms">
                          <mat-tab label="{{l?.downloads}}"  *ngFor="let l of this.plan;">
                            <div class="plan_price">
                              <p>Discount : {{l?.discount}}</p>
                              <h2>₹{{l?.price}}.00</h2>
                            </div>
                          </mat-tab>

                        
                      
                      </mat-tab-group>
                    </div>
                    <div class="mnthly_optns grey_form" *ngIf="list?.type =='monthly'">
                      <div class="form-group">
                        <select class="form-control" (change)="monthlyplan($event)">
                          <option value="Annual, billed monthly">Annual, billed monthly</option>
                          <option value="Annual, billed upfront">Annual, billed upfront</option>
                          <option value="Monthly, no contract">Monthly, no contract</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  
                  <div class="plan_actn">
                    <button (click)="subscriptionPurchaseFunction()" class="btn btn_theme_filled d-block "
                    *ngIf="list?.type =='small'">Purchase now</button>
                    <button  (click)="subscriptionPurchaseFunctionMonthly()" class='btn btn_gry d-block'
                    *ngIf="list?.type =='monthly'">Purchase now</button>
                    <a class="btn btn_theme_filled d-block"
                    *ngIf="list?.type =='custom'">Get in touch</a>
                  </div>
                </div>
              </div>
             

              <div class="col-md-12">
                <div class="pricing_card text-center sngl_prcng_crd">
                  <div class="plan_cntnt">
                    <div class="pln_nm">
                      <h4>Single Image Pricing</h4>
                      <p>
                        Keep ahead of the curve, tell better stories and explore the largest visual
                      </p>
                    </div>
                    <div class="snglimg_prcng">
                      <div class="cart_tabl cntct_form p-0 table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Image Type</th>
                              <th scope="col">Resolution</th>
                              <th scope="col">Dimension (Pixels)</th>
                              <th scope="col">Size (Inches)</th>
                              <th scope="col">Price (Rs.)*</th>
                              <th scope="col">File Format</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let list of pricingdata;">
                              <td *ngIf="list?.image_type?.includes('extra_large')">Extre Large</td>
                              <td *ngIf="!list?.image_type?.includes('extra_large')">{{list?.image_type | titlecase}}</td>
                              <td>{{list?.resolution}}</td>
                              <td>{{list?.size}}</td>
                              <td>{{list?.size_inches}}</td>
                              <td>{{list?.price}}</td>
                              <td>{{list?.format}}</td>
                            </tr>                  
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <app-footer></app-footer>
</div>