<div class="main_home_wraper">

	<!-- <app-header-cmn></app-header-cmn> -->
	<app-db-header></app-db-header>
	<div class="inner_wrap_sectn">
		<section class="image_details">
			<div class="custom_container">
				<div class="search_bar">
					<form class="d-flex srch_form">
						<div class="slct_srch d-flex">
							<div class="srch_drpdwn">
								<div class="dropdown">
									<button class="btn btn-secondary dropdown-toggle slctdrop_btn" type="button"
										id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                        {{phototype == 'none' ? 'All' : phototype == 'image'  ? 'Photos' : phototype == 'vector'  ? 'Vector':''}}
									</button>
									<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
										<a class="dropdown-item" (click)="getPhototype('none')">All</a>
										<a class="dropdown-item" (click)="getPhototype('vector')">Vectors</a>
										<a class="dropdown-item" (click)="getPhototype('image')">Photos</a>
									</div>
								</div>
							</div>
							<div class="srch_input">
								<input class="form-control mr-2 hdr_srch" type="search" placeholder="Search" [(ngModel)]="serachdata" placeholder="Search" aria-label="Search" name="search"
								#search="ngModel"
									aria-label="Search">
							</div>
						</div>
						<div class="srch_bttn">
							<button class="btn btn_theme_filled" type="submit" (click)="applyFilter()">Search</button>
						</div>
					</form>
				</div>
			</div>
		</section>
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<h1>Categories</h1>
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="#">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Categories</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="browse_collections">
			<div class="custom_container">
				<div class="cllctns_wrap">
					<div class="row">
						<div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let image of this.categories_with_images">
							<div class="cllctns_div" [style.cursor]="'pointer'">
								<div class="img_cllctn"  routerLink="/stock-photos/{{image?.category_slug}}">
									<a class="cllctn_gllry" *ngIf="image.category_images.length>2">
										<div class="gllry_img">
											<img [src]="image.category_images[0] ? env.IMAGE_COVER_WATERMARK+image?.category_images[0]?.cover_image?.with_watermark : env.CATEGORY_IMAGE+image?.image"
												class="img-fluid" (error)="onImgError($event)">
										</div>
										<div class="gllry_img">
											<img [src]="image.category_images[0] ? env.IMAGE_COVER_WATERMARK+image?.category_images[1]?.cover_image?.with_watermark : env.CATEGORY_IMAGE+image?.image"
												class="img-fluid" (error)="onImgError($event)">
										</div>
										<div class="gllry_img">
											<img [src]="image.category_images[0] ? env.IMAGE_COVER_WATERMARK+image?.category_images[2]?.cover_image?.with_watermark : env.CATEGORY_IMAGE+image?.image"
												class="img-fluid" (error)="onImgError($event)">
										</div>
									</a>
									<a class="cllctn_gllry" *ngIf="image.category_images.length==2">
										<div class="gllry_img">
											<img [src]="image.category_images[0] ? env.IMAGE_COVER_WATERMARK+image?.category_images[0]?.cover_image?.with_watermark : env.CATEGORY_IMAGE+image?.image"
												class="img-fluid" (error)="onImgError($event)">
										</div>
										<div class="gllry_img">
											<img [src]="image.category_images[0] ? env.IMAGE_COVER_WATERMARK+image?.category_images[1]?.cover_image?.with_watermark : env.CATEGORY_IMAGE+image?.image"
												class="img-fluid" (error)="onImgError($event)">
										</div>
										
									</a>
									<a class="cllctn_gllry" *ngIf="image.category_images.length==1">
										<div class="gllry_img">
											<img [src]="image.category_images[0] ? env.IMAGE_COVER_WATERMARK+image?.category_images[0]?.cover_image?.with_watermark : env.CATEGORY_IMAGE+image?.image"
												class="img-fluid" (error)="onImgError($event)">
										</div>
										
									</a>
									<a class="cllctn_gllry" *ngIf="image.category_images.length==0">
										<div class="gllry_img">
											<img [src]="this.env.DEFAULT_IMAGE"
												class="img-fluid">
										</div>
										
									</a>
								</div>
								<div class="cllctn_hdng">
									<a>{{image?.category_name}}</a>
								</div>
								<div class="cllctn_tags">
									<ul>
										<li *ngFor="let tags of getCategoryTags(image)">
											<a (click)="searchvalue(tags)" value='tags'>{{tags}}</a>
										</li>
										
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>


	<app-footer></app-footer>
</div>