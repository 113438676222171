<footer class="footer_section">
	<div class="top_footer">
		<div class="custom_container">
			<div class="row">
				<div class="col-lg-4 col-md-6">
					<aside class="side_1 pr-4 mb-4">
						<img src="assets/images/nuStock_b.png" class="img-fluid footr_logo" alt="Logo">
						<p>Get the largest collection of Indian images!</p>
						<div class="social_links mt-4 ">
							<ul type="none" class="socl_links_txt">
								<li><a class="fb_icn" href="https://www.facebook.com/nustockimages" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
								<li><a href="https://www.instagram.com/nustockimages" target="_blank" class="drbl_icn" ><i class="fab fa-instagram"></i></a></li>
								<li><a href="https://www.linkedin.com/company/nustockimages/" target="_blank" class="twtr_icn"><i class="fab fa-linkedin-in"></i></a></li>
								<li><a href="https://twitter.com/nustockimages" target="_blank" class="pntrst_icn"><i class="fab fa-twitter"></i></a></li>
								<li><a href="https://www.youtube.com/channel/UCh1yWeYK2vbdiIrCp29JJ6w" target="_blank" class="utube_icn"><i class="fab fa-pinterest"></i></a></li>
							</ul>
						</div>
						
					</aside>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="foot_ttl mb-4">
						<h5>Top Categories</h5>
						<ul type="none" class="service_list" *ngFor="let category of this.topcategory">
							<li><a class="pointer" routerLink="/stock-photos/{{category.category_slug}}" (click)="navigateByCategory(category.category_slug)" >{{category?.category_name}}</a></li>
				
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-md-3">
					<div class="foot_ttl mb-4">
						<h5>Utilities</h5>
						<ul type="none" class="service_list">
							<!-- <li><a class="pointer" routerLink="/blogs">Blog</a></li> -->
							<li><a class="pointer"   routerLink="/new-stock-images">Explore</a></li>
							<li><a class="pointer" routerLink="/stock-photos/categories">Categories</a></li>
							<li><a class="pointer"  routerLink="/user/view-profile" >My account</a></li>
							<!-- <li><a class="pointer" (click)="click('')">Contribute</a></li> -->
						</ul>
					</div>
				</div>
				<div class="col-lg-2 col-md-6">
					<div class="foot_ttl mb-4">
						<h5>Pages</h5>
						<ul type="none" class="service_list">
							<li><a  routerLink="/contact-us" class="pointer" >Contact</a></li>
							<li><a  routerLink="/about-us" class="pointer" >About Us</a></li>
							<!-- <li><a  routerLink="/pricing" class="pointer" >Pricing</a></li> -->
							<!-- <li><a  routerLink="/howItWorks">How it works</a></li> -->
							<li><a  routerLink="/faq" class="pointer">FAQ</a></li>
						</ul>
					</div>
				</div>

				<div class="col-lg-2 col-md-6">
					<div class="foot_ttl">
						<h5>Legal</h5>
						<ul type="none" class="service_list">
							<li><a class="pointer"   routerLink="/terms-conditions" >Terms and condition</a></li>
							<li><a class="pointer"  routerLink="/privacy-policy"  >Privacy Policy</a></li>
							<li><a routerLink="/terms-of-use" >Terms of Use</a></li>
						</ul>
					</div>
				</div>
				
			</div>
		</div>
	</div>
	<div class="bottom-footer">
		<div class="custom_container">
			<div class="btmftr_wrp d-flex justify-content-center">
				<div class="btmftr_txt">
					<p class="mb-0">Copyright © {{year}} Nu Stock Images. All Rights Reserved.</p>
				</div>
			</div>
		</div>
		
	</div>
</footer>



