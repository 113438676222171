import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeRoutingModule } from './home-routing.module';
import { IndexComponent } from './index/index.component';
import { CarouselModule } from "ngx-owl-carousel-o";
import { MatTabsModule } from "@angular/material/tabs";
import { MatIconModule } from "@angular/material/icon";
import { MatExpansionModule } from "@angular/material/expansion";
import { NgxDropzoneModule } from "ngx-dropzone";
import { MatSelectModule } from "@angular/material/select";
import { MatMenuModule } from "@angular/material/menu";
import { MatChipsModule } from "@angular/material/chips";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRadioModule } from "@angular/material/radio";
import { HeaderModule } from '../common/header/header.module';
import { FooterModule } from '../common/footer/footer.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { HeaderCmnComponent } from '../common/header-cmn/header-cmn.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsnconditionsComponent } from './termsnconditions/termsnconditions.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { FaqComponent } from './faq/faq.component';
// import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LazyimgModule } from '../directives/lazyImgLoad/lazyimg.module';

import { CheckoutComponent } from './checkout/checkout.component';
import { PhotoeventsListComponent } from './photoevents-list/photoevents-list.component';
import { BuddingModelsComponent } from '../user/budding-models/budding-models.component';
// import { AgmCoreModule } from '@agm/core';
import { EventFormComponent } from './event-form/event-form.component';
import { CategoriesComponent } from './categories/categories.component';
import { EmailVerifiedComponent } from './email-verified/email-verified.component';
import {NgxPaginationModule} from 'ngx-pagination'; 
import { PricingComponent } from './pricing/pricing.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ImagePreloadModule } from '../directives/imagePreload/image-preload.module';
import { ConfirmModule } from '../dialogs/confirm/confirm.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { DbHeaderComponent } from '../common/db-header/db-header.component';
import { ImageDetailsComponent } from './image-details/image-details.component';
import { ExploreComponent } from './explore/explore.component';
import { PaymentcancelComponent } from './paymentcancel/paymentcancel.component';
import { SuccessfulComponent } from './successful/successful.component';
import { UnsuccessfulComponent } from './unsuccessful/unsuccessful.component';
import { ResetComponent } from './reset/reset.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { ExploreCategoryComponent } from './explore-category/explore-category.component';
import { ExploreImagesComponent } from './explore-images/explore-images.component';
import { ExploreShootComponent } from './explore-shoot/explore-shoot.component';
import { ExploreModelComponent } from './explore-model/explore-model.component';
import { ExploreSimilarComponent } from './explore-similar/explore-similar.component';
import { ExploreSimilarTagsComponent } from './explore-similar-tags/explore-similar-tags.component';
import { ExploreSearchComponent } from './explore-search/explore-search.component';

@NgModule({
  declarations: [
    IndexComponent,
    AboutUsComponent,
    ContactUsComponent,
    PrivacyPolicyComponent,
    TermsnconditionsComponent,
    TermsOfServiceComponent,
    FaqComponent,
    ExploreSearchComponent,
    // HowItWorksComponent,
    HeaderCmnComponent,
    PricingComponent,
    DbHeaderComponent,
    CheckoutComponent,
    PhotoeventsListComponent,
    BuddingModelsComponent,
    EventFormComponent,
    CategoriesComponent,
    EmailVerifiedComponent,
    ImageDetailsComponent,
    ExploreComponent,
    PaymentcancelComponent,
    UnsuccessfulComponent,
    SuccessfulComponent,
    ResetComponent,
    ExploreCategoryComponent,
    ExploreImagesComponent,
    ExploreShootComponent,
    ExploreModelComponent,
    ExploreSimilarComponent,
    ExploreSimilarTagsComponent,
  ],
  imports: [
    NgxCaptchaModule,
    NgxPaginationModule,
    HeaderModule,
    CommonModule,
    HomeRoutingModule,
    CarouselModule,
    MatTabsModule,
    MatIconModule,
    MatExpansionModule,
    NgxDropzoneModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    MatSnackBarModule,
    FooterModule,
    LazyimgModule,
    MatSlideToggleModule,
    NgxPaginationModule,
    CommonModule,
    HeaderModule,
    CommonModule,
    HomeRoutingModule,
    CarouselModule,
    MatTabsModule,
    MatIconModule,
    MatExpansionModule,
    NgxDropzoneModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderModule,
    MatSnackBarModule,
    FooterModule,
    ImagePreloadModule,
    ConfirmModule,
    MatProgressSpinnerModule,
    NgxMatSelectSearchModule
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyBO4h0M4llURpaaEfOsGRCbsXI_0Sc-2jU'
    // })


  ]
})
export class HomeModule { }
