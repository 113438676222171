<div class="main_home_wraper">
	
	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<!-- <h1>{{this.pagetitile}}</h1> -->
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
							    <li class="breadcrumb-item"><a href="#">Home</a></li>
							    <li class="breadcrumb-item active" aria-current="page">{{termAndConditiondata[0]?.heading}}</li>
							  </ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="contact_us privacy_policy">
			<div class="custom_container" *ngFor="let x of termAndConditiondata">
				<div class="policy_wrap">
					<h2 [innerHtml]="x.heading"></h2>
					<p [innerHtml]="x.description"> </p>
					
				</div>
			</div>
		</section>	
	</div>
	

	<app-footer></app-footer>
</div>