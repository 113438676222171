<div class="main_home_wraper">

	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<h1>{{content?.page_title}}</h1>
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
								<ol class="breadcrumb">
									<li class="breadcrumb-item"><a href="#">Home</a></li>
									<li class="breadcrumb-item active" aria-current="page">Contact</li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="contact_us">
			<div class="custom_container">
				<div class="row">
					<div class="col-md-7 mb-4">
						<div class="cntct_form_wrp">
							<div class="sctn_hdng">
								<p>{{content?.main_heading}}</p>
								<h2>{{content?.description}}</h2>
							</div>
							<div class="cntct_form grey_form">
								<h3>Send a message​</h3>
								<form [formGroup]="contactUs">
									<div class="form-group">
										<input type="text" class="form-control" placeholder="Full Name"
											formControlName="name"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
										<div *ngIf="contactUs.controls['name'].touched" class="mt-2">
											<p class="danger err-msg "
												*ngIf="contactUs.controls['name'].hasError('required')  && !contactUs.controls['name'].valid ">
												Please enter name*</p>


										</div>
									</div>
									<div class="form-group">
										<input type="email" class="form-control" placeholder="Email"
											formControlName="email">
										<div *ngIf="contactUs.controls['email'].touched" class="mt-2">
											<p class="danger err-msg "
												*ngIf="contactUs.controls['email'].hasError('required')  && !contactUs.controls['email'].valid ">
												Please enter Email*</p>
											<p *ngIf="contactUs.controls.email.errors?.pattern" class="danger err-msg">
												Email is not valid*
											</p>

										</div>
									</div>
									<div class="form-group">
										<input type="text" class="form-control" placeholder="Subject"
											formControlName="subject"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
										<div *ngIf="contactUs.controls['subject'].touched" class="mt-2">
											<p class="danger err-msg "
												*ngIf="contactUs.controls['subject'].hasError('required')  && !contactUs.controls['subject'].valid ">
												Please enter subject*</p>


										</div>
									</div>
									<div class="form-group">
										<textarea class="form-control" rows="5" placeholder="Enter message"
											formControlName="message"
											oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"></textarea>
										<div *ngIf="contactUs.controls['message'].touched" class="mt-2">
											<p class="danger err-msg "
												*ngIf="contactUs.controls['message'].hasError('required')  && !contactUs.controls['message'].valid ">
												Please enter message*</p>



										</div>
									</div>
									<button type="submit" class="btn btn_theme_filled"
										(click)="submit()">Submit</button>
								</form>
							</div>
						</div>
					</div>
					<div class="col-md-5 mb-4">
						<div class="cntct_info_wrp">
							<div class="cntct_info_div d-flex justify-content-between align-items-start">
								<div class="cntct_cht d-flex">
									<div class="cntct_icn">
										<img src="assets/images/wtsapp.svg" class="img-fluid">
									</div>
									<div class="cntct_txt">
										<h5>{{content?.chat_heading}}</h5>
										<p>{{content?.chat_description}}</p>
									</div>
								</div>
								<a class="btn btn_theme_filled" href="https://wa.me/9718149868" target="_blank" style="white-space: nowrap;">Chat Now</a>
							</div>
							<div class="cntct_info_div d-flex ">
								<div class="cntct_icn">
									<i class="fas fa-phone-alt"></i>
								</div>
								<div class="cntct_txt">
									<h5>Call Us</h5>
									<p>{{content?.call_us_timimg}}</p>
									<p class="info_bld">{{content?.call_us_number}}</p>
								</div>
							</div>
							<div class="cntct_info_div d-flex ">
								<div class="cntct_icn">
									<i class="fas fa-envelope"></i>
								</div>
								<div class="cntct_txt">
									<h5>Email Us</h5>
									<!-- <p>Write an email to us regarding your query.</p> -->
									<p class="info_bld">{{content?.email_us}}</p>
								</div>
							</div>

							<div class="cntct_info_div d-flex ">
								<div class="cntct_icn">
									<i class="fas fa-map-marker-alt"></i>
								</div>
								<div class="cntct_txt">
									<h5>Address</h5>
									<!-- <p>Write an email to us regarding your query.</p> -->
									<p class="info_bld">{{content?.address}}</p>
								</div>
							</div>
							<div class="cntct_info_div d-flex ">
								<div class="cntct_icn">
									<i class="fa fa-camera"></i>
								</div>
								<div class="cntct_txt">
									<h5>Custom shoots</h5>
									<button class="btn hdr_btn_outline" style="color: black !important;" data-toggle="modal" data-target="#exampleModal" ><i
											class="fa fa-camera" (click)="handleReset()"></i> Custom Shoots</button>

											
								</div>
								<!-- <form [formGroup]="aFormGroup">
									<ngx-recaptcha2 #captchaElem
									[siteKey]="'6LcCVkAmAAAAAOJ7qnSmsl8foFywvEVoT_fJLjB5'"
									
									formControlName="recaptcha">
									</ngx-recaptcha2>
								  </form> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>


	<app-footer></app-footer>
</div>





<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
	aria-hidden="true">
	<div class="modal-dialog" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Custom Shoots</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div [formGroup]="shoot" (ngSubmit)="submitShoot()">
				<div class="modal-body grey_form">
					<div class="form-group">
						<label for="">Full Name</label>
						<input type="text" class="form-control" formControlName="fullname" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value">
						<div *ngIf="shoot.controls['fullname'].touched" class="mt-2">
							<p class="danger err-msg "
								*ngIf="shoot.controls['fullname'].hasError('required')  && !shoot.controls['fullname'].valid ">
								Please enter fullname*</p>
	
	
						</div>
					</div>
					
	
					<div class="form-group">
						<label for="">Email Address</label>
						<input type="text" class="form-control" formControlName="email" >
						<div *ngIf="shoot.controls['email'].touched" class="mt-2">
							<p class="danger err-msg "
								*ngIf="shoot.controls['email'].hasError('required')  && !shoot.controls['email'].valid ">
								Please enter Email*</p>
							<p *ngIf="shoot.controls.email.errors?.pattern" class="danger err-msg">
								Email is not valid*
							</p>

						</div>
					</div>
	
					<div class="form-group">
						<label for="">Phone Number</label>
						<input type="text" class="form-control" formControlName="phone"  maxlength="10" (keypress)="numberOnly($event)">
						<div *ngIf="shoot.controls['phone'].touched" class="mt-2">
							<p class="danger err-msg"
                                                            *ngIf="shoot.controls.phone.errors?.required   && (shoot.controls['phone'].dirty || shoot.controls['phone'].touched)">
                                                            Phone number is required*</p>
                                                        <p class="danger err-msg"
                                                            *ngIf="shoot.controls.phone.errors?.pattern   && (shoot.controls['phone'].dirty || shoot.controls['phone'].touched)">
                                                            Invalid Phone number*</p>
                                                        <p class="danger err-msg"
                                                            *ngIf="shoot.controls.phone.errors?.minlength   && (shoot.controls['phone'].dirty || shoot.controls['phone'].touched)">
                                                            Please enter min 10 numbers*</p>

						</div>
					</div>
	
					<div class="form-group">
						<label for="">Message</label>
						<textarea name="" id="" rows="2" class="form-control" formControlName="message" oninput="this.value = !this.value.trim() ? this.value.trim() : this.value"></textarea>
						<div *ngIf="shoot.controls['message'].touched" class="mt-2">
							<p class="danger err-msg "
								*ngIf="shoot.controls['message'].hasError('required')  && !shoot.controls['message'].valid ">
								Please enter message*</p>



						</div>
					</div>
					<div class="form-group">
						<ngx-recaptcha2 #captchaElem
						[siteKey]="'6Le2mkwmAAAAANXdcHz0ygRDllG5_2WhRi8MWZg1'"
						(reset)="handleReset()"
						formControlName="recaptcha">
						</ngx-recaptcha2>
					</div>
					
	
				</div>
				<div class="modal-footer">
					<!-- <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button> -->
					<button type="button" class="btn btn_theme_filled" (click)="submitShoot()">Submit</button>
				</div>
			</div>
			
		</div>
	</div>
</div>