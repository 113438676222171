<div class="main_home_wraper">
    <app-db-header></app-db-header>

    <div class="inner_wrap_sectn">
        <section class="image_details">
            <div class="custom_container">
                <div class="search_bar">
                    <form class="d-flex srch_form">
                        <div class="slct_srch d-flex">
                            <div class="srch_drpdwn">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle slctdrop_btn" type="button"
                                        aria-expanded="false" id="dropdownMenuButton" data-toggle="dropdown"
                                        aria-expanded="false">
                                        {{phototype == 'none' ? 'All ' : phototype == 'image' ? 'Photos' : phototype ==
                                        'vector' ? 'Vector':''}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <!-- <a class="dropdown-item" (click)="getPhototype('none')">All</a> -->
                                        <a class="dropdown-item" (click)="getPhototype('vector')">Vectors</a>
                                        <a class="dropdown-item" (click)="getPhototype('image')">Photos</a>
                                    </div>
                                </div>
                            </div>
                            <div class="srch_input">
                                <input class="form-control mr-2 hdr_srch" type="search" [(ngModel)]="serachdata"
                                    (keyup.enter)="applyFilter()" placeholder="Search" aria-label="Search"
                                    aria-label="Search" #search="ngModel" name="search" />
                            </div>
                        </div>
                        <div class="srch_bttn">
                            <button class="btn btn_theme_filled" type="submit" (click)="applyFilter()">
                                Search
                            </button>
                        </div>
                    </form>
                </div>

                <div class="image_optns d-flex justify-content-between align-items-md-center w-100">
                    <div class="optn_btns">
                        <button *ngIf="imageDetail?.is_wishlist === null" (click)="saveUnsaveImage()"
                            class="btn btn_optn">
                            <span><i class="far fa-bookmark mr-2"></i></span>
                            <span class="d-none d-sm-inline">Save</span>
                        </button>
                        <button *ngIf="imageDetail?.is_wishlist !== null" (click)="saveUnsaveImage()"
                            class="btn btn_optn">
                            <span><i class="fas fa-bookmark mr-2"></i></span>
                            <span class="d-none d-sm-inline">Save</span>
                        </button>



                        <a class="btn btn_optn" data-toggle="modal" data-target="#shareServices">
                            <span><i class="fas fa-share mr-2"></i></span>
                            <span class="d-none d-sm-inline">Share</span>
                        </a>
                        <a class="btn btn_optn"
                            (click)="downloadtryImage(this.imageDetail?.cover_image?.with_watermark,this.imageDetail?.image_unique_id)">
                            <span><i class="fas fa-angle-double-down mr-2"></i></span>
                            <span class="d-none d-sm-inline">Try</span>
                        </a>
                        <!-- <a class="btn btn_optn" download (click)="downloadImage(this.imageDetail?.cover_image?.with_watermark)">
                            <span><i class="fas fa-arrow-down mr-2"></i></span>
                            <span>Download</span>
                        </a> -->
                        <a class="btn btn_optn" (click)="downloadImage(this.imageDetail?._id)">
                            <span><i class="fas fa-arrow-down mr-2"></i></span>
                            <span class="d-none d-sm-inline">Download</span>
                        </a>
                        <a class="btn btn_optn" data-toggle="modal" data-target="#exclsv_rts">


                            <span><i class="fas fa-copyright mr-2"></i></span>
                            <span class="d-none d-sm-inline">Buy Exclusive Rights</span>
                        </a>
                    </div>
                    <div class="cart_btn">
                        <a *ngIf="this.imageDetail?.type!='vector'" (click)="addToCart()" class="btn btn_theme_filled">
                            <span><i class="fas fa-shopping-cart mr-2"></i></span>
                            <span class="d-none d-sm-inline">Add to Cart</span>
                        </a>
                        <a *ngIf="this.imageDetail?.type=='vector'" (click)="addToCartForVector(this.imageID)"
                            class="btn btn_theme_filled">
                            <span><i class="fas fa-shopping-cart mr-2"></i></span>
                            <span class="d-none d-sm-inline">Add to Cart</span>
                        </a>
                    </div>
                </div>
                <div class="imagedtl_grid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="main_image" style="position: relative;" *ngIf="this.imageDetail?.cover_image?.with_watermark">
                                <img loading="lazy" appLazyLoad [style.width.px]="1500"
                                    [src]="env.IMAGE_COVER_WATERMARK+this.imageDetail?.cover_image?.with_watermark"
                                    class="img-fluid" />
                                <div class="dropdown download-img"  *ngIf="subscribedPlans.length>0" >
                                    <button class="btn btn-default dropdown-toggle btn btn_theme_filled " type="button" data-toggle="dropdown">
                                        <i class="fas fa-download mr-2"></i>Subscription
                                    <span class="caret"></span></button>
                                    <ul class="dropdown-menu" >
                                      <li *ngFor="let imgSize of imagesizes" (click)="downloadSubscribedImage(imgSize._id)" >{{imgSize?.image_type | uppercase}}</li>
                                    </ul>
                                  </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4">
                            <div class="main_image_shoot" *ngIf="collectionImages?.length !== 0">
                                <div class="sngl_img" *ngFor="let collecImage of collectionImages">
                                    <img [src]="env.IMAGE_COVER+collecImage.cover_image?.without_watermark"
                                        [alt]="env.IMAGE_COVER+collecImage.cover_image.with_watermark"
                                        class="img-fluid" />
                                </div>

                                <div class="sngl_img">
                                    <div class="view_all_div" *ngIf="collectionImages?.length > 6">
                                        <p (click)="viewCollectionImageButton()">View all</p>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="image_info">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="img_idttl">
                                <div class="img_id d-flex justify-content-between">
                                    <p class="copy-icon1">
                                        Photo ID: {{this.imageDetail?.image_unique_id}}
                                        <span><i class="far fa-clone"
                                                (click)="copyText1(this.imageDetail?.image_unique_id)"></i> <span
                                                class="copy-text1">Copied</span></span>
                                    </p>
                                    <!-- <p *ngIf="this.imageDetail?.collection_details?.shoot_id" class="copy-icon">
                                        Shoot ID: {{this.imageDetail?.collection_details?.shoot_id ?
                                        this.imageDetail?.collection_details?.shoot_id: 'N/A'}}
                                        <span><i class="far fa-clone" (click)="copyText(this.imageDetail?.collection_details?.shoot_id)"></i> <span class="copy-text">Copied</span></span>
                                    </p> -->
                                </div>
                                <div class="img_ttl mb-4">
                                    <h6>
                                        {{this.imageDetail?.description}}
                                    </h6>
                                    <p>

                                    </p>
                                </div>
                                <div class="photo_formats" *ngIf="this.imageDetail?.type=='vector'">
                                    <h6 class="mb-3 Vctr_head">Pricing & Format</h6>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="img_frmt_div vctr_prcng ">
                                                <p><strong>Image Type:</strong> <span class="ml-3"> Vector</span></p>
                                                <p><strong>File Format:</strong> <span class="ml-3"> EPS </span></p>
                                                <p><strong>Price (Rs.)*:</strong> <span class="ml-3">
                                                        {{this.price?.price}}</span></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="rqst_frmt">
                                    <p>
                                        Want Image in another Format?
                                        <a href="#" data-toggle="modal" data-target="#rqst_frmt">Click here!</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <!-- <div class="img_cntrbtr">
                                <h6 class="mb-3">Image Contributer</h6>
                                <div class="img_usr_div d-flex align-items-center">
                                    <div class="usr_img mr-2">
                                        <img [src]="this.env.USER_PROFILE+imageDetail?.collection_details?.user_details?.profile_image"
                                            class="img-fluid" (error)="onImgError($event)" />
                                    </div>
                                    <div class="usr_nm">
                                        <span>By {{this.imageDetail?.collection_details?.user_details?.first_name ?
                                            this.imageDetail?.collection_details?.user_details?.first_name +' '
                                            +this.imageDetail?.collection_details?.user_details?.last_name:
                                            'N/A'}}</span>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>

                    <div class="rqst_frmt">
                        <p>
                            If you are unable to find what you are looking for,
                            please
                            <a href="#" routerLink="/contact-us">Contact Us.</a>
                        </p>
                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="latest_uploads similar_imgs" *ngIf="this.similarImages.length !=0">
            <div class="custom_container">
                <div class="sctn_hdng d-flex justify-content-between align-items-center">
                    <h4>Similar Images</h4>
                    <a class="btn btn_optn btn_sm"  routerLink="/similarImages/{{this.tags}}">View all</a>
                </div>
                <div class="ltst_uplds_wrap">
                    <div class="colm_grid">
                        <ul class="colm_grid_list ">
                            <li class="colm_grid_item" *ngFor="let image of this.similarImages | slice:0:5">
                                <a>
                                    <div class="colm_grid_div">
                                        <div class="colm_grid_img">
                                            <a class="gllry_img">
                                                <img loading="lazy" appLazyLoad [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    class="img-fluid"
                                                    [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    [style.cursor]="'pointer'" (click)="navigate(image?.image_unique_id)" />
                                            </a>
                                        </div>
                                        <div class="inner-content">
                                            <div class="inner-content-body">
                                                <div class="img_badge">
                                                    <ul class="d-flex justify-content-end">
                                                        <li class="set_vctr" *ngIf="image?.type=='vector'">
                                                            {{image?.type}}</li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="!this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save" (click)="addToWishlist(image._id)"><i
                                                                    class="far fa-bookmark "></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save" (click)="addToWishlist(image._id)"><i
                                                                    class="fas fa-bookmark"></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2">
                                                            <span
                                                                data-title="Similar Images" routerLink="/similarImages/{{image.tags}}"  ><mat-icon>content_copy</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type!='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#size">add_shopping_cart</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type=='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-btm overlay_div">
                                            <div class="img_actn d-flex justify-content-between align-items-center">

                                                <span class="font_16">{{image?.image_unique_id}}</span>

                                                <div class="font_16">
                                                    <span title="Try"
                                                        (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                            class="fa fa-arrow-down"></i></span>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section> -->

        <!-- <section class="all_categories">
            <div class="custom_container">
                <div class="categories_crsl">
                    <owl-carousel-o [options]="catg_crsl">
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg1.jpg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Food</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg2.jpeg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Summer</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg3.jpeg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Home & Interiors</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg4.jpg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>City</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg5.jpeg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Cars</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg6.jpeg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Woman</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg7.jpeg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Nature & Travel</span>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="catg_card">
                                <div class="catg_img">
                                    <img
                                        src="assets/images/catg8.jpeg"
                                        class="img-fluid"
                                    />
                                </div>
                                <div class="catg_cntnt">
                                    <span>Lifestyle & Fashion</span>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </section> -->

        <section class="latest_uploads similar_imgs">
            <div class="custom_container">
                <div class="sctn_hdng d-flex justify-content-between align-items-center">
                    <h4>Similar Images</h4>
                    <a class="btn btn_optn btn_sm" routerLink="/similarImages/{{this.tags}}">View all</a>
                </div>
                <!-- | slice:0:3 -->
                <div class="ltst_uplds_wrap">
                    <div class="colm_grid categories_crsl similar_imgcrsl">
                        <owl-carousel-o [options]="simimg_crsl">
                            <ng-template carouselSlide class="colm_grid_list" *ngFor="let image of this.similarImages ">
                                <div class="owl-item-content">
                                    <div class="colm_grid_item">
                                        <a>
                                            <div class="colm_grid_div">
                                                <div class="colm_grid_img">
                                                    <a class="gllry_img">
                                                        <img loading="lazy" appLazyLoad
                                                            [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            class="img-fluid"
                                                            [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            [style.cursor]="'pointer'"
                                                            (click)="navigate(image?.image_unique_id)" />
                                                    </a>
                                                </div>
                                                <div class="inner-content">
                                                    <div class="inner-content-body">
                                                        <div class="img_badge">
                                                            <ul class="d-flex justify-content-end">
                                                                <li class="set_vctr" *ngIf="image?.type=='vector'">
                                                                    {{image?.type}}</li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="!this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="far fa-bookmark "></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="fas fa-bookmark"></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2">
                                                                    <span data-title="Similar Images"
                                                                        routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type!='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                            data-toggle="modal"
                                                                            data-target="#size">add_shopping_cart</mat-icon></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type=='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="custom-btm overlay_div">
                                                    <div
                                                        class="img_actn d-flex justify-content-between align-items-center">

                                                        <span class="font_16">{{image?.image_unique_id}}</span>

                                                        <div class="font_16">
                                                            <span title="Try"
                                                                (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                                    class="fa fa-arrow-down"></i></span>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </ng-template>
                        </owl-carousel-o>

                    </div>
                </div>
            </div>
        </section>

        <!-- <section class="latest_uploads similar_imgs" *ngIf="this.imagesByUser.length !=0">
            <div class="custom_container">
                <div class="sctn_hdng d-flex justify-content-between align-items-center">
                    <h4>Images from the same artist</h4>
                    <a class="btn btn_optn btn_sm" (click)="viewall(this.imageUserId,'sameArtist')">View all</a>
                </div>
                <div class="ltst_uplds_wrap">
                    <div class="colm_grid">
                        <ul class="colm_grid_list">
                            <li class="colm_grid_item" *ngFor="let image of this.imagesByUser | slice:0:3">
                                <a>
                                    <div class="colm_grid_div">
                                        <div class="colm_grid_img">
                                            <a class="gllry_img">
                                                <img [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    class="img-fluid"
                                                    [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark" [style.cursor]="'pointer'"
                                                    (click)="navigate(image?._id)"/>
                                            </a>
                                        </div>
                                         <div class="" >
                                        </div> 
                                        <div class="inner-content">
                                            <div class="inner-content-body">
                                                <div class="img_badge">
                                                    <ul class="d-flex justify-content-end">
                                                        <li class="set_vctr" *ngIf="image?.type=='vector'">{{image?.type}}</li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="!this.wishlistdata.includes(image._id)">
                                                            <span title="Collect" (click)="addToWishlist(image._id)"><i
                                                                    class="far fa-bookmark "></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="this.wishlistdata.includes(image._id)">
                                                            <span title="Collect" (click)="addToWishlist(image._id)"><i
                                                                    class="fas fa-bookmark"></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2">
                                                            <span
                                                                title="Similar"><mat-icon>content_copy</mat-icon></span>
                                                        </li>
                                                         <li class="img_dwnld ml-2">
                                                            <span title="Add to Cart"
                                                                (click)="selectPic(image._id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#size">add_shopping_cart</mat-icon></span>
                                                        </li> 
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type!='vector'">
                                                            <span title="Add to Cart"
                                                                (click)="selectPic(image._id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#size">add_shopping_cart</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"  *ngIf="image?.type=='vector'">
                                                            <span title="Add to Cart"
                                                            (click)="addToCartForVector(image._id)"><mat-icon
                                                                    >add_shopping_cart</mat-icon></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-btm overlay_div">
                                            <div class="img_actn d-flex justify-content-between align-items-center">
                                                 <div class="img_usr_div d-flex align-items-center"> -->
        <!-- <div class="usr_img mr-2">
                                                        <img loading="lazy" appLazyLoad
                                                            [src]="this.env.USER_PROFILE+image?.collection_data?.user_details?.profile_image"
                                                            class="img-fluid" (error)="onImgError($event)">
                                                    </div>
                                                    <div class="usr_nm">
                                                        <span>By {{image?.collection_data?.user_details?.first_name ?
                                                            image?.collection_data?.user_details?.first_name +' '
                                                            +image?.collection_data?.user_details?.last_name:
                                                            'N/A'}}</span>
                                                    </div> -->
        <!-- </div> -->
        <!-- <a class="btn btn_optn bg-white"
                                                    (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)">
                                                    <span><i class="fas fa-angle-double-down mr-2"></i></span>
                                                    <span>Try</span>
                                                </a> 

                                                <span class="font_16">SM983915</span>

                                                <div class="font_16">
                                                    <span title="Download" (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)"><i class="fa fa-arrow-down"></i></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="latest_uploads similar_imgs" *ngIf="this.categories_with_images?.length !=0 ">
            <div class="custom_container">
                <div class="sctn_hdng d-flex justify-content-between align-items-center">
                    <h4>Same Category Images</h4>
                    <a class="btn btn_optn btn_sm" routerLink="/stock-photos/{{this.category_slug}}">View all</a>
                </div>

                <div class="ltst_uplds_wrap">
                    <div class="colm_grid categories_crsl similar_imgcrsl">
                        <owl-carousel-o [options]="imgcatg_crsl">
                            <ng-template carouselSlide class="colm_grid_list"
                                *ngFor="let image of categories_with_images  | slice:0:8">
                                <div class="owl-item-content">
                                    <div class="colm_grid_item">
                                        <a>
                                            <div class="colm_grid_div colm_grid_div_two">
                                                <div class="colm_grid_img colm_grid_img_two">
                                                    <a class="gllry_img">
                                                        <img loading="lazy" appLazyLoad
                                                            [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            class="img-fluid"
                                                            [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            [style.cursor]="'pointer'"
                                                            (click)="navigate(image?.image_unique_id)" />
                                                    </a>
                                                </div>
                                                <div class="inner-content">
                                                    <div class="inner-content-body">
                                                        <div class="img_badge">
                                                            <ul class="d-flex justify-content-end">
                                                                <li class="set_vctr" *ngIf="image?.type=='vector'">
                                                                    {{image?.type}}</li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="!this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="far fa-bookmark "></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="fas fa-bookmark"></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2">
                                                                    <span data-title="Similar Images"
                                                                        routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                                </li>

                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type!='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                            data-toggle="modal"
                                                                            data-target="#size">add_shopping_cart</mat-icon></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type=='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="custom-btm overlay_div">
                                                    <div
                                                        class="img_actn d-flex justify-content-between align-items-center">


                                                        <span class="font_16">{{image?.image_unique_id}}</span>

                                                        <div class="font_16">
                                                            <span title="Try"
                                                                (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                                    class="fa fa-arrow-down"></i></span>
                                                        </div>


                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </ng-template>
                        </owl-carousel-o>

                    </div>
                </div>
                <!-- <div class="ltst_uplds_wrap">
                    <div class="colm_grid">
                        <ul class="colm_grid_list colm_grid_img_loop">
                            <li class=" colm_grid_item" *ngFor="let image of categories_with_images | slice:0:3">
                                <a>
                                    <div class="colm_grid_div colm_grid_div_two">
                                        <div class="colm_grid_img colm_grid_img_two">
                                            <a class="gllry_img">
                                                <img loading="lazy" appLazyLoad
                                                    [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    class="img-fluid"
                                                    [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    [style.cursor]="'pointer'"
                                                    (click)="navigate(image?.image_unique_id)" />
                                            </a>
                                        </div>
                                        <div class="inner-content">
                                            <div class="inner-content-body">
                                                <div class="img_badge">
                                                    <ul class="d-flex justify-content-end">
                                                        <li class="set_vctr" *ngIf="image?.type=='vector'">
                                                            {{image?.type}}</li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="!this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="far fa-bookmark "></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="fas fa-bookmark"></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2">
                                                            <span data-title="Similar Images"
                                                                routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                        </li>

                                                        <li class="img_dwnld ml-2" *ngIf="image?.type!='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#size">add_shopping_cart</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type=='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="custom-btm overlay_div">
                                            <div class="img_actn d-flex justify-content-between align-items-center">


                                                <span class="font_16">{{image?.image_unique_id}}</span>

                                                <div class="font_16">
                                                    <span title="Try"
                                                        (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                            class="fa fa-arrow-down"></i></span>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </section>

        <section class="latest_uploads similar_imgs" *ngIf="this.modalImages.length !=0">
            <div class="custom_container">
                <div class="sctn_hdng d-flex justify-content-between align-items-center">
                    <h4>Same Model Images</h4>
                    <a class="btn btn_optn btn_sm" routerLink="/samemodelimages/{{this.modalname}}">View all</a>
                </div>

                <div class="ltst_uplds_wrap">
                    <div class="colm_grid categories_crsl similar_imgcrsl">
                        <owl-carousel-o [options]="smodl_crsl">
                            <ng-template carouselSlide class="colm_grid_list" *ngFor="let image of this.modalImages ">
                                <div class="owl-item-content">
                                    <div class="colm_grid_item">
                                        <a>
                                            <div class="colm_grid_div">
                                                <div class="colm_grid_img">
                                                    <a class="gllry_img">
                                                        <img loading="lazy" appLazyLoad
                                                            [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            class="img-fluid"
                                                            [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            [style.cursor]="'pointer'"
                                                            (click)="navigate(image?.image_unique_id)" />
                                                    </a>
                                                </div>
                                                <div class="inner-content">
                                                    <div class="inner-content-body">

                                                        <div class="img_badge">



                                                            <ul class="d-flex justify-content-end">
                                                                <li class="mr-2" *ngIf="image?.type=='vector'">
                                                                    {{image?.type}}

                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="!this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="far fa-bookmark "></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="fas fa-bookmark"></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2">
                                                                    <span data-title="Similar Images"
                                                                        routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                                </li>

                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type!='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                            data-toggle="modal"
                                                                            data-target="#size">add_shopping_cart</mat-icon></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type=='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                                </li>
                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="custom-btm overlay_div">

                                                    <div
                                                        class="img_actn d-flex justify-content-between align-items-center">

                                                        <span class="font_16">{{image?.image_unique_id}}</span>

                                                        <div class="font_16">
                                                            <span title="Try"
                                                                (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                                    class="fa fa-arrow-down"></i></span>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </ng-template>
                        </owl-carousel-o>

                    </div>
                </div>
                <!-- <div class="ltst_uplds_wrap">
                    <div class="colm_grid">
                        <ul class="colm_grid_list ">
                            <li class="colm_grid_item" *ngFor="let image of this.modalImages | slice:0:5">
                                <a>
                                    <div class="colm_grid_div">
                                        <div class="colm_grid_img">
                                            <a class="gllry_img">
                                                <img loading="lazy" appLazyLoad
                                                    [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    class="img-fluid"
                                                    [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    [style.cursor]="'pointer'"
                                                    (click)="navigate(image?.image_unique_id)" />
                                            </a>
                                        </div>
                                        <div class="inner-content">
                                            <div class="inner-content-body">

                                                <div class="img_badge">



                                                    <ul class="d-flex justify-content-end">
                                                        <li class="mr-2" *ngIf="image?.type=='vector'"> {{image?.type}}

                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="!this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="far fa-bookmark "></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="fas fa-bookmark"></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2">
                                                            <span data-title="Similar Images"
                                                                routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                        </li>

                                                        <li class="img_dwnld ml-2" *ngIf="image?.type!='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#size">add_shopping_cart</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type=='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                        </li>
                                                    </ul>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="custom-btm overlay_div">

                                            <div class="img_actn d-flex justify-content-between align-items-center">
                                                
                                                <span class="font_16">{{image?.image_unique_id}}</span>

                                                <div class="font_16">
                                                    <span title="Try"
                                                        (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                            class="fa fa-arrow-down"></i></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </section>

        <section class="latest_uploads similar_imgs" *ngIf="this.ShooImages.length !=0 ">
            <div class="custom_container">
                <div class="sctn_hdng d-flex justify-content-between align-items-center">
                    <h4>Entire Shoot</h4>
                    <a class="btn btn_optn btn_sm" routerLink="/entireshoot/{{this.ShootId}}">View all</a>
                </div>

                <div class="ltst_uplds_wrap">
                    <div class="colm_grid categories_crsl similar_imgcrsl">
                        <owl-carousel-o [options]="entrsht_crsl">
                            <ng-template carouselSlide class="colm_grid_list" *ngFor="let image of this.ShooImages">
                                <div class="owl-item-content">
                                    <div class="colm_grid_item">
                                        <a>
                                            <div class="colm_grid_div">
                                                <div class="colm_grid_img">
                                                    <a class="gllry_img">
                                                        <img loading="lazy" appLazyLoad
                                                            [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            class="img-fluid"
                                                            [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                            [style.cursor]="'pointer'"
                                                            (click)="navigate(image?.image_unique_id)" />

                                                    </a>
                                                </div>
                                                <!-- <div class="" >
                                                </div> -->
                                                <div class="inner-content">
                                                    <div class="inner-content-body">
                                                        <!-- <span *ngIf="image?.type=='vector'">{{image?.type}}</span> -->
                                                        <div class="img_badge">



                                                            <ul class="d-flex justify-content-end">
                                                                <li class="mr-2" *ngIf="image?.type=='vector'">
                                                                    {{image?.type}}

                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="!this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="far fa-bookmark "></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="this.wishlistdata.includes(image._id)">
                                                                    <span data-title="Save"
                                                                        (click)="addToWishlist(image._id)"><i
                                                                            class="fas fa-bookmark"></i></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2">
                                                                    <span data-title="Similar Images"
                                                                        routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                                </li>

                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type!='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                            data-toggle="modal"
                                                                            data-target="#size">add_shopping_cart</mat-icon></span>
                                                                </li>
                                                                <li class="img_dwnld ml-2"
                                                                    *ngIf="image?.type=='vector'">
                                                                    <span data-title="Add to Cart"
                                                                        (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                                </li>
                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div class="custom-btm overlay_div">

                                                    <div
                                                        class="img_actn d-flex justify-content-between align-items-center">
                                                        <!-- <div class="img_usr_div d-flex align-items-center">
                                                            <div class="usr_img mr-2">
                                                                <img loading="lazy" appLazyLoad
                                                                    [src]="this.env.USER_PROFILE+image?.collection_data?.user_details?.profile_image"
                                                                    class="img-fluid" (error)="onImgError($event)">
                                                            </div>
                                                            <div class="usr_nm">
                                                                <span>By {{image?.collection_data?.user_details?.first_name ?
                                                                    image?.collection_data?.user_details?.first_name +' ' +
                                                                    image?.collection_data?.user_details?.last_name:
                                                                    'N/A'}}</span>
                                                            </div>
                                                        </div> -->
                                                        <span class="font_16">{{image?.image_unique_id}}</span>
                                                        <!-- <a class="btn btn_optn bg-white"
                                                            (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)">
                                                            <span><i class="fas fa-angle-double-down mr-2"></i></span>
                                                            <span>Try</span>
                                                        </a> -->

                                                        <div class="font_16">
                                                            <span title="Try"
                                                                (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                                    class="fa fa-arrow-down"></i></span>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>
                                        </a>
                                    </div>
                                </div>

                            </ng-template>
                        </owl-carousel-o>

                    </div>
                </div>
                <!-- <div class="ltst_uplds_wrap">
                    <div class="colm_grid">
                        <ul class="colm_grid_list">
                            <li class=" colm_grid_item" *ngFor="let image of this.ShooImages | slice:0:5">
                                <a>
                                    <div class="colm_grid_div">
                                        <div class="colm_grid_img">
                                            <a class="gllry_img">
                                                <img loading="lazy" appLazyLoad
                                                    [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    class="img-fluid"
                                                    [alt]="env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark"
                                                    [style.cursor]="'pointer'"
                                                    (click)="navigate(image?.image_unique_id)" />

                                            </a>
                                        </div>
                                        <div class="inner-content">
                                            <div class="inner-content-body">
                                                <div class="img_badge">



                                                    <ul class="d-flex justify-content-end">
                                                        <li class="mr-2" *ngIf="image?.type=='vector'"> {{image?.type}}

                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="!this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="far fa-bookmark "></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="this.wishlistdata.includes(image._id)">
                                                            <span data-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="fas fa-bookmark"></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2">
                                                            <span data-title="Similar Images"
                                                                routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                        </li>

                                                        <li class="img_dwnld ml-2" *ngIf="image?.type!='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#size">add_shopping_cart</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type=='vector'">
                                                            <span data-title="Add to Cart"
                                                                (click)="addToCartForVector(image?._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                        </li>
                                                    </ul>
                                                </div>


                                            </div>
                                        </div>
                                        <div class="custom-btm overlay_div">

                                            <div class="img_actn d-flex justify-content-between align-items-center">
                                                
                                                <span class="font_16">{{image?.image_unique_id}}</span>
                                                

                                                <div class="font_16">
                                                    <span title="Try"
                                                        (click)="downloadtryImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i
                                                            class="fa fa-arrow-down"></i></span>
                                                </div>

                                            </div>
                                        </div>

                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> -->
            </div>
        </section>

        <!-- <section class="latest_uploads similar_imgs" *ngIf="this.imageDetail?.tags?.length">
            <div class="custom_container">
                <div class="sctn_hdng">
                    <h4>Related keywords</h4>
                </div>
                <div class="ltst_uplds_wrap">
                    <div class="cllctn_tags">
                        <ul>
                            <li *ngFor="let tags of this.imageDetail?.tags">
                                <a (click)="searchByTag('Tags',tags )" [style.cursor]="'pointer'">{{tags}}</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </section> -->

        <section class="latest_uploads similar_imgs" id="top" *ngIf=" this.imageDetail?.type!='vector'">
            <div class="custom_container">
                <div class="sngl_img_info">
                    <div class="pricing_card text-center sngl_prcng_crd">
                        <div class="plan_cntnt">
                            <div class="pln_nm">
                                <h4>Single Image Pricing</h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt.</p>
                            </div>

                            <div class="snglimg_prcng">
                                <div class="cart_tabl cntct_form p-0 table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">
                                                    Image Type
                                                </th>
                                                <th scope="col">
                                                    Resolution
                                                </th>
                                                <th scope="col">
                                                    Dimension (Pixels)
                                                </th>
                                                <th scope="col">
                                                    Size (Inches)
                                                </th>
                                                <th scope="col">
                                                    Price (Rs.)*
                                                </th>
                                                <th scope="col">
                                                    File Format
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let imageSizes of imagesizes">
                                                <td>
                                                    <mat-radio-button
                                                        (change)="selectSingleImage(imageSizes?._id)"></mat-radio-button>
                                                </td>
                                                <td>{{imageSizes?.image_type | uppercase}}</td>
                                                <td>{{imageSizes?.resolution | uppercase}}</td>
                                                <td>{{imageSizes?.size | uppercase}}</td>
                                                <td>{{imageSizes?.size_inches | uppercase}}</td>
                                                <td>{{imageSizes?.price}}</td>
                                                <td>{{imageSizes?.format | uppercase}}</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="mt-4">


                                <a *ngIf="this.imageDetail?.type!='vector'" (click)="addToCart()"
                                    class="btn btn_theme_filled">
                                    <i class="fas fa-shopping-cart mr-2"></i> <span> Add to Cart</span>
                                </a>
                                <a *ngIf="this.imageDetail?.type=='vector'" (click)="addToCartForVector(this.imageID)"
                                    class="btn btn_theme_filled">
                                    <i class="fas fa-shopping-cart mr-2"></i> <span> Add to Cart</span>
                                </a>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="latest_uploads similar_imgs" id="top">
            <div class="custom_container">
                <div class="sngl_img_info">
                    <div class="pricing_card text-center sngl_prcng_crd">
                        <div class="plan_cntnt">
                            <div class="pln_nm">
                                <h4>Save with discounted packs</h4>
                            </div>
                            <div class="pln_nm web-pack">
                                <div style=" font-weight: 500; ">Web size Pack</div>
                                <p style=" font-size: 13px; ">1000 * 1500 at72DPI(JPEG)</p>
                            </div>

                            <div class="snglimg_prcng">
                                <div class="cart_tabl cntct_form p-0 table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col"></th>
                                                <th scope="col">
                                                    Images/Videos
                                                </th>
                                                <th scope="col">
                                                    Discount
                                                </th>
                                                <th scope="col">
                                                    Price (Rs.)
                                                </th>
                                                <th scope="col">
                                                    Per Image/Video (Rs.)
                                                </th>
                                                <th scope="col">
                                                    Download Within
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let subsPlan of subscriptionPlans">
                                                <td>
                                                    <mat-radio-button (change)="selectPlanId(subsPlan)">
                                                    </mat-radio-button>
                                                </td>
                                                <td>{{subsPlan?.quantity}}</td>

                                                <td>{{subsPlan?.item?.discount}} %</td>
                                                <td>{{subsPlan?.item?.amount}}</td>
                                                <td>{{(subsPlan?.item?.amount?? 0) / (subsPlan?.quantity ?? 0)}}</td>
                                                <td>{{subsPlan?.interval}} Days</td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="pln_nm web-pack">
                                <div style=" font-weight: 500">*Tax @18% as applicable will be charged extra.</div>
                                <p style="font-size: 15px;font-weight: 400">** The image type 'WEB' can only be used for
                                    online media and cannot be used in any kind of print media or otherwise.</p>
                            </div>

                            <div class="mt-4">
                                <a class="btn btn_theme_filled" (click)="buySubscription()">
                                    <i class="fas fa-bell mr-2"></i> <span>Subscribe</span>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>


        <!-- <section class="latest_uploads similar_imgs">
            <div class="custom_container">
                <h2>Keep ahead of the curve, tell better stories and explore the largest visual archive!</h2>
                <p>Choose the right subscription plan for your brand!</p>
                <div class="pricing_wrap">
                    <div class="row justify-content-center align-items-center">
                        <div class="col-md-4" *ngFor="let list of subscriptionData;let i=index">
                            <div [ngClass]=" list?.type == 'monthly' ? 'pricing_card1' : 'pricing_card text-center' ">
                                <div class="plan_cntnt">

                                    <div class="pln_nm">
                                        <h3>{{list?.title | titlecase}}</h3>
                                        <h4>{{list?.type | titlecase}}</h4>
                                        <p class="td-width td-tundi">{{list?.description | titlecase}} </p>
                                    </div>


                                    <div class="plan_tabs">
                                        <p class="dwnld_nm">{{list?.sub_description |titlecase}}</p>
                                        <div class="clnt_logos">
                                            <div class="row" *ngIf="list.type=='custom'">
                                                <div class="col-md-4" *ngFor="let client of prestigious_clients">
                                                    <div class="mb-5">
                                                        <img loading="lazy" appLazyLoad
                                                            [src]="env.PRESTIGIOUS_IMAGE +''+client" [alt]="client"
                                                            class="img-fluid">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="mnthly_optns grey_form" *ngIf="list.type!='custom'">
                                            <div class="form-group">
                                                <select class="form-control" (change)="selectSize($event,i)">
                                                    <option value="small">Small size (1000X1500)</option>
                                                    <option value="large">Large size (5400X8100)</option>

                                                </select>
                                            </div>
                                        </div>
                                        <mat-tab-group mat-align-tabs="center"
                                            (selectedTabChange)="tabClickForOthers($event)"
                                            *ngIf="list?.type =='small' || list?.type =='custom'"
                                            animationDuration="0ms">
                                            <mat-tab label="{{l?.downloads}}"
                                                *ngFor="let l of list?.subscription_types[0]?.plan;">
                                                <div class="plan_price">
                                                    <p>Discount : {{l?.discount}}</p>
                                                    <h2>₹{{l?.price}}.00</h2>
                                                </div>
                                            </mat-tab>
                                        </mat-tab-group>
                                        <mat-tab-group mat-align-tabs="center" class="monthly_tab_arrow"
                                            (selectedTabChange)="tabClickForMonthly($event)"
                                            *ngIf="list?.type == 'monthly'" animationDuration="0ms">
                                            <mat-tab label="{{l?.downloads}}" *ngFor="let l of this.plan;">
                                                <div class="plan_price text-center">
                                                    <p>Discount : {{l?.discount}}</p>
                                                    <h2>₹{{l?.price}}.00</h2>
                                                </div>
                                            </mat-tab>

                                            >

                                        </mat-tab-group>
                                    </div>
                                    <div class="mnthly_optns grey_form" *ngIf="list?.type =='monthly'">
                                        <div class="form-group">
                                            <select class="form-control" (change)="monthlyplan($event)">
                                                <option value="Annual, billed monthly">Annual, billed monthly</option>
                                                <option value="Annual, billed upfront">Annual, billed upfront</option>
                                                <option value="Monthly, no contract">Monthly, no contract</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>


                                <div class="plan_actn">
                                    <button (click)="subscriptionPurchaseFunction()"
                                        class="btn btn_theme_filled d-block w-100" *ngIf="list?.type =='small'">Purchase
                                        now</button>
                                    <button (click)="subscriptionPurchaseFunctionMonthly()"
                                        class='btn btn_gry d-block w-100' *ngIf="list?.type =='monthly'">Purchase
                                        now</button>
                                    <a class="btn btn_theme_filled d-block" *ngIf="list?.type =='custom'">Get in
                                        touch</a>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>
        </section> -->


        <section class="latest_uploads ">
            <div class="custom_container">
                <div class="sngl_img_info">
                    <div class="pricing_card sngl_prcng_crd" *ngFor="let x of termAndConditiondata">
                        <h3>Terms and Conditions:</h3>
                        <p [innerHtml]="x.description"></p>
                    </div>
                </div>
            </div>
        </section>





        <section class="latest_uploads similar_imgs">
            <div class="custom_container">
                <div class="sctn_hdng">
                    <h4>Related Keywords</h4>
                </div>
                <div class="row" *ngIf="this.imageDetail?.tags !=0">
                    <div class="col-lg-3 py-2" *ngFor="let tags of this.imageDetail?.tags">
                        <mat-checkbox class="example-margin" [value]="tags" (change)="onCheckboxChange($event)">
                            {{tags | titlecase}}</mat-checkbox>
                        <!-- <div class="ml-2">
                            <ul>
                                <li *ngFor="let keywords of key.keywords">
                                    <mat-checkbox class="example-margin"  [value]="keywords" (change)="onCheckboxChange($event)"> {{keywords}}</mat-checkbox>
                                </li>
                               
                            </ul>
                        </div> -->
                    </div>

                </div>
                <div class="text-center" *ngIf="this.imageDetail?.tags == 0">
                    <h3> No Similar Keywords Found</h3>
                </div>
                <div class="bg_clr" *ngIf="show">
                    <img class="loading" src="assets/images/NUBlack.png" alt="">
                </div>
                <div class="text-start">
                    <button class="btn btn_theme_filled" (click)="viewall()" [disabled]="this.filterKeywords.length==0">
                        <i class="fas fa-search mr-2"></i><span>Search Keywords</span></button>
                </div>
            </div>
        </section>
    </div>

    <!-- --------------------Modal-------------------------- -->

    <!-- <div class="modal rightsd_modl fade" id="slct_price" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	  <div class="modal-dialog">
	    <div class="modal-content">
	      <div class="modal-header">
	        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
	      </div>
	      <div class="modal-body">
	        <div class="cart_side_cntnt">
	        	<h5>Ready to purchase?</h5>
	        	<p>Lorem ipsum dolor sit amet, consectetur adipisicing elit</p>
	        </div>
	      </div>
	      <div class="modal-footer justify-content-center">
	        <button type="button" class="btn btn_theme_filled w-100">Checkout</button>
	      </div>
	    </div>
	  </div>
	</div> -->

    <app-footer></app-footer>
</div>

<!-- ----------------Modal-------------------- -->

<div class="modal fade payout_modl" id="rqst_frmt" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header align-items-center">
                <h5 class="modal-title" id="exampleModalLabel">
                    Request for Another Image Format
                </h5>
                <button type="button" class="btn close_modl" data-dismiss="modal">
                    Close
                </button>
            </div>
            <div class="modal-body">
                <div class="payoutmodl_wrap">
                    <div class="address_form grey_form">
                        <form>
                            <div class="form-group">
                                <label>Select Image Format</label>
                                <select class="form-control" placeholder="Select" [formControl]="imgform">
                                    <option value="png">Png</option>
                                    <option value="tiff">Tiff</option>
                                </select>
                                <p *ngIf="imgform?.errors?.['required'] && imgform.touched" class="text-danger">Required
                                </p>
                            </div>
                            <div class="form-group">
                                <label>Message</label>
                                <textarea class="form-control" rows="5" placeholder="Write your message here"
                                    [formControl]="message"></textarea>
                                <p *ngIf="message?.errors?.['required'] && message.touched" class="text-danger">Required
                                </p>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn_theme_filled" (click)="requestConversion()">
                    Send Request
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade payout_modl" id="exclsv_rts" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header align-items-center">
                <h5 class="modal-title" id="exampleModalLabel">
                    Buy Exclusive Rights
                </h5>
                <button type="button" class="btn close_modl" data-dismiss="modal" #refModal2>
                    Close
                </button>
            </div>
            <div class="modal-body">
                <div class="payoutmodl_wrap">
                    <div class="address_form grey_form">
                        <form>
                            <!-- <div class="form-group">
                                <label>Select duration of Exclusive Rights</label>
                                <select class="form-control" placeholder="Select" (change)="selectedOffer($event)">
                                    <option>select offer</option>
                                    <option *ngFor="let offers of ExclusiveOffers"
                                        [disabled]="offers?.duration !== 'life_time'" value={{offers._id}}>
                                        {{offers.duration}}</option>

                                </select>
                            </div> -->
                            <div class="form-group">
                                <label>Price (Rs.)*</label>
                                <input type="text" class="form-control" placeholder="0.00"
                                    [value]=this.exclusiveAmounnt?.total readonly />
                            </div>
                            <div class="form-group">
                                <label>GST</label>
                                <input type="text" class="form-control" placeholder="0.00"
                                    value={{this.exclusiveAmounnt?.GST}}% readonly />
                            </div>
                            <div class="form-group">
                                <label>Net Price (Rs.)*</label>
                                <input type="text" class="form-control" placeholder="0.00"
                                    [value]=this.exclusiveAmounnt?.netPrice readonly />
                            </div>

                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn_theme_filled" (click)="buyRights()">Buy</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="shareServices" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header modal-hdr-bg">
                <h5 class="modal-title" id="exampleModalLabel">Share Image</h5>
                <button type="" #refModal class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" style="color:#bdbdbd!important;">&times;</span>
                </button>
            </div>
            <div class="modal-body p-4">
                <div>
                    <!-- <h5 class="clr-fnt2 mb-3 mt-2">Category Cover Image</h5>
                <div class="add-cat-cover-img pos-rltv">
                    <img src="https://asvs.in/wp-content/uploads/2017/08/dummy.png" class="img-fluid" alt="">
                    <span  class="pos-abs">
                        <i  class="fa-solid fa-pen-to-square"></i><input _ngcontent-npl-c293="" type="file"></span>
                </div> -->
                    <!-- <h5 class="clr-fnt2 mb-3 mt-2">Shoot Name</h5> -->
                    Link:<input type="text" class="form-control"
                        [value]=env.IMAGE_COVER+this.imageDetail?.cover_image?.with_watermark />
                    <div class="shareIcon">
                        <a href="https://www.instagram.com/" class="fa fa-instagram fontawsome"></a>
                        <a href="https://in.pinterest.com/" class="fa fa-pinterest fontawsome"></a>
                        <a href="https://www.fb.com" class="fab fa-facebook fontawsome"></a>
                        <a href="https://www.twitter.com" class="fa fa-twitter fontawsome"></a>
                    </div>

                    <!-- <form class="example-form"> -->
                    <!-- <mat-form-field appearance="fill">
                        <div class="input-bg">
                            <input matInput placeholder="" formControlName="shoot_name">
                            
                        </div>
                    </mat-form-field> -->

                    <!-- </form> -->

                </div>

            </div>

        </div>
    </div>
</div>


<div class="modal fade payout_modllp" id="size" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header align-items-center justify-content-end">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                  Buy Exclusive Rights
              </h5> -->
                <button #refModal1 type="button" class="btn close_modl" data-dismiss="modal">
                    Close
                </button>
            </div>
            <div class="modal-body">
                <div class="payoutmodl_wrap">
                    <div class="address_form grey_form">
                        <div class="sngl_img_info">
                            <div class="pricing_card text-center sngl_prcng_crd imgs_paycard">
                                <div class="plan_cntnt">
                                    <div class="pln_nm">
                                        <h4>Single Image Pricing</h4>
                                        <p>
                                            Keep ahead of the curve, tell better stories and explore the largest visual
                                        </p>
                                    </div>
                                    <div class="snglimg_prcng">
                                        <div class="cart_tabl table_sizess cntct_form p-0 table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">
                                                            Image Type
                                                        </th>
                                                        <th scope="col">
                                                            Resolution
                                                        </th>
                                                        <th scope="col">
                                                            Dimension (Pixels)
                                                        </th>
                                                        <th scope="col">
                                                            Size (Inches)
                                                        </th>
                                                        <th scope="col">
                                                            Price (Rs.)*
                                                        </th>
                                                        <th scope="col">
                                                            File Format
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let list of pricingdata;">
                                                        <td>
                                                            <mat-radio-button [value]="list?._id"
                                                                (change)="getSize($event)"></mat-radio-button>
                                                        </td>
                                                        <!-- <td *ngIf="list?.image_type?.includes('extra_large')">Extre Large</td> -->
                                                        <td>{{list?.image_type | titlecase}}</td>
                                                        <td>{{list?.resolution}}</td>
                                                        <td>{{list?.size}}</td>
                                                        <td>{{list?.size_inches}}</td>
                                                        <td>{{list?.price}}</td>
                                                        <td>{{list?.format}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn_theme_filled" (click)="addToCart1()">Buy</button>
            </div>
        </div>
    </div>
</div>