import { Component, OnInit } from '@angular/core';
import { TermAndConditionResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { Title,Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-termsnconditions',
  templateUrl: './termsnconditions.component.html',
  styleUrls: ['./termsnconditions.component.scss']
})
export class TermsnconditionsComponent implements OnInit {
  termAndConditiondata: any
  pagetitile:any
  constructor(private service:HttpService,private titleService: Title, private meta: Meta) { }

  ngOnInit(): void {
    this.titleService.setTitle("NuStock: Terms and Conditions")
    this.meta.updateTag({ property: 'og:description', content: "Delve into NuStock's terms and conditions, ensuring a seamless experience as you navigate the creative landscape with confidence and clarity"});
    this.meta.updateTag({ name: 'description', content: "Delve into NuStock's terms and conditions, ensuring a seamless experience as you navigate the creative landscape with confidence and clarity." });
    this.termAndCondition()
  }
    termAndCondition(){
      this.service.termAndCondition().subscribe((res:TermAndConditionResponse)=>{
        this.pagetitile=res.data.page_title
        this.termAndConditiondata=res.data.cms
      })
 }
}
