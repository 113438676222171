import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
// import { MapsAPILoader } from '@agm/core';
@Component({
  selector: 'app-budding-models',
  templateUrl: './budding-models.component.html',
  styleUrls: ['./budding-models.component.scss']
})
export class BuddingModelsComponent implements OnInit {
  eventId:any
  event:any
  env = environment
  // geoCoder: any;
  constructor(private service: HttpService,private router: ActivatedRoute) { }

  ngOnInit(): void {
    this.eventId=this.router.snapshot.paramMap.get('id')
    this.getEvent()
    // this.mapsAPILoader.load().then(() => {
     
    //   this.geoCoder = new google.maps.Geocoder;
    // });


  }
  getEvent(){
    const obj ={
      event_id:this.eventId
    }
    this.service.getSingleEvents(obj).subscribe((res)=>{
      this.event=res.data

    })
  }
  


}
