import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { ContributeStepsModule } from '../../user/contribute-steps/contribute-steps.module';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CategoriesImagesResponse, ResponseCheckout, ResponsePricing, ResponseSubscriptionPlan, TermAndConditionResponse } from 'src/app/models/model';
import { ChangeDetectorRef } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { NgZone } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

interface SubscriptionPlan {
  item: {
    currency: string,
    name: string,
    amount: number,
    description: string,
    discount: number
    avgPrice: number
  },
  _id: string,

  interval: number,
  quantity: number,
  size: number,
  planId: string,
  createdAt: string,
  updatedAt: string,
}
declare let $: any

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H' },
  { position: 2, name: 'Helium', weight: 4.0026, symbol: 'He' },
  { position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li' },
  { position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be' },
  { position: 5, name: 'Boron', weight: 10.811, symbol: 'B' },
  { position: 6, name: 'Carbon', weight: 12.0107, symbol: 'C' },
  { position: 7, name: 'Nitrogen', weight: 14.0067, symbol: 'N' },
  { position: 8, name: 'Oxygen', weight: 15.9994, symbol: 'O' },
  { position: 9, name: 'Fluorine', weight: 18.9984, symbol: 'F' },
  { position: 10, name: 'Neon', weight: 20.1797, symbol: 'Ne' },
];

@Component({
  selector: 'app-image-details',
  templateUrl: './image-details.component.html',
  styleUrls: ['./image-details.component.scss']
})
export class ImageDetailsComponent implements OnInit {




  simimg_crsl: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    autoWidth: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3,

      }
    },
    nav: true
  }

  imgcatg_crsl: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3,

      }
    },
    nav: true
  }

  smodl_crsl: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  entrsht_crsl: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 20,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

  routeID: any = '';
  imageDetail: any;
  env = environment;
  collectionImages: any;
  collectionImagesData: any;
  selectedImageSize: string = '';
  selectSubscriptionPlan: any = {};
  collectionImageID: any;
  collectionImageCount: any;
  ExclusiveOffers: any[] = [];
  offerPrice: any;
  imageID: any;
  cartlength: any
  category_id: any[] = [];
  categories_with_images: any[] = []
  selectedpicture: any;
  pricingdata: any[] = [];
  selectedsize: any = '';
  @ViewChild('refModal1', { static: true })
  myModal!: ElementRef<any>;
  @ViewChild('refModal2', { static: true })
  myModal2!: ElementRef<any>;
  wishlistdata: any[] = [];
  imagesByUser: any[] = [];
  imageUserId: any = '';
  token: any = '';
  imagesizes: any[] = []
  modalname = ''
  similarImages: any[] = []
  modalImages: any[] = []
  tags: any;
  sizes: any[] = []
  offerid: any;
  offersizeID: any;
  serachdata = ''
  category_slug: any;
  session: any
  show: boolean = true
  message = new FormControl('', Validators.required);
  imgform = new FormControl('', Validators.required);

  subscriptionPlans: SubscriptionPlan[] = [];
  subscribedPlans: any[] = [];

  constructor(private cdr: ChangeDetectorRef, private ngZone: NgZone, private service: HttpService, private route: ActivatedRoute, private loader: NgxUiLoaderService, private router: Router) {
    this.serachdata = ''
    this.session = this.service.getCookie('_session')
  }

  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  dataSource = ELEMENT_DATA;



  ngOnInit(): void {
    this.routeID = this.route.snapshot.paramMap.get('id');
    this.token = this.service.getToken();
    this.getCart()
    this.getSubscriptionPlanFxn()
    this.getImageDetail();
    this.getHomePageContent()
    this.termAndCondition()

    // this.getPricingFxn()

    if (this.service.getToken()) {
      this.getExclusiveOffers();
      this.getWishList()
    }


  }
  prestigious_clients: any[] = []

  getHomePageContent(): void {
    this.service.getHomePageContent({}).subscribe((resp) => {
      this.prestigious_clients = resp.home_data.prestigious_clients

    })
  }
  getPriceofExclusiverights() {
    const obj = {
      collection_image_id: this.imageID
    }
    this.service.getPriceofExclusiverightsOfImage(obj).subscribe((res: any) => {

      this.exclusiveAmounnt = res

    })
  }
  exclusiveAmounnt: any


  exclusiveRights() {

  }



  getPricingFxn(sizes: any) {
    const obj = {
      ids: sizes.toString()
    }
    this.service.getPricing1(obj).subscribe((res: ResponsePricing) => {
      this.imagesizes = res.data;
    })
  }

  onCheckboxChange(data: any) {

    if (data.checked) {
      this.filterKeywords.push(data.source.value)


    } else {
      let arr1 = this.filterKeywords.indexOf(data.source.value)
      this.filterKeywords.splice(arr1, 1)


    }


  }



  price: any
  filterKeywords: any[] = []
  ShootId: any = ''
  getImageDetail() {
    this.service.getImageDetail(this.routeID).subscribe((resp: any) => {

      this.imageDetail = resp?.image_details;
      this.subscriptionPlans = resp?.plans;
      this.subscribedPlans = resp?.subscription;
      this.price = resp?.vectorImagePrice
      this.modalname = this.imageDetail?.model_name
      this.imageID = resp?.image_details?._id
      this.imageUserId = this.imageDetail?.collection_details?.user_id
      this.category_id = resp?.image_details?.multiple_categories
      this.ShootId = resp?.image_details?.collection_details?.shoot_id
      this.sizes = this.imageDetail?.available_sizes.map((res: any) => res.image_type)

      this.getPricingFxn(this.sizes);
      this.show = false
      if (this.ShootId) {
        this.getImagesByShoot()
      }
      if (this.modalname) {
        this.getImagesByModal()
      }
      if (this.imageDetail?.tags.length != 0) {
        this.getSimilarImages()

      }
      if (this.category_id?.length > 0) {
        this.getCategoriesWithImages()

      }
      this.keyFilters()
      if (this.imageUserId) {
        this.getImagesByUser()
      }

      this.getPriceofExclusiverights()
    })
  }
  keysWords: any[] = []

  keyFilters() {
    const obj = {
      categories_ids: this.category_id
    }
    this.service.relatedKeywordByImage(obj).subscribe((res) => {
      this.keysWords = res.data
    }, (err: any) => {
      this.service.showErrorMessage({ message: err })
    })
  }

  getSimilarImages() {
    this.tags = this.imageDetail.tags.toString()
    const obj = {
      limit: 15,
      offset: 0,
      keywords: this.imageDetail.tags.toString()
    }
    this.service.getSimilarImages(obj).subscribe((res) => {
      this.similarImages = res.data
    })
  }

  getImagesByShoot() {

    const obj = {
      limit: 15,
      offset: 0,
      shoot_id: this.ShootId
    }
    this.service.getSimilarImages(obj).subscribe((res) => {
      this.ShooImages = res.data
    })
  }
  ShooImages: any[] = []

  getImagesByUser() {
    const obj = {
      limit: 3,
      offset: 0,
      contributor_id: this.imageUserId
    }
    this.service.getImagesByUser(obj).subscribe((res) => {
      this.imagesByUser = res.data

    })
  }
  getImagesByModal() {
    const obj = {
      limit: 15,
      offset: 0,
      model_name: this.modalname
    }
    this.service.getImagesByModal(obj).subscribe((res) => {
      this.modalImages = res.data

    })
  }


  saveUnsaveImage() {
    if (!this.service.getToken()) {

      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: 'Please Login First' })
      return
    } else {
      const obj = {
        //collection_img_id: this.routeID
        collection_img_id: this.imageID
      }
      this.service.addwhishlist(obj).subscribe((res: any) => {

        if (res.data == "Saved") {
          this.service.showSuccessMessage({ message: "The image saved successfully" })
          this.getWishList()
          this.getImageDetail()
        } else {
          this.service.showSuccessMessage({ message: "The image remove successfully" })
          this.getWishList();
          this.getImageDetail()

        }

      })
    }

  }

  viewCollectionImageButton() {
    this.collectionImages = this.collectionImagesData;
  }

  buyRights() {

    if (this.service.getToken()) {
      const obj = {
        collection_image_id: this.imageID,
        amount: this.exclusiveAmounnt?.netPrice * 100
      }
      this.service.getPaymentLinkOfExclusive(obj).subscribe((res: any) => {
        if (res.code == 200) {
          window.open(res.url)
        }

      }, (err: any) => {

        this.service.showErrorMessage({
          message: err
        })
      })



    } else {
      this.service.showSuccessMessage({ message: 'Please Login First' })
      $("#sign_modl").modal('show');
    }



  }


  applyFilter() {

    let search = this.serachdata
    let type = this.phototype
    const queryParams = { search: search };
    if (this.serachdata) {
      this.router.navigate(['/new-stock-images/' + type], { queryParams: queryParams })
    } else {
      this.router.navigate(['/new-stock-images/' + type])
    }



  }




  phototype: any = 'image'
  getPhototype(type: any) {
    this.phototype = type
  }

  addToCart() {
    let object = {
      collection_img_id: this.imageDetail._id,
      available_size_id: this.selectedImageSize,
      session_id: this.session,
    }
    if (!this.token) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: 'Please Login First' })
      return
    }
    if (this.selectedImageSize != '') {
      this.service.addCart(object).subscribe((resp: any) => {
        if (resp?.code == 200) {
          this.service.showSuccessMessage({
            message: "Added to Cart"
          })
          this.getCart()
          this.selectedImageSize = ''

        }
      }, (error: HttpErrorResponse) => {
        this.service.showErrorMessage({
          message: error?.error?.errors
        })
      })
    }
    else {
      this.service.showErrorMessage({
        message: "Please Size First"
      })
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
    }
  }
  addToCartForVector(imageID: any) {
    if (!this.token) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: 'Please Login First' })
      return
    }
    let object = {
      collection_img_id: imageID,
      session_id: this.session,

    }
    this.service.addCart(object).subscribe((resp: any) => {
      if (resp?.code == 200) {
        this.service.showSuccessMessage({
          message: "Added to Cart"
        })
        this.getCart()
        this.selectedImageSize = ''

      }
    }, (error: HttpErrorResponse) => {
      this.service.showErrorMessage({
        message: error?.error?.errors
      })
    })
  }

  getCart() {
    this.service.getCart().subscribe((Res: ResponseCheckout) => {
      this.cartlength = Res.data.length
      this.eventEmit(this.cartlength)
    })
  }
  // Hack: Scrolls to top of Page after page view initialized


  eventEmit(res: any) {
    this.service.updateCartViaNumberApi((JSON.stringify(res)))
  }

  async downloadtryImage(image: any, name: any) {
    const imgName = this.env.IMAGE_COVER_WATERMARK + image;

    this.loader.start();
    const file = await fetch(imgName + '?r=' + Math.floor(Math.random() * 100000)).then(function (response) {
      return response.blob();
    })

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = 'watermark_' + name;
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));
    this.loader.stop();
    setTimeout(() => { // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);

  }

  selectSingleImage(id: any,) {
    this.selectedImageSize = id;
  }
  

  selectPlanId(plan: any,) {
    this.selectSubscriptionPlan = plan;
  }

  getExclusiveOffers() {
    this.service.getExclusiveOffers().subscribe((resp: any) => {
      this.ExclusiveOffers = resp.data;
      this.offerPrice = 0;
    })
  }

  selectedOffer(event: any) {
    this.offerid = event.target.value
    let filteroffer = this.ExclusiveOffers.filter((res) => res._id == this.offerid)
    this.offerPrice = filteroffer[0].price

  }
  selectedofferSize(event: any) {
    this.offersizeID = event.target.value
  }


  getCategoriesWithImages(): void {
    this.service.getCategoriesWithImages({
      limit: 3,
      offset: 0,
      categories_ids: this.category_id
    }).subscribe((resp: CategoriesImagesResponse) => {

      this.categories_with_images = resp?.data[0]?.category_images;
      this.category_slug = resp?.data[0]?.category_slug


    })

  }
  selectPic(id: any) {

    this.service.getImageDetail(id).subscribe((resp: any) => {
      if (resp.code == 200) {
        this.selectedpicture = resp?.image_details._id;
        let sizes = resp?.image_details.available_sizes.map((res: any) => res.image_type)
        const obj = {
          ids: sizes.toString()
        }
        this.service.getPricing1(obj).subscribe((res: ResponsePricing) => {
          this.pricingdata = res.data;
        })
      }



    })
  }

  getSize(event: any) {

    this.selectedsize = event.value
  }

  addToCart1() {
    const obj = {
      collection_img_id: this.selectedpicture,
      available_size_id: this.selectedsize
    }


    if (this.selectedsize !== '') {
      this.service.addCart(obj).subscribe((res: any) => {
        this.myModal.nativeElement.click();
        this.getCart()
        this.selectedsize = ''
        this.service.showSuccessMessage({ message: "add to cart successfully" })
      })
    }
    else {
      this.service.showErrorMessage({ message: "Please select Size First" })
    }
  }
  //---------------------
  getWishList() {
    this.service.getWishList().subscribe((res: any) => {
      this.wishlistdata = res.data.map((res: any) => res?.collection_img_id?._id)
    })
  }


  //--------------------

  // addToWishlist(id: any) {

  //   if (this.service.getToken()) {
  //     const obj = {
  //       collection_img_id: id
  //     }
  //     this.service.addwhishlist(obj).subscribe((res: any) => {

  //       if (res.data == "Saved") {
  //         this.getWishList()
  //         this.service.showSuccessMessage({ message: "The image saved successfully" })


  //       } else {
  //         this.service.showSuccessMessage({ message: "The image remove successfully" })
  //         this.getWishList()
  //       }

  //     })

  //   } else {
  //     const obj = {
  //       loginType: true
  //     }
  //     this.service.LoggedType(JSON.stringify(obj))
  //     this.service.showErrorMessage({ message: "Please Login First" })
  //   }
  // }


  addToWishlist(id: any) {
    if (this.service.getToken()) {
      const obj = {
        collection_img_id: id
      };

      this.service.addwhishlist(obj).subscribe((res: any) => {
        if (res.data === "Saved") {
          this.getWishList();
          this.service.showSuccessMessage({ message: "The image saved successfully" });
          this.ngZone.run(() => {
            this.cdr.detectChanges(); // Manually trigger change detection
          });
        } else {
          this.getWishList();
          this.service.showSuccessMessage({ message: "The image removed successfully" });
          this.ngZone.run(() => {
            this.cdr.detectChanges(); // Manually trigger change detection
          });
        }

        // Update imageDetail.is_wishlist here if necessary
      });
    } else {
      const obj = {
        loginType: true
      };
      this.service.LoggedType(JSON.stringify(obj));
      this.service.showErrorMessage({ message: "Please Login First" });
    }
  }

  navigate(img_id: any) {

    this.router.navigate([`/moreinfo/${img_id}`]);
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  downloadImage(id: any) {
    if (!this.token) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: 'Please Login First' })
      return
    }
    if (this.selectedImageSize != '' || this.selectedImageSize) {


      window.open(this.env.SERVER_URL + 'users/download/image?token=' + this.token + '&collection_image_id=' + id + '&image_type_id=' + this.selectedImageSize);
      // this.selectedImageSize=''
    }
    else {
      this.service.showErrorMessage({
        message: "Please Size First"
      })
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
    }

  }

  onImgError(event: any) {
    event.target.src = this.env.DEFAULT_IMAGE;
  }


  viewall() {

    this.router.navigate(['/similarTags/' + this.filterKeywords + '/' + this.imageDetail?.tags])

  }

  //////////////////////subscription code
  IMAGE_COVER = environment.IMAGE_COVER
  subscriptionData: any[] = []
  monthlySubscriptionPlans: any[] = [];
  subscriptionIDforMonthly: any;
  plan: any;
  monthlyPlanID: any;
  PlanID: any;
  subscriptionID: any;
  smallPlan: any[] = [];


  getSubscriptionPlanFxn() {
    this.service.getSubscriptionPlan().subscribe((res: ResponseSubscriptionPlan) => {
      this.subscriptionData = res.data;
      this.subscriptionDataForSmallPlan = this.subscriptionData[0]?.subscription_types?.filter((res: any) => {
        if (this.selectPicSize == 'small') {
          return res.subscription_type == 'small'
        } else {
          return res.subscription_type == 'large'
        }
      })

      this.monthlySubscriptionPlans = this.subscriptionData[1]?.subscription_types; //all types of monthely subscription
      this.subscriptionIDforMonthly = this.subscriptionData[1]?._id   //monthely subscription _id
      this.plan = this.monthlySubscriptionPlans[0]?.plan;  //monthely subscription[0] index all plans

      this.smallPlan = this.subscriptionDataForSmallPlan[0]?.plan;

      this.monthlyPlanID = this.plan[0]?._id;
      this.subscriptionID = this.subscriptionData[0]?._id
      this.PlanID = this.subscriptionDataForSmallPlan[0]?.plan[0]?._id//normal subscription _id

    })
  }
  subscriptionDataForSmallPlan: any[] = []


  selectSize(event: any, type: any) {
    if (type == 0) {
      this.selectPicSize = event?.target?.value
      this.subscriptionDataForSmallPlan = this.subscriptionData[0]?.subscription_types?.filter((res: any) => {
        if (this.selectPicSize == 'small') {
          return res.subscription_type == 'small'
        } else {
          return res.subscription_type == 'large'
        }
      })
      this.smallPlan = this.subscriptionDataForSmallPlan[0].plan;
    } else {
      this.selectPicSize1 = event?.target?.value
      const obj = {
        target: {
          value: this.subtype
        }
      }
      this.monthlyplan(obj)

    }


  }

  selectPicSize = 'small'
  selectPicSize1 = 'small'
  subtype: any = 'Annual, billed monthly'

  monthlyplan(event: any) {
    this.subtype = event?.target?.value
    this.monthlySubscriptionPlans.forEach(element => {
      if (element?.name === event?.target?.value && element?.subscription_type == this.selectPicSize1) {
        this.plan = element?.plan;
      }

    });

  }

  tabClickForMonthly(event: any) {
    this.plan.forEach((element: any) => {
      if (event.tab.textLabel == element?.downloads) {
        this.monthlyPlanID = element?._id;
      }

    })
  }

  tabClickForOthers(event: any) {
    this.smallPlan.forEach((element: any) => {
      if (event.tab.textLabel == element?.downloads) {
        this.PlanID = element?._id;
      }

    })
  }
  loginType: boolean = false
  subscriptionPurchaseFunction() {
    if (!this.service.getToken()) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      return
    }

    let obj = {
      subscription_id: this.subscriptionID,
      plan_id: this.PlanID,
    }
    this.service.getPaymentLinkOfSubscription(obj).subscribe((res: any) => {
      if (res.code == 200) {
        window.open(res.url)
      }

    }, (err: any) => {

      this.service.showErrorMessage({
        message: err
      })
    })


  }


  subscriptionPurchaseFunctionMonthly() {
    if (!this.service.getToken()) {

      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      return
    }
    let obj = {
      subscription_id: this.subscriptionIDforMonthly,
      plan_id: this.monthlyPlanID,
    }
    this.service.getPaymentLinkOfSubscription(obj).subscribe((res: any) => {
      if (res.code == 200) {
        window.open(res.url)
      }

    }, (err: any) => {

      this.service.showErrorMessage({
        message: err
      })
    })

  }


  copyText(id: any) {
    const textToCopy = id; // Replace with the actual text you want to copy
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        const copyText: any = document.querySelector('.copy-text')
        copyText.classList.add('show');
        setTimeout(() => {
          copyText.classList.remove('show');
        }, 2000);
      })
      .catch((error) => {
        console.error('Unable to copy text:', error);
      });
  }
  copyText1(id: any) {
    const textToCopy = id; // Replace with the actual text you want to copy
    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        const copyText: any = document.querySelector('.copy-text1')
        copyText.classList.add('show');
        setTimeout(() => {
          copyText.classList.remove('show');
        }, 2000);
      })
      .catch((error) => {
        console.error('Unable to copy text:', error);
      });
  }



  view(data: any, viewtype: any) {
    let search = data
    let type = viewtype
    this.router.navigate(['/user/Explore/' + search + '/' + type])

  }

  termAndConditiondata: any

  termAndCondition() {
    this.service.termAndCondition().subscribe((res: TermAndConditionResponse) => {
      this.termAndConditiondata = res.data.cms
    })
  }

  requestConversion() {
    if (this.service.getToken()) {
      if (this.message.invalid && this.imgform.invalid) {
        this.message.markAllAsTouched()
        this.imgform.markAllAsTouched()
      }
      else if (this.message.invalid) {
        this.message.markAllAsTouched()
      }
      else if (this.imgform.invalid) {
        this.imgform.markAllAsTouched()
      } else {

        const data = {
          message: this.message.value,
          form: this.imgform.value
        }
        this.service.requestImageConversion(data).subscribe((res) => {
          $("#rqst_frmt").modal('hide')
          this.message.reset()
          this.imgform.reset()
        })

      }
    } else {
      this.service.showSuccessMessage({ message: 'Please Login First' })
      $("#sign_modl").modal('show');
    }
  }

  buySubscription() {
    if (!this.service.getToken()) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      return
    }
  
    if (Object.keys(this.selectSubscriptionPlan).length != 0) {
      localStorage.setItem('planDetails', JSON.stringify(this.selectSubscriptionPlan));
      this.router.navigate(['checkout', this.selectSubscriptionPlan._id]);
    }
    else
      this.service.showErrorMessage({ message: "Please select Plan First" })

   
  }

  downloadSubscribedImage(size: string = '') {
    if (!this.token) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: 'Please Login First' })
      return
    }
    // if (this.selectedImageSize != '' || this.selectedImageSize) {
    //   window.open(this.env.SERVER_URL + 'users/download/image?token=' + this.token + '&collection_image_id=' + id + '&image_type_id=' + this.selectedImageSize);
    //   // this.selectedImageSize=''
    // }
    const obj = {
      collection_img_id: this.imageDetail._id,
      collection_id: this.imageDetail.collection_id,
      token: this.service.getToken(),
      selected_image_size: size,
      subscriptionId: this.subscribedPlans[0]._id,//need to change the id when all set
    };
   
      this.service.downloadImage(obj).subscribe((blob: any) => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${this.routeID}.jpg`; // Set the download filename
        // Append link to the body, click it, and then remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.selectedImageSize = '';
      }, (err: any) => {
        this.selectedImageSize = '';
        this.service.showErrorMessage({
          message: 'Subscription expired/Not found!'
        })
      })
    }
}

// if (this.filterKeywords?.length > 0 && data == 'Tags' && viewtype == 'SimilarTags') {
//   let search = this.filterKeywords
//   let type = viewtype
//   const queryParams: NavigationExtras = {
//     queryParams: {
//       ref_id: this.imageDetail?.tags
//     }
//   };

//   this.router.navigate(['/user/Explore/' + search + '/' + type], queryParams)
// } else {
//   let search = data
//   let type = viewtype
//   this.router.navigate(['/user/Explore/' + search + '/' + type])
// }