import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { Title,Meta } from '@angular/platform-browser';
import { SEOService } from 'src/app/services/SEO/seo.service';
import { assert, log } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CategoriesImagesResponse, CategoryResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
interface Sort {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-explore-category',
  templateUrl: './explore-category.component.html',
  styleUrls: ['./explore-category.component.scss']
})
export class ExploreCategoryComponent implements OnInit {
  wishlistdata: any[] = []
  sortby: Sort[] = [
    { value: 'Most seen', viewValue: 'Most seen' },
    { value: 'Most popular', viewValue: 'Most popular' },
    { value: 'Recently added', viewValue: 'Recently added' },
    { value: 'Most relevant', viewValue: 'Most relevant' },
  ];
  filterKeywords: any[] = []
  searchingText: any = ''
  keysWords: any[] = []
  selectedSort = this.sortby[2].value;
  searchdata: any;
  exploreData: any
  env = environment
  selectedsize: any = '';
  selectedpicture: any;
  pricingdata: any[] = []
  @ViewChild('refModal', { static: true })
  myModal!: ElementRef<any>;
  cartlength: any;
  serachdata: any = '';
  type: any = ''
  name = new FormControl('');
  tags: any;
  limit = 45
  offset = 0
  show: boolean = true
  keyparam: any;
  session:any
  SEOdata:any;
  shortDes: any;
  constructor(private route: ActivatedRoute, private service: HttpService, private loader: NgxUiLoaderService,
     private router: Router, private seoService:SEOService) {
    this.type = this.route.snapshot.paramMap.get('type')
    this.keyparam = this.route.snapshot.params['type2'];
    this.getCAtBySlug()
    this.session = this.service.getCookie('_session')
    this.getSEO()
  }
  refId: any = ''
  imageDetailtags: any[] = []
  ngOnInit(): void {

    this.keyFilters();
   // this.titleService.setTitle("NuStock Images")
    if (this.service.getToken()) {
      this.getWishList()
    }

    this.getCart()
    // if(this.type=="plants"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free plant images at NuStock. Explore our plant stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free plant images at NuStock. Explore our plant stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Plant Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="nature-landscapes"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free nature landscape images at NuStock. Explore our nature landscape stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free nature landscape images at NuStock. Explore our nature landscape stock photos and pictures for your project.'});
      
    //   this.titleService.setTitle('NuStock: Nature Landscape Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="technology"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free technology images at NuStock. Explore our technology stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free technology images at NuStock. Explore our technology stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Technology Stock Images, Pictures and Photos');
    // }

    // this.getCart()
    // if(this.type=="rural-india"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free rural India images at NuStock. Explore our rural India stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free rural India images at NuStock. Explore our rural India stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Rural India Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="business-corporate"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free corporate business images at NuStock. Explore our corporate business stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free corporate business images at NuStock. Explore our corporate business stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Corporate Business Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="food-drink"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free food and drink images at NuStock. Explore our food and drink stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free food and drink images at NuStock. Explore our food and drink stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Food and Drink Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="health-fitness"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free fitness and health images at NuStock. Explore our fitness and health stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free fitness and health images at NuStock. Explore our fitness and health stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Fitness & Health Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="beauty-fashion"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free beauty and fashion images at NuStock. Explore our beauty and fashion stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free beauty and fashion images at NuStock. Explore our beauty and fashion stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Fashion & Beauty Stock Images, Pictures and Photos');
    // }



    // this.getCart()
    // if(this.type=="vacations-holidays"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free holiday images at NuStock. Explore our holiday stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free holiday images at NuStock. Explore our holiday stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Holiday Stock Images, Pictures and Photos');
    // }



    // this.getCart()
    // if(this.type=="lifestyle-seniors"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free senior lifestyle images at NuStock. Explore our senior lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free senior lifestyle images at NuStock. Explore our senior lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Senior Lifestyle Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="lifestyle-seniors"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free senior lifestyle images at NuStock. Explore our senior lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free senior lifestyle images at NuStock. Explore our senior lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Senior Lifestyle Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="lifestyle-families"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free family lifestyle images at NuStock. Explore our family lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free family lifestyle images at NuStock. Explore our family lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Family Lifestyle Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="lifestyle-couples"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free couples lifestyle images at NuStock. Explore our couples lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free couples lifestyle images at NuStock. Explore our couples lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Couples Lifestyle Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="lifestyle-adults"){
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free adult lifestyle images at NuStock. Explore our adult lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free adult lifestyle images at NuStock. Explore our adult lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Adult Lifestyle Stock Images, Pictures and Photos');
    // }

    // this.getCart()
    // if(this.type=="lifestyle-teenagers"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free teenage lifestyle images at NuStock. Explore our teenage lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free teenage lifestyle images at NuStock. Explore our teenage lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Teenage Lifestyle Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="lifestyle-children"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free child lifestyle images at NuStock. Explore our child lifestyle stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free child lifestyle images at NuStock. Explore our child lifestyle stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Child Lifestyle Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="indian-culture"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free Indian culture images at NuStock. Explore our Indian culture stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free Indian culture images at NuStock. Explore our Indian culture stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Indian Culture Stock Images, Pictures and Photos');
    // }



    // this.getCart()
    // if(this.type=="sports-leisure"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free leisure sports images at NuStock. Explore our leisure sports stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free leisure sports images at NuStock. Explore our leisure sports stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Leisure Sports Stock Images, Pictures and Photos');
    // }


    // this.getCart()
    // if(this.type=="shopping-retail"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free retail shopping images at NuStock. Explore our retail shopping stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free retail shopping images at NuStock. Explore our retail shopping stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Retail Shopping Stock Images, Pictures and Photos');
    // }



    // this.getCart()
    // if(this.type=="concepts-ideas"){
    //   this.meta.updateTag({ name: 'description', content: 'Browse our collection of royalty-free concepts and ideas images at NuStock. Explore our concepts and ideas stock photos and pictures for your project.'});
    //   this.meta.updateTag({ property: 'og:description', content: 'Browse our collection of royalty-free concepts and ideas images at NuStock. Explore our concepts and ideas stock photos and pictures for your project.'});
    //   this.titleService.setTitle('NuStock: Concepts & Ideas Stock Images, Pictures and Photos');
    // }

    
  }

  updateMeta()
  {
    this.seoService.updateTitle(this.SEOdata?.seo_title)
    this.seoService.updateDescription(this.SEOdata?.seo_description)
    this.seoService.updateKeywords(this.SEOdata?.other_related_category_option)
    this.seoService.updateCustomeHeader(this.SEOdata?.custom_header_tag)
    this.seoService.updateOgTags({
      Title: this.SEOdata?.seo_title,
      Description: this.SEOdata?.short_description,
      Image: this.env.CATEGORY_IMAGE + this.SEOdata?.image,
      SiteName: 'NuStock',
    });
  } 
  getSEO()
  {        
       this.service.getSEO(this.type).subscribe((res:any)=>{
       this.SEOdata = res.data
        this.updateMeta();
       })    
  }
  getCAtBySlug() {
    this.service.getCategoryBySlug(this.type).subscribe((res) => {
      if (res.code == 200) {
        this.categoryName = res?.data?.category_name
        this.categoryId = res?.data?._id
        this.shortDes=res?.data?.short_description
        this.getImagesByCategory()
      }
    })
  }



  getChecked(tags: any): boolean {
    if (this.filterKeywords.includes(tags)) {
      return true
    } else {
      return false
    }
  }
  getImagesbyTags() {
    let obj: any = {
      limit: this.limit,
      offset: this.offset,
      keywords: this.filterKeywords
    }

    this.service.searchImages(obj).subscribe((res) => {
      this.show = false
      this.exploreData = res?.data
      this.total = res.count



    })
  }
  onImgError(event: any) {
    event.target.src = this.env.DEFAULT_IMAGE;
  }

  total: any
  page: number = 1






  getWishList() {
    this.service.getWishList().subscribe((res: any) => {

      this.wishlistdata = res?.data.map((res: any) => res?.collection_img_id?._id)

    })
  }


  async downloadImage(image: any, name: any) {

    const imgName = image;

    this.loader.start();
    const file = await fetch(imgName + '?r=' + Math.floor(Math.random() * 100000)).then(function (response) {
      return response.blob();
    })

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = 'watermark_' + name;
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));
    this.loader.stop();
    setTimeout(() => { // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }

  addToWishlist(id: any) {

    if (this.service.getToken()) {
      const obj = {
        collection_img_id: id
      }
      this.service.addwhishlist(obj).subscribe((res: any) => {

        if (res.data == "Saved") {
          this.service.showSuccessMessage({ message: "The image saved successfully" })
          this.getWishList()
        } else {
          this.service.showSuccessMessage({ message: "The image remove successfully" })
          this.getWishList()
        }

      })

    } else {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: "Please Login First" })
    }
  }
  getSize(event: any) {
    this.selectedsize = event.value
  }

  selectPic(id: any) {

    this.service.getImageDetail(id).subscribe((resp: any) => {
      if (resp.code == 200) {
        this.selectedpicture = resp?.image_details?.id;
        let sizes = resp?.image_details.available_sizes.map((res: any) => res.image_type)
        const obj = {
          ids: sizes.toString()
        }
        this.service.getPricing1(obj).subscribe((res) => {
          this.pricingdata = res.data;
        })
      }


    })
  }

  addToCart() {
    const obj = {
      collection_img_id: this.selectedpicture,
      available_size_id: this.selectedsize,
      session_id: this.session,
    }
    if (this.selectedsize !== '') {
      this.service.addCart(obj).subscribe((res: any) => {

        this.myModal.nativeElement.click();
        this.getCart()
        this.service.showSuccessMessage({ message: "add to cart successfully" })
      })
    }
    else {
      this.service.showErrorMessage({ message: "Please select Size First" })
    }
  }
  getCart() {
    this.service.getCart().subscribe((Res) => {

      this.cartlength = Res?.data?.length
      this.eventEmit(this.cartlength)

    })
  }

  eventEmit(res: any) {
    this.service.updateCartViaNumberApi((JSON.stringify(res)))
  }
  sortByFilter(event: any) {
  }
  categoryName: any = ''
  categoryId: any = ''

  getImagesByCategory(isScrolltoTop = false): void {

    const obj: any = {
      limit: this.limit,
      offset: this.offset,
      categories_ids: this.categoryId,
    }
    if (this.phototype != 'none' && this.phototype) {
      obj.type = this.phototype
    }
    if (this.keyparam == "Indoors") {

      this.filterKeywords.push("Indoor")
      obj.keywords = this.filterKeywords
    } else if (this.keyparam == "Outdoors") {
      this.filterKeywords.push("Outdoor")
      obj.keywords = this.filterKeywords
    } else if (this.keyparam) {
      this.filterKeywords.push(this.keyparam.toLowerCase())
      obj.keywords = this.filterKeywords
    }
    if (this.name.value) {
      obj.search = this.name.value
    }
    if (this.filterKeywords && this.filterKeywords.length > 0) {

      obj.keywords = this.filterKeywords
    }

    this.service.searchImages(obj).subscribe((resp: any) => {
      this.show = false
      this.exploreData = resp?.data
      this.total = resp.count

      if (isScrolltoTop) {
        window.setTimeout(function(){
          window.scrollTo(0, 0)
        },50)
        
      }

    })
  }



  phototype: any = 'none'



  getPhototype(type: any) {

    this.phototype = type


  }


  // applyFilter():any {
  //   this.page=1
  //   let obj:any
  //     obj = {
  //       limit: this.limit,
  //       offset:0,
  //       categories_ids:this.categoryId
  //     }
  //     if(this.phototype!='none'  && this.phototype){
  //       obj.type=this.phototype
  //     }

  //     if(this.name.value){
  //       obj.search=this.name.value
  //     }
  //     if(this.filterKeywords.length>0){
  //       obj.keywords=this.filterKeywords
  //     }
  //    this.service.searchImages(obj).subscribe((resp) => {
  //    this.exploreData = resp?.data
  //    this.total= resp.count

  //   })
  // }

  applyFilter() {

    let search = this.name.value
    let type = this.phototype
    const queryParams = { search: search };
    if (this.name.value) {
      this.router.navigate(['/new-stock-images/' + type], { queryParams: queryParams })
    } else {
      this.router.navigate(['/new-stock-images/' + type])
    }



  }


  keyFilters() {
    this.service.keyFilters().subscribe((res) => {
      this.keysWords = res.data
    })
  }



  onCheckboxChange(data: any) {
    this.page = 1
    let obj: any = {
      limit: this.limit,
      offset: 0,

      categories_ids: this.categoryId
    }
    
    if (data.checked) {
      if (this.phototype != 'none' && this.phototype) {
        obj.type = this.phototype
      }
      this.filterKeywords?.push(data.source.value)
      obj.keywords = this.filterKeywords

      if (this.name.value != '') {
        obj.search = this.name.value
      }
      if (!this.name.value) {
        this.searchingText = ''
      }

      this.service.searchImages(obj).subscribe((res) => {
        this.show = false
        this.exploreData = res?.data
        this.total = res.count


      })
    } else {
      let arr1 = this.filterKeywords.indexOf(data.source.value)
      this.filterKeywords.splice(arr1, 1)
      if (this.filterKeywords?.length > 0) {
        obj.keywords = this.filterKeywords
      }
      if (this.phototype != 'none' && this.phototype) {
        obj.type = this.phototype
      }
      if (this.name.value != '') {
        obj.search = this.name.value
      }


      this.service.searchImages(obj).subscribe((res) => {
        this.show = false

        this.exploreData = res?.data
        this.total = res.count

      })


    }



  }
  addToCartForVector(imageID: any) {
    let object = {
      collection_img_id: imageID,
      session_id: this.session
    }
    this.service.addCart(object).subscribe((resp: any) => {
      if (resp?.code == 200) {
        this.service.showSuccessMessage({
          message: "Added to Cart"
        })
        this.getCart()
        this.selectedsize = ''

      }
    }, (error: HttpErrorResponse) => {
      this.service.showErrorMessage({
        message: error?.error?.errors
      })
    })
  }







  viewall(data: any, viewtype: any) {


    let search = data
    let type = viewtype
    this.router.navigate(['/'], {
      skipLocationChange: true
    }).then(res => {
      this.router.navigate(['/user/Explore/' + search + '/' + type])
    })

  }


  pageChanged(event: any) {
    this.page = event
    this.limit = 45,
      this.offset = (event - 1) * 45
    this.getImagesByCategory(true)

  }
}


