import { NgModule } from "@angular/core"
import { ConfirmDialog } from "./confirm";
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    declarations: [
      ConfirmDialog,  
    ],
    imports: [
        MatDialogModule,
        MatButtonModule
    ]
  })
  export class ConfirmModule { }