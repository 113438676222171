import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit,ElementRef, ViewChild  } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { log } from 'console';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CategoriesImagesResponse, CategoryResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
interface Sort {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-explore-similar-tags',
  templateUrl: './explore-similar-tags.component.html',
  styleUrls: ['./explore-similar-tags.component.scss']
})
export class ExploreSimilarTagsComponent implements OnInit {

  wishlistdata: any[] = []
  sortby: Sort[] = [
    { value: 'Most seen', viewValue: 'Most seen' },
    { value: 'Most popular', viewValue: 'Most popular' },
    { value: 'Recently added', viewValue: 'Recently added' },
    { value: 'Most relevant', viewValue: 'Most relevant' },
  ];
  selectedSort = this.sortby[2].value;
  searchdata: any;
  exploreData: any
  env = environment
  selectedsize: any='';
  selectedpicture: any;
  pricingdata: any[] = []
  @ViewChild('refModal', { static: true })
  myModal!: ElementRef<any>; 
  cartlength: any;
  serachdata: any='';
  type:any=''
  name = new FormControl('');
  tags: any;
  limit=45
  offset=0;
  show:boolean=true
  constructor(private route: ActivatedRoute, private service: HttpService, private loader: NgxUiLoaderService,private router:Router) { }
  refId:any=''
  imageDetailtags:any[]=[]
  ngOnInit(): void {
    let selectedKeywords=this.route.snapshot.params.selectedTags
    let allKeywords= this.route.snapshot.params.allTags
    this.filterKeywords=selectedKeywords.split(',')
    this.imageDetailtags= allKeywords.split(',') //id
    this.keyFilters()
    this.getSimilar()
    if(this.service.getToken()){
       this.getWishList()
    }
    
    this.getCart()
  }

  
  

  getChecked(tags:any):boolean{
    if(this.filterKeywords.includes(tags)){
      return true
    }else{
       return false
    }
  }
  getSimilar(){
    const obj:any={
     
      limit: this.limit,
      offset:this.offset,


    }
    if (this.phototype != 'none' && this.phototype) {
      obj.type = this.phototype
    }
    if (this.name.value) {
      obj.search = this.name.value

    } if (this.filterKeywords.length > 0) {
      obj.keywords = this.filterKeywords
    }
    this.service.getSimilarImages(obj).subscribe((res)=>{
      this.exploreData=res.data
      this.total= res.count;
      this.show=false
   
    })
   }


  onImgError(event:any) { 
    event.target.src = this.env.DEFAULT_IMAGE;
  }

  pageChanged(event: any) {
    this.page = event
    this.limit = 45,
      this.offset = (event - 1) * 45
    this.getSimilar()
  }

  total:any
  page:number=1





   
  getWishList() {
    this.service.getWishList().subscribe((res: any) => {
     
      this.wishlistdata = res?.data.map((res: any) => res?.collection_img_id?._id)
      
    })
  }

  
  async downloadImage(image: any,name:any) {
   
    const imgName = image;

    this.loader.start();
    const file = await fetch(imgName + '?r=' + Math.floor(Math.random() * 100000)).then(function (response) {
      return response.blob();
    })

    const blob = window.URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = blob;
    link.download = 'watermark_'+name;
    link.dispatchEvent(new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    }));
    this.loader.stop();
    setTimeout(() => { // firefox
      window.URL.revokeObjectURL(blob);
      link.remove();
    }, 100);
  }

  addToWishlist(id: any) {

    if (this.service.getToken()) {
      const obj = {
        collection_img_id: id
      }
      this.service.addwhishlist(obj).subscribe((res: any) => {

        if (res.data == "Saved") {
          this.service.showSuccessMessage({ message: "The image saved successfully" })
          this.getWishList()
        } else {
          this.service.showSuccessMessage({ message: "The image remove successfully" })
          this.getWishList()
        }

      })

    } else {
      const obj={
        loginType:true
      }
      this.service.LoggedType(JSON.stringify(obj))
      this.service.showErrorMessage({ message: "Please Login First" })
    }
  }
  getSize(event: any) {
    this.selectedsize=event.value
  }
  
  selectPic(id: any) {
   
    this.service.getImageDetail(id).subscribe((resp: any) => {
      if (resp.code == 200) {
        this.selectedpicture = resp?.image_details?.id;
        let sizes=resp?.image_details.available_sizes.map((res:any)=> res.image_type)
        const obj={
          ids:sizes.toString()
        }
        this.service.getPricing1(obj).subscribe((res) => {
          this.pricingdata = res.data;
        })
      }

     
  })
}

  addToCart(){
    const obj={
      collection_img_id: this.selectedpicture,
      available_size_id:this.selectedsize
     }
     if(this.selectedsize !== ''){
    this.service.addCart(obj).subscribe((res:any)=>{
     
      this.myModal.nativeElement.click();
      this.getCart()
      this.service.showSuccessMessage({message:"add to cart successfully"})
    })
  }
  else{
    this.service.showErrorMessage({message:"Please select Size First"})
  }
  }
  getCart(){
    this.service.getCart().subscribe((Res)=>{
     
      this.cartlength=Res?.data?.length
      this.eventEmit( this.cartlength)
    
    })
  }

  eventEmit(res: any) {
		this.service.updateCartViaNumberApi((JSON.stringify(res)))
	}
  


 




 phototype:any='none'



 getPhototype(type:any){

  this.phototype=type

 
 }

 applyFilter() {
    
  let search = this.name.value
  let type = this.phototype
  const queryParams = { search: search };
  if(this.name.value){
    this.router.navigate(['/new-stock-images/' + type],{ queryParams: queryParams })
  }else{
    this.router.navigate(['/new-stock-images/' + type])
  }
 


}

//  applyFilter(): any {
//   this.page = 1
//   let obj: any
//   obj = {
//     limit: this.limit,
//     offset: 0,
  

//   }
//   this.searchingText = this.name.value

//   if (this.phototype != 'none' && this.phototype) {
//     obj.type = this.phototype
//   }

//   if (this.name.value) {
//     obj.search = this.name.value
//   }
//   if (this.filterKeywords.length > 0) {
//     obj.keywords = this.filterKeywords
//   }


//   this.service.getSimilarImages(obj).subscribe((resp) => {
//     this.exploreData = resp?.data
//     this.total = resp.count

//   })
// }







  searchingText:any=''
  keysWords:any[]=[]
  keyFilters(){
    this.service.keyFilters().subscribe((res) => {
      this.keysWords = res.data
    })
  }

  filterKeywords:any[]=[]






  addToCartForVector(imageID:any){
    let object = {
      collection_img_id: imageID,
    
    }
    this.service.addCart(object).subscribe((resp: any) => {
      if (resp?.code == 200) {
        this.service.showSuccessMessage({
          message: "Added to Cart"
        })
        this.getCart()
        this.selectedsize = ''

      }
    }, (error: HttpErrorResponse) => {
      this.service.showErrorMessage({
        message: error?.error?.errors
      })
    })
  }



  onCheckboxChange(data: any) {
    this.page = 1
    let obj: any = {
      limit: this.limit,
      offset: 0,
      
    }
    if (data.checked) {
      if (this.phototype != 'none' && this.phototype) {
        obj.type = this.phototype
      }
      this.filterKeywords.push(data.source.value)
      obj.keywords = this.filterKeywords

      if (this.name.value != '') {
        obj.search = this.name.value
      }

      this.service.getSimilarImages(obj).subscribe((res) => {
        this.exploreData = res?.data
        this.total = res.count;
        this.show=false
      })

    } else {
      let arr1 = this.filterKeywords.indexOf(data.source.value)
      this.filterKeywords.splice(arr1, 1)
      if (this.filterKeywords?.length > 0) {
        obj.keywords = this.filterKeywords
      }
      if (this.phototype != 'none' && this.phototype) {
        obj.type = this.phototype
      }
      if (this.name.value != '') {
        obj.search = this.name.value
      }
      this.service.getSimilarImages(obj).subscribe((res) => {
        this.exploreData = res?.data
        this.total = res.count;
        this.show=false
      })

    }


  }



  viewall(data: any, viewtype: any) {
    
  
    let search = data
    let type = viewtype
    this.router.navigate(['/'], {
      skipLocationChange: true
    }).then(res => {
      this.router.navigate(['/user/Explore/' + search + '/' + type])
    })
 
      }

}
