import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "./auth/auth.guard";

// import { HomepageComponent } from "./views/homepage/homepage.component";
// import { PricingComponent } from "./views/pricing/pricing.component";
// import { ContactUsComponent } from "./views/contact-us/contact-us.component";
// import { PrivacyPolicyComponent } from "./views/privacy-policy/privacy-policy.component";
// import { TermsnconditionsComponent } from "./views/termsnconditions/termsnconditions.component";
// import { FaqComponent } from "./views/faq/faq.component";
// import { ImageDetailsComponent } from "./views/image-details/image-details.component";
// import { CheckoutComponent } from "./views/checkout/checkout.component";
// import { BuddingModelsComponent } from "./views/budding-models/budding-models.component";
// import { PhotoeventsListComponent } from "./views/photoevents-list/photoevents-list.component";
// import { EventFormComponent } from "./views/event-form/event-form.component";
// import { ExploreComponent } from "./views/explore/explore.component";
// import { CategoriesComponent } from "./views/categories/categories.component";
// import { AboutUsComponent } from "./views/about-us/about-us.component";
// import { HowItWorksComponent } from "./views/how-it-works/how-it-works.component";
// import { TermsOfServiceComponent } from "./views/terms-of-service/terms-of-service.component";
// import { DashboardComponent } from "./views/dashboard/dashboard.component";
// import { DbNotificationsComponent } from "./views/db-notifications/db-notifications.component";
// import { DbSavedComponent } from "./views/db-saved/db-saved.component";
// import { DbAddressesComponent } from "./views/db-addresses/db-addresses.component";
// import { DbAddAddressComponent } from "./views/db-add-address/db-add-address.component";
// import { DbEditAddressComponent } from "./views/db-edit-address/db-edit-address.component";
// import { DbAccountDetailsComponent } from "./views/db-account-details/db-account-details.component";
// import { DbOrdersComponent } from "./views/db-orders/db-orders.component";
// import { ViewOrderComponent } from "./views/view-order/view-order.component";
// import { DbContributionsComponent } from "./views/db-contributions/db-contributions.component";
// import { DbPayoutsComponent } from "./views/db-payouts/db-payouts.component";
// import { DbDownloadsComponent } from "./views/db-downloads/db-downloads.component";
// import { DbSubscriptionsComponent } from "./views/db-subscriptions/db-subscriptions.component";
// import { OwnedImagesComponent } from "./views/owned-images/owned-images.component";
// import { ContributeComponent } from "./views/contribute/contribute.component";
// import { BillingDetailsComponent } from "./views/billing-details/billing-details.component";
// import { ContributeStepsComponent } from "./views/contribute-steps/contribute-steps.component";

const routes: Routes = [
    {
        path: "",
        loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
        // component: HomepageComponent,
    },
    {
        path: "user",
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
    scrollPositionRestoration: "enabled",
    initialNavigation: 'enabledBlocking'
}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
