import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { AboutUsResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
	ABOUTIMAGES=environment.ABOUTIMAGES

	clients_slider: OwlOptions = {
	    loop: true,
	    mouseDrag: true,
	    touchDrag: true,
	    pullDrag: true,
	    dots: false,
	    navSpeed: 700,
	    margin: 20,
	    nav: false,
	    center: true,
	    autoplay: true,
	    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
	    responsive: {
	      0: {
	        items: 1
	      },
	      400: {
	        items: 2
	      },
	      740: {
	        items: 3
	      },
	      940: {
	        items: 6
	      }
	    }

	};

	teams_slider: OwlOptions = {
		loop: true,
	    mouseDrag: true,
	    touchDrag: true,
	    pullDrag: true,
	    dots: false,
	    navSpeed: 700,
		margin: 30,
	    autoplay: false,
	    autoplaySpeed: 8000,
	    autoplayTimeout: 10000,
	    nav: true,
	    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
	    responsive: {
	      0: {
	        items: 1
	      },
	      400: {
	        items: 1
	      },
	      740: {
	        items: 2
	      },
	      940: {
	        items: 2
	      }
	    },

	}
	aboutus_content: any;

  constructor(private service : HttpService,private router:Router,private titleService: Title,private meta: Meta) { }

  ngOnInit(): void {
	this.meta.updateTag({ name: 'description', content: "Learn about NuStock's journey, passion, and commitment to exceptional visual storytelling. Join us in redefining creativity through breathtaking images."});
	this.meta.updateTag({ property: 'og:description', content: "Learn about NuStock's journey, passion, and commitment to exceptional visual storytelling. Join us in redefining creativity through breathtaking images."});
	this.titleService.setTitle("NuStock: About Us")
	this.getAboutUs()
  }
  getAboutUs(){
    this.service.getAboutUs({}).subscribe((resp:AboutUsResponse) => {
		this.aboutus_content=resp.home_data
    })
  }
  click(){
	let search='latestUploads'
	let type='allImages'
   this.router.navigate(['/user/Explore/'+search+'/'+type]) 
  }
 

}
