import { Component, OnInit } from '@angular/core';
import { TermOfServiceResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { Title,Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-terms-of-service',
  templateUrl: './terms-of-service.component.html',
  styleUrls: ['./terms-of-service.component.scss']
})
export class TermsOfServiceComponent implements OnInit {
  termOfServicedata: any

  constructor(private service:HttpService,private titleService: Title,private meta: Meta) { 
    
  }

  ngOnInit(): void {
   this.titleService.setTitle("NuStock: Terms of Use")
   this.meta.updateTag({ name: 'description', content: "Go through NuStock's terms of use, empowering clear guidelines to your exploration. We are a platform where creativity thrives with purpose and respect."});
   this.termOfService()
   this.updateMetaTags();
  }

   updateMetaTags() {
    const metaDescriptionTag = document.querySelector('meta[name="description"]');
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute('content', "Go through NuStock's terms of use, empowering clear guidelines to your exploration. We are a platform where creativity thrives with purpose and respect.");
    } else {
      // If the meta tag doesn't exist, create it
      const newMetaTag = document.createElement('meta'); 
      newMetaTag.setAttribute('name', 'description');
      newMetaTag.setAttribute('content', "Go through NuStock's terms of use, empowering clear guidelines to your exploration. We are a platform where creativity thrives with purpose and respect.");
      document.head.appendChild(newMetaTag);
      // alert("inside else")
    }
  }

  termOfService():any{
        this.service.termOfService().subscribe((res:TermOfServiceResponse)=>{
        this.termOfServicedata=res.data.cms
})
  }
}
