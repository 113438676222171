<div class="main_home_wraper">
    <!-- <app-header-cmn></app-header-cmn> -->
    <app-db-header></app-db-header>

    <div class="inner_wrap_sectn explore_wrap">
        <section class="image_details">
            <div class="custom_container">
                <div class="search_bar">
                    <form class="d-flex srch_form">
                        <div class="slct_srch d-flex">
                            <div class="srch_drpdwn">
                                <div class="dropdown">
                                    <button class="btn btn-secondary dropdown-toggle slctdrop_btn" type="button"
                                        id="dropdownMenuButton" data-toggle="dropdown" aria-expanded="false">
                                          {{phototype == 'none' ? 'All' : phototype == 'image'  ? 'Photos' : phototype == 'vector'  ? 'Vector':'All'}}

                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <!-- <a class="dropdown-item" (click)="getPhototype('none')">All</a> -->
                                        <a class="dropdown-item" (click)="getPhototype('vector')">Vectors</a>
                                        <a class="dropdown-item" (click)="getPhototype('image')">Photos</a>
                                    </div>
                                </div>
                            </div>
                            <div class="srch_input">
                                <input class="form-control mr-2 hdr_srch" type="search" placeholder="Search"
                                    aria-label="Search" [formControl]="name" (keyup.enter)="applyFilter()"/>
                            </div>
                        </div>
                        <div class="srch_bttn">
                            <button class="btn btn_theme_filled" type="submit" (click)="applyFilter()">
                                Search
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </section>
        <section class="contact_top explr_sctn">
            <div class="custom_container">
                <div class="contactus_wrap">
                    <div class="pg_hdng">
                        <h1>Explore</h1>
                        <div class="pg_brdcrmb">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <a href="#">Home</a>
                                    </li>
                                    <li class="breadcrumb-item active" aria-current="page">
                                        Explore
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="contact_top explr_sctn">
            <div class="custom_container">
                <div class="contactus_wrap">
                    <div class="">
                        <mat-expansion-panel hideToggle class="box_shadow mb-3">
                            <mat-expansion-panel-header class="hover_class gap">
                                <mat-panel-title class="gap">
                                    <button _ngcontent-serverapp-c243="" class="btn btn_outline"
                                        ng-reflect-router-link=""><i class="fa fa-filter"></i> Filters</button>
                                </mat-panel-title>
                                <mat-panel-description>
                                    <h5>{{this.total ? (this.total == 0 ? '0 images' : this.total + ' + images') : (this.exploreData?.length == 0 ? '0 images' : this.total + ' + images')}}  <span *ngIf="this.searchingText ">for '{{this.searchingText }}'</span>  <span class="ml-2" *ngIf="this.filterKeywords?.length != 0"><strong class="black">Filter :</strong> '{{this.filterKeywords.toString()}}'</span></h5>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="row" >
                                <div class="col-lg-3 col-md-6 py-2" *ngFor="let key of this.keysWords"> 
                                    <h6>{{key?.category_name}}</h6>
                                    <div class="ml-2">
                                        <ul>
                                            <li *ngFor="let keywords of key.keywords">
                                                <mat-checkbox class="example-margin"  [value]="keywords" (change)="onCheckboxChange($event)"> {{keywords}}</mat-checkbox>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                               
                            </div>
                        </mat-expansion-panel>

                    </div>
                </div>
            </div>
        </section>

        <section class="contact_us latest_uploads explr_sctn">
            <div class="padding_custom">

                <!-- <div class="ltst_uplds_wrap">
                    <div class="ltst_imgs_grid">
                        <div class="colm_grid"
                            *ngFor="let image of this.exploreData | paginate: { itemsPerPage: 9, currentPage: page ,totalItems: total }">
                            <ul class="colm_grid_list">
                                <li class="colm_grid_item mt-3">
                                    <a>
                                        <div class="colm_grid_div">
                                            <div class="colm_grid_img">
                                                <a class="gllry_img">
                                                    <img loading="lazy" appLazyLoad
                                                        [src]="env?.ExploreImageSize+image?.cover_image?.with_watermark+'&size=400x267'"
                                                        class="img-fluid" (error)="onImgError($event)">
                                                </a>
                                            </div>
                                            <div class="overlay_div" [style.cursor]="'pointer'"
                                                routerLink="/user/moreinfo/{{image?._id}}">

                                            </div>
                                            <div class="inner-content">
                                                <div class="inner-content-body">
                                                    <div class="img_badge">
                                                        <ul class="d-flex justify-content-end">
                                                            <li class="img_dwnld ml-2"
                                                                *ngIf="!this.wishlistdata?.includes(image._id)">
                                                                <span title="Collect"
                                                                    (click)="addToWishlist(image._id)"><i
                                                                        class="far fa-bookmark "></i></span>
                                                            </li>
                                                            <li class="img_dwnld ml-2"
                                                                *ngIf="this.wishlistdata?.includes(image._id)">
                                                                <span title="Collect"
                                                                    (click)="addToWishlist(image._id)"><i
                                                                        class="fas fa-bookmark"></i></span>
                                                            </li>
                                                            <li class="img_dwnld ml-2">
                                                                <span
                                                                    title="Similar"><mat-icon>content_copy</mat-icon></span>
                                                            </li>
                                                            <li class="img_dwnld ml-2" *ngIf="image?.type != 'vector' || !image?.type ">
                                                                <span  title="Add to Cart"
                                                                    (click)="selectPic(image._id)"><mat-icon
                                                                        data-toggle="modal"
                                                                        data-target="#exclsv_rts">add_shopping_cart</mat-icon></span>
                                                               
                                                            </li>
                                                            <li class="img_dwnld ml-2" *ngIf="image?.type == 'vector'">
                                                                
                                                                <span  title="Add to Cart"
                                                                (click)="addToCartForVector(image._id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                            </li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="custom-btm">
                                                <div class="img_actn d-flex justify-content-between">
                                                    <div class="img_usr_div d-flex align-items-center">
                                                        <div class="usr_img mr-2">
                                                            <img loading="lazy" appLazyLoad
                                                            [src]="this.env.USER_PROFILE+image?.collection_data?.user_details?.profile_image"
                                                            class="img-fluid" (error)="onImgError($event)" >
                                                        </div>
                                                        <div class="usr_nm">
                                                            <span>By {{image?.collection_data?.user_details?.first_name ? image?.collection_data?.user_details?.first_name + image?.collection_data?.user_details?.last_name: 'N/A'}}</span>
                                                        </div>
                                                    </div>
                                                    <a class="btn btn_optn bg-white"
                                                        (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)">
                                                        <span><i class="fas fa-angle-double-down mr-2"></i></span>
                                                        <span>Try</span>
                                                    </a>
                                                     <div class="img_dwnld">
                                                        <span title="Download" (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)"><i class="fa fa-arrow-down"></i></span>
                                                    </div> 
                                                </div>
                                            </div>

                                        </div>

                                    </a>
                                </li>

                            </ul>
                        </div>

                    </div>
                    <div *ngIf="this.exploreData?.length == 0">
                        <p class="text-center pstyle mt-3">No data Found</p>
                    </div>
                    <div class="pagntn_dsn text-center mt-5">
                        <ul class="pagination">
                            
                            <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                        </ul>
                    </div>
                </div> -->

                <div class="MuiBox-root imgs_grp ">
                    <ul  class="colm_grid_list colm_grid_listt">
                        <li style="height: auto; margin-bottom: 5px;" class=" MuiImageListItem-root MuiImageListItem-masonry  colm_grid_item colm_grid_itemm "  *ngFor="let image of this.exploreData | paginate: { itemsPerPage: 45, currentPage: page ,totalItems: total }"   [ngClass]="image?.orientation == 'vertical' ? 'Imageitems_vertical' : 'Imageitems_horizontal'">
                            <a >
                                <div class="colm_grid_div" >
                                    <div class="colm_grid_img" >
                                        <a class="gllry_img" >
                                            <img loading="lazy" appLazyLoad [style.cursor]="'pointer'"  [src]="env?.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark" class="img-fluid"  routerLink="/moreinfo/{{image?.image_unique_id}}">
                                        </a>
                                    </div>
                                    <!-- <div class=""  >
                                        
                                    </div> -->
                                    <div class="inner-content">
                                        <div class="inner-content-body">
                                            <div class="img_badge">
                                                
                                                    <ul class="d-flex justify-content-end">
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="!this.wishlistdata?.includes(image._id)">
                                                            <span date-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="far fa-bookmark "></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2"
                                                            *ngIf="this.wishlistdata?.includes(image._id)">
                                                            <span date-title="Save"
                                                                (click)="addToWishlist(image._id)"><i
                                                                    class="fas fa-bookmark"></i></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2">
                                                            <span
                                                            date-title="Similar Images"   routerLink="/similarImages/{{image.tags}}"><mat-icon>content_copy</mat-icon></span>
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type != 'vector' || !image?.type ">
                                                            <span  date-title="Add to Cart"
                                                                (click)="selectPic(image?.image_unique_id)"><mat-icon
                                                                    data-toggle="modal"
                                                                    data-target="#exclsv_rts">add_shopping_cart</mat-icon></span>
                                                           
                                                        </li>
                                                        <li class="img_dwnld ml-2" *ngIf="image?.type == 'vector'">
                                                            
                                                            <span  date-title="Add to Cart"
                                                            (click)="addToCartForVector(image?.image_unique_id)"><mat-icon>add_shopping_cart</mat-icon></span>
                                                        </li>
                                                    </ul>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div class="custom-btm overlay_div">
                                        <div class="img_actn d-flex justify-content-between align-items-center">
                                            <!-- <div class="img_usr_div d-flex align-items-center">
                                                <div class="usr_img mr-2">
                                                    <img loading="lazy" appLazyLoad
                                                    [src]="this.env.USER_PROFILE+image?.collection_data?.user_details?.profile_image"
                                                    class="img-fluid" (error)="onImgError($event)" >
                                                </div>
                                                <div class="usr_nm">
                                                    <span>By {{image?.collection_data?.user_details?.first_name ? image?.collection_data?.user_details?.first_name + image?.collection_data?.user_details?.last_name: 'N/A'}}</span>
                                                </div>
                                            </div> -->
                                            <span class="font_16">{{image?.image_unique_id}}</span>
                                            <!-- <a class="btn btn_optn bg-white"
                                                (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark)">
                                                <span><i class="fas fa-angle-double-down mr-2"></i></span>
                                                <span>Try</span>
                                            </a> -->
                                             <div class="font_16">
                                                <span title="Try" (click)="downloadImage(env.IMAGE_COVER_WATERMARK+image?.cover_image?.with_watermark,image?.image_unique_id)"><i class="fa fa-arrow-down"></i></span>
                                            </div> 
                                        </div>
                                    </div>
                                    
                                </div>
                                
                            </a>
                        </li>
                    </ul>
    
                </div>
                <div *ngIf="this.exploreData?.length == 0">
                    <p class="text-center pstyle mt-3">No data Found</p>
                </div>
                <div class="bg_clr" *ngIf="show">
                    <img class="loading" src="assets/images/NUBlack.png" alt="">
                </div>
                <div class="pagntn_dsn text-center mt-5 ">
                    <ul class="pagination ">
                        
                        <pagination-controls (pageChange)="pageChanged($event)" [responsive]="true"></pagination-controls>
                    </ul>
                </div>

            </div>
        </section>

        <!-- <section class="latest_uploads similar_imgs" *ngIf="this.imageDetailtags.length !=0">
            <div class="custom_container">
                <div class="sctn_hdng">
                    <h4>Related Keywords</h4>
                </div>
                <div class="row" *ngIf="this.imageDetailtags.length !=0">
                    <div class="col-lg-3 py-2" *ngFor="let tags of this.imageDetailtags">
                        <mat-checkbox class="example-margin" [value]="tags" (change)="onCheckboxChange($event)"  [checked]="getChecked(tags)">
                            {{tags | titlecase}}</mat-checkbox>
                        <!-- <div class="ml-2">
                            <ul>
                                <li *ngFor="let keywords of key.keywords">
                                    <mat-checkbox class="example-margin"  [value]="keywords" (change)="onCheckboxChange($event)"> {{keywords}}</mat-checkbox>
                                </li>
                               
                            </ul>
                        </div> 
                    </div>

                </div>
                <!-- <div class="text-center" *ngIf="this.imageDetailtags.length == 0">
                    <h3> No Similar Keywords Found</h3>
                </div> -->
                <!-- <div class="text-center">
                    <button class="btn btn_theme_filled " (click)="viewall('Tags','SimilarTags')"
                        [disabled]="this.filterKeywords.length==0">Search Keywords</button>
                </div> 
            </div>
        </section> -->
    </div>

    <app-footer></app-footer>
</div>

<div class="modal fade payout_modllp" id="exclsv_rts" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
            <div class="modal-header align-items-center justify-content-end">
                <!-- <h5 class="modal-title" id="exampleModalLabel">
                    Buy Exclusive Rights
                </h5> -->
                <button #refModal type="button" class="btn close_modl" data-dismiss="modal">
                    Close
                </button>
            </div>
            <div class="modal-body">
                <div class="payoutmodl_wrap">
                    <div class="address_form grey_form">
                        <div class="sngl_img_info">
                            <div class="pricing_card text-center sngl_prcng_crd imgs_paycard">
                                <div class="plan_cntnt">
                                    <div class="pln_nm">
                                        <h4>Single Image Pricing</h4>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur
                                            adipisicing elit, sed do eiusmod tempor
                                            incididunt.
                                        </p>
                                    </div>
                                    <div class="snglimg_prcng">
                                        <div class="cart_tabl table_sizess cntct_form p-0 table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">
                                                            Image Type
                                                        </th>
                                                        <th scope="col">
                                                            Resolution
                                                        </th>
                                                        <th scope="col">
                                                            Dimension (Pixels)
                                                        </th>
                                                        <th scope="col">
                                                            Size (Inches)
                                                        </th>
                                                        <th scope="col">
                                                            Price (Rs.)*
                                                        </th>
                                                        <th scope="col">
                                                            File Format
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let list of pricingdata;">
                                                        <td>
                                                            <mat-radio-button [value]="list?._id"
                                                                (change)="getSize($event)"></mat-radio-button>
                                                        </td>
                                                        <!-- <td *ngIf="list?.image_type?.includes('extra_large')">Extre Large</td> -->
                                                        <td>{{list?.image_type | titlecase}}</td>
                                                        <td>{{list?.resolution}}</td>
                                                        <td>{{list?.size}}</td>
                                                        <td>{{list?.size_inches}}</td>
                                                        <td>{{list?.price}}</td>
                                                        <td>{{list?.format}}</td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-center">
                <button type="button" class="btn btn_theme_filled" (click)="addToCart()">Buy</button>
            </div>
        </div>
    </div>
</div>