import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-event-form',
  templateUrl: './event-form.component.html',
  styleUrls: ['./event-form.component.scss']
})
export class EventFormComponent implements OnInit {
  files: File[] = [];//drag and dropzone.
  applyEventForm: FormGroup//form
  mimeType: any

  constructor(private formBuilder: FormBuilder,
    private service: HttpService, private route: ActivatedRoute) {
      this.routeID = this.route.snapshot.paramMap.get('id');
    this.applyEventForm = this.formBuilder.group({
      first_name: [null, Validators.required, Validators.pattern('^[a-zA-Z \-\']+')],
      last_name: [null, Validators.required, Validators.pattern('^[a-zA-Z \-\']+')],
      company_name: [null, Validators.required],
      country: [null, Validators.required],
      city_town: [null, Validators.required],
      address: [null, Validators.required],
      postcode: [null, Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phone: [null, Validators.compose([Validators.required, Validators.minLength(7), Validators.maxLength(16), Validators.pattern('^[0-9+]*')])],
    
    })
  }
  routeID:any=''
  ngOnInit(): void {
    
  }
  // dropzone-----------
  onSelect(event: any) {
    if (event) {
      for (var i = 0; i < event.addedFiles.length; i++) {
        if (event.addedFiles[i].size > 500000) {
          this.service.showErrorMessage({
            message: "'Please  upload files less than 5MB', 'close'",
          })
        }
        else if (this.files.length >=4) {
          this.service.showErrorMessage({
            message: "'Please upload files less than only 4 files', 'close'",
          })
        } else {
          this.files.push(event.addedFiles[i])
          this.mimeType = event.addedFiles[i].type.slice(0, 5)
        }
      }
    }
  }

  onRemove(event: any) {
    this.files.splice(this.files.indexOf(event), 1);
  }


  // event submit ------
  eventSubmit() {
    if (this.applyEventForm.invalid) {
      this.applyEventForm.markAllAsTouched()
      this.service.showErrorMessage({
        message: 'Please fill all the required fields '
      })
    } else {
      const obj={
        ...this.applyEventForm,id:this.routeID
      }
      this.service.addEvent(obj).subscribe((res:any)=>{
        this.service.showSuccessMessage({message:'Sent Successfully'})
        this.applyEventForm.reset()
      },(err:any)=> this.service
      .showErrorMessage({message:err}))
    }
  }

}

