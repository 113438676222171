import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SEOService {

  constructor(private meta: Meta, private title: Title) {}

  updateTitle(title: string) {
    this.title.setTitle(title);
  }

  updateDescription(desc: string) {
    this.meta.updateTag({ name: 'description', content: desc })
  }

  updateKeywords(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }
  updateCustomeHeader(keywords: string) {
    this.meta.updateTag({ name: 'keywords', content: keywords })
  }


  updateOgTags(content:any) {
    this.meta.updateTag({ property: 'og:title', content: content.Title });
    this.meta.updateTag({ property: 'og:description', content: content.Description });
    this.meta.updateTag({ property: 'og:image', content: content.Image });
    this.meta.updateTag({ property: 'og:site_name', content: content.SiteName });
    this.meta.updateTag({ property: 'og:type', content:"website" });
  }
  
}
