import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ResponseCheckout, ResponsePricing } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  getBillingData: any = '';
  addBillingAddress: FormGroup;
  cartdetail: any[] = [];
  env = environment;
  pricingdata: any[] = [];
  totalamount: any;
  amount: any;
  couponCode: any;
  additionalInformation: any = '';
  cartlength: any;
  gst:any
  has_gst:any
  session: any
  countries:any
  cities:any
  selectedName:any
  user:any
  c:any
  subscriptionId = ''
  planObj: any;
  // amount:any
  constructor(private fb: FormBuilder, private router: Router,
    private service: HttpService, private route: ActivatedRoute) {
    this.addBillingAddress = this.fb.group({
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      company_name: [''],
      country: ['', [Validators.required]],
      street_address: ['', [Validators.required]],
      street_address_2: [''],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      zip: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(2), Validators.maxLength(6)]],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      gstNo: ['']
    })
    this.session = this.service.getCookie('_session');
    const id = localStorage.getItem('user_details');
    this.route.params.subscribe(params => {
      this.subscriptionId = params['subscriptionId'];
      const plan: any = localStorage.getItem('planDetails');
      this.planObj = JSON.parse(plan);
    });

    if (id != null) {
      this.user = JSON.parse(id)
    }
    
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    if (this.service.loggedUserDetails()) {
      this.getBillingAddress()
    }
    
    this.getCart()
    this.getPricingFxn()
    
  }
 

  getBillingAddress() {
    this.service.GetbillingAddress().subscribe((res: any) => {
      if (res?.data[0]) {
        this.getBillingData = res?.data[0]
      } else {
        this.getBillingData = ''
      }

       this.c = this.getBillingData?.country
       this.getCountries()
      

      this.addBillingAddress.patchValue({
        first_name: this.getBillingData?.first_name,
        last_name: this.getBillingData?.last_name,
        company_name: this.getBillingData?.company_name,
        country: this.getBillingData?.country,
        street_address: this.getBillingData?.street_address,
        street_address_2: this.getBillingData?.street_address_2,
        city: this.getBillingData?.city,
        state: this.getBillingData?.state,
        zip: this.getBillingData?.zip,
        phone: this.getBillingData?.phone,
        email: this.getBillingData?.email,
        gstNo: this.getBillingData?.gstNo
      })
      this.has_gst = this.getBillingData?.has_gst
    })
  }
  GSTAMOUNT:any
  getCart() {
    this.service.getCart().subscribe((Res: any) => {
      this.GSTAMOUNT=Res?.amount
      this.cartdetail = Res.data
      this.cartlength = Res.data.length
      this.eventEmit(this.cartlength)
     
    })

  }

  onCheck(e:any){
    this.has_gst = e.target.checked
    if(e.target.checked){
      this.gst = true
      this.addBillingAddress.get('company_name')?.addValidators([Validators.required])
      this.addBillingAddress.get('gstNo')?.addValidators([Validators.required])
    }
    if(!e.target.checked){
      this.gst = false
      this.addBillingAddress.get('company_name')?.removeValidators([Validators.required])
      this.addBillingAddress.get('gstNo')?.removeValidators([Validators.required])
    }
  }

  fixNumber(amount:any ){
    return amount?.toFixed(2);
  }

  deleteItem(id: any) {
    const obj = {
      cart_id: id
    }
    this.service.deleteCartItem(obj).subscribe((Res: any) => {
      this.getCart()

    })

  }

  eventEmit(res: any) {
    this.service.updateCartViaNumberApi((JSON.stringify(res)))
  }

  getPricingFxn() {
    this.service.getPricing().subscribe((res: ResponsePricing) => {
      this.pricingdata = res.data
    })
  }

  placeOrder() {
    if (!this.service.getToken()) {

      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      return
    }
    if (this.cartlength == 0) {
      this.service.showErrorMessage({ message: 'Your cart is empty' })
      return
    }
    else if (!this.addBillingAddress.valid) {
      this.addBillingAddress.markAllAsTouched()
      // this.service.showErrorMessage({
        // message: "Please Size First"
      // })
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }   
     }
    else if (this.getBillingData == '') {
      const data = {
        ...this.addBillingAddress.value,
        type: "billing",
        has_gst: this.has_gst

      }
      this.service.addBillingAddress(data).subscribe((res) => {
        if (res.code == 200) {
          if (this.subscriptionId)
          this.buySubscriptions();
        else
          this.payment(res.data._id, this.additionalInformation)
          //   // const obj1={
          //   //   billing_address:this.addBillingAddress.value,
          //   //   billing_address_id:res.data._id
          //   // }
          //   // this.service.placeOrder(obj1).subscribe((resp:any) => {
          //   //   this.service.showSuccessMessage({message:'order place successfully'})
          //   // this.router.navigate(['user/exclusive/right/images'])
          //  })
        }


      }, err => {
        this.service.showErrorMessage({ message: err })
      })
    } else if (this.getBillingData != '') {
      const data = {
        ...this.addBillingAddress.value,
        type: "billing",
        has_gst: this.has_gst

      }
      this.service.addBillingAddress(data).subscribe((res) => {
        if(res.code==200){
          // this.payment(res.data._id, this.additionalInformation)
        }
     
      }, err => {
        this.service.showErrorMessage({ message: err })
      })
      //   // const obj={
      //   //   billing_address:this.addBillingAddress.value,
      //   //   billing_address_id:this.getBillingData._id
      //   // }
      //   // this.service.placeOrder(obj).subscribe((resp:any) => {
      //   //   this.service.showSuccessMessage({message:'order place successfully'})
      //   // this.router.navigate(['user/exclusive/right/images'])
      //  })
    }
  }

  placeOrderPhonePe() {
    if (!this.service.getToken()) {
      const obj = {
        loginType: true
      }
      this.service.LoggedType(JSON.stringify(obj))
      return
    }
    if (this.cartlength == 0 && !this.subscriptionId) {
      this.service.showErrorMessage({ message: 'Your cart is empty' })
      return
    }
    else if (!this.addBillingAddress.valid) {
      this.addBillingAddress.markAllAsTouched()

      // this.service.showErrorMessage({
      // message: "Please Size First"
      // })
      let top = document.getElementById('top');
      if (top !== null) {
        top.scrollIntoView();
        top = null;
      }
    }
    else if (this.getBillingData == '') {
      const data = {
        ...this.addBillingAddress.value,
        type: "billing",

      }
      this.service.addBillingAddress(data).subscribe((res) => {
        if (res.code == 200) {
          if (this.subscriptionId)
          this.buySubscriptions();
        else
          this.paymentPhonePe(res.data._id, this.additionalInformation)
          //   // const obj1={
          //   //   billing_address:this.addBillingAddress.value,
          //   //   billing_address_id:res.data._id
          //   // }
          //   // this.service.placeOrder(obj1).subscribe((resp:any) => {
          //   //   this.service.showSuccessMessage({message:'order place successfully'})
          //   // this.router.navigate(['user/exclusive/right/images'])
          //  })
        }

      }, err => {
        this.service.showErrorMessage({ message: err })
      })
    } else if (this.getBillingData != '') {
      const data = {
        ...this.addBillingAddress.value,
        type: "billing",


      }
      this.service.addBillingAddress(data).subscribe((res) => {
        if (res.code == 200) {
          if (this.subscriptionId)
          this.buySubscriptions();
        else
          this.paymentPhonePe(res.data._id, this.additionalInformation)
        }


      }, err => {
        this.service.showErrorMessage({ message: err })
      })
      //   // const obj={
      //   //   billing_address:this.addBillingAddress.value,
      //   //   billing_address_id:this.getBillingData._id
      //   // }
      //   // this.service.placeOrder(obj).subscribe((resp:any) => {
      //   //   this.service.showSuccessMessage({message:'order place successfully'})
      //   // this.router.navigate(['user/exclusive/right/images'])
      //  })
    }
  }



  payment(id: any, data: any) {
    const obj = {
      billing_address_id: id,
      additional_info: data,
    }

    this.service.payment(obj).subscribe((Res) => {
      if (Res.code == 200) {
        // window.location.href = Res.url
      }

    })
  }

  paymentPhonePe(id: any, data: any) {
    const obj = {
      billing_address_id: id,
      additional_info: data,
      // ref_id:this.cartdetail[0]._id,
      user_id: this.user._id,
      session_id: this.session,
      amount: this.GSTAMOUNT.total*100
    }
    this.service.getLink(obj).subscribe((Res) => {
      if (Res.code == 200) {
        window.location.href = Res.url
      }

    })
  }   

  getCountries(){
    this.service.getCountries().subscribe((res)=>{
      this.countries = res.data
      
        let d = this.countries.find((co:any)=>co.name == this.c)?.isoCode
      this.getStates(d)
      
    })
  }

  countrySelected(e:any){
     this.selectedName = this.addBillingAddress.get('country')?.value
     let code = this.countries.find((c: any) => c.name === this.selectedName)?.isoCode;
    this.getStates(code)
  }

  getStates(code:any){
    const data = {
      country_code: code
    }
    this.service.getStates(data).subscribe((res)=>{
      this.cities = res.data
    })
  }


  buySubscriptions() {
    const obj = {
      planId: this.planObj.planId,
      imageType: this.planObj.imageType
    }
   this.service.buySubscription(obj).subscribe((res: any) => {
      if (res.code == 200) {
        window.open(res.url)
      }
    }, (err: any) => {

      this.service.showErrorMessage({
        message: err
      })
    })
}

}











// if (this.cartdetail.length > 0) {

     
//   this.amount =  this.cartdetail.map((res)=>{
//     if(res.type=='image'){
//       return res?.available_size?.price 
//     }else{
//       return res?.collection_images?.price
//     }
//   })


//   this.totalamount = this.amount?.reduce((previousValue: any, currentValue: any) => previousValue + currentValue)
// }
// else {
//   this.totalamount = 0
// }
