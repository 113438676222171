<div class="main_home_wraper">
	
	<app-header-cmn></app-header-cmn>

	<div class="inner_wrap_sectn">
		<section class="contact_top">
			<div class="custom_container">
				<div class="contactus_wrap">
					<div class="pg_hdng">
						<!-- <h1>Terms of Use</h1> -->
						<div class="pg_brdcrmb">
							<nav aria-label="breadcrumb">
							  <ol class="breadcrumb">
							    <li class="breadcrumb-item"><a href="#">Home</a></li>
							    <li class="breadcrumb-item active" aria-current="page">{{termOfServicedata[0]?.heading}}</li>
							  </ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		</section>

		<!-- <section class="contact_us privacy_policy">
			<div class="custom_container"  *ngFor="let x of termOfServicedata">
				<div class="policy_wrap">
					<h1 [innerHtml]="x.heading"></h1>
					<p [innerHtml]="x.description"> </p>
					
				</div>
			</div>
		</section> -->
		<section>
			<div class="custom_container"  *ngFor="let x of termOfServicedata">
				<div class="policy_wrap">
					<h1 [innerHtml]="x.heading"></h1>
					  <p>Your use of this Web site (https://nustockimages.com/) means you agree to the following terms
						of use (the &quot;Terms of Use&quot;). If you do not agree with these terms you should leave this Web site
						(the &quot;Site&quot;) immediately, not use the files herein, and/or not subscribe to this service.</p>
						<p>PLEASE READ THESE TERMS AND CONDITIONS OF USE (HEREINAFTER
							REFERRED TO AS &quot;TOU&quot;) CAREFULLY. THIS AGREEMENT GOVERNS YOUR USE
							OF https://nustockimages.com/ (HEREINAFTER REFERRED TO AS &quot;WEBSITE&quot;). YOUR
							USE OF THIS WEB SITE (https://nustockimages.com/) MEANS YOU AGREE TO THE
							FOLLOWING TERMS OF CONDITIONS OF USE (THE &quot;TERMS OF USE&quot;) DESCRIBED
							HEREIN AND BY ALL TERMS, POLICIES AND GUIDELINES INCORPORATED BY
							REFERENCE. IF YOU DO NOT AGREE TO ALL OR ANY OF THESE TERMS AND
							CONDITIONS, DO NOT USE THIS WEBSITE, LEAVE THIS WEBSITE IMMEDIATELY,
							DO NOT USE THE FILES HEREIN, AND/OR DO NOT SUBSCRIBE TO THIS SERVICE.
							By using this website, you represent and warrant that you are over the age of 18 and are lawfully
							able to accept this Website&#39;s terms of conditions of use. If you are using the Website on behalf of
							any entity, you further represent and warrant that you are authorized to accept these Website
							terms and conditions on such entity&#39;s behalf, and that such entity agrees to indemnify NU Stock
							Images for violations of these Website terms and conditions.
							NU Stock Images may revise and update these TOU at any time and without notice. Your
							continued access or use of this website after any such changes are posted will constitute your
							acceptance of these changes and you would be bound by the amended TOU.</p>
					
				</div>
			</div>
			<div class="container mt-5">
				<div class="row">
					<div class="col-lg-12">
						<mat-accordion>
							<mat-expansion-panel class="mb-3">
							  <mat-expansion-panel-header>
								<mat-panel-title>
									1. OWNERSHIP OF THE SITE AND ITS CONTENTS- THIS SITE IS OWNED, HOSTEDAND OPERATED BY &quot;NU Stock Images”
								</mat-panel-title>
							  </mat-expansion-panel-header>
							  <p>Unless otherwise indicated, all the Content
								featured or displayed on the Website, including, but not limited to, photographic images, text,
								graphics, the layout of the Website, sound, illustrations, software, trade dress, trademarks,
								patents, and the selection and arrangement thereof (&quot;NU Stock Images Content&quot;), is owned by
								NU Stock Images, its affiliates, subsidiaries, its licensors, contributors, or its third-party image
								partners.</p>
							</mat-expansion-panel>
			
							<mat-expansion-panel>
							  <mat-expansion-panel-header>
								<mat-panel-title>
									2. USE OF THE WEBSITE-
								</mat-panel-title>
							  </mat-expansion-panel-header>
							  <p>This Website and NU Stock Images Content are intended for
								customers of NU Stock Images. You may not use this Website or the NU Stock Images Content
								for any purpose not related to your business with NU Stock Images&#39; Content. You are specifically
								prohibited from (a) downloading, copying, or re-transmitting any or all of the Website or NU</p>
								<p>Stock Images Content without, or in violation of, a written license or agreement with NU Stock
									Images; (b) using any data mining, robots or similar data gathering or extraction methods; (c)
									manipulating or otherwise displaying the Website or the NU Stock Images Content by using
									framing, linking, meta-tag or similar navigational technology; (d) registering, subscribing,
									unsubscribing, or attempting to register, subscribe, or unsubscribe any party for any NU Stock
									Images Content product or service if you are not expressly authorized by such party to do so; (e)
									using the Website or the NU Stock Images Content other than for its intended purpose. Such
									unauthorized use may also violate applicable laws including without limitation copyright and
									trademark laws, publicity and privacy laws, moral rights, and applicable communications regulations
									and statutes.</p>
									<p>You represent and warrant that you will comply with all applicable laws and regulations, including,
										without limitation, those relating to the Internet, data, e-mail, copyright, trademarks, patent,
										privacy, and the transmission of technical data exported from India or the country in which you
										reside. You further warrant that you will not interfere with the security of, or otherwise abuse
										this Website or any system resources, services or networks connected to or accessible through
										this Website. You are allowed to use this Website only for lawful purposes and activities.</p>
							</mat-expansion-panel>

							<mat-expansion-panel class="mt-3">
								<mat-expansion-panel-header>
								  <mat-panel-title>
									3. REGISTRATION DATA AND ACCOUNT SECURITY-
								  </mat-panel-title>
								</mat-expansion-panel-header>
								<p>In consideration of your use of the
									Website, you agree to (a) provide correct, accurate, current and complete information about you
									as may be prompted by any registration forms on the Website (&quot;Registration Data&quot;); (b) maintain
									and promptly update the Registration Data, and any other information you provide to NU Stock
									Images, to keep it accurate, current and complete; (c) maintain the security of your password and
									identification; (d) notify NU Stock Images immediately of any unauthorized use of your account or
									another breach of security; (e) accept all responsibility for any and all activities from your account,
									and (f) accept all risks of unauthorized access to the Registration Data and any other information
									you provide to NU Stock Images.</p>
								</mat-expansion-panel>

								<mat-expansion-panel class="mt-3">
									<mat-expansion-panel-header>
									  <mat-panel-title>
										4. INTELLECTUAL PROPERTY RIGHTS - 
									  </mat-panel-title>
									</mat-expansion-panel-header>
									<p>NU Stock Images is the sole owner and lawful
										licensee of all the rights to the Website and its Content. All materials on this Website, including,
										but not limited to design, layout, images, text, graphics, sound, video, trade dress, trademarks, and
										patents (NU Stock Images Content) are the property of NU Stock Images and are protected by
										all the applicable laws, including, but not limited to copyright, trademark, trade-names, patents,
										internet domain names, data protection, IT Act, privacy and publicity rights and other similar rights
										and statutes. Use of any NU Stock Images Content without the express written permission and
										license to use is strictly prohibited. All title, ownership and intellectual property rights in the
										Website and its Content shall remain with NU Stock Images, its affiliates or licensors, as the case
										may be and does not pass on to you or your representatives.</p>
										<p>COPYRIGHT- NU Stock Images, its licensors, its image partners and contributors, as the case
											may be, own all copyrights in the represented images on the Website. No ownership in any
											represented images shall be to anyone accessing this Website and using the Content. You shall
											not remove any copyright or other proprietary rights notice contained in the represented Images,
											or any related promotional materials provided on this Website. NU Stock Images prohibits any
											Content or any other material that infringes on any copyright, trademark, patent, trade secret,
											right to privacy, right to publicity, or any other applicable law or proprietary right to be uploaded
											to the Website.</p>
											<p>NU Stock Images respects the intellectual property rights of others, and we expect our user(s) to
												do the same. NU Stock Images is not an expert in your intellectual property rights, and we cannot
												verify that the users and contributors to the Website have rights to the Content posted by them.
												If you believe that any material on the Website infringes upon any copyright that you own or
												control, you may file a notification of such infringement with our legal department as set forth
												below:</p>
												<p>Email: NU Stock Images may give notice of a claim of copyright infringement by means of a general
													notice on the Website, or electronic mail to a user&#39;s e-mail address and may also remove the
													infringing content uploaded by the user. In the event, NU Stock Images is not removing the said
													Content; NU Stock Images is not endorsing the sale of any such content.</p>
													<p>
														TRADEMARKS- NU Stock Images is the exclusive owner and holder of NU Stock Images
														Trademarks, https://nustockimages.com/, NU Stock Images’ trade names, trademarks, logos or
														service marks and any other slogan or design contained in the Website and otherwise used in
														trade (&#39;&#39;NU Stock Images Marks&#39;&#39;). NU Stock Images Marks shall remain the sole property of NU
														Stock Images, its affiliates and subsidiaries, and may not be copied, imitated or used, in whole or in
														part, without the prior written permission of NU Stock Images or the applicable trademark holder.
														You are not allowed to use, register, or assist others in using or registering any trademarks, trade
														names, logos, internet domain names, or any mark or name confusingly similar to NU Stock Images
														Marks without NU Stock Images&#39; prior written consent. You will not now or in the future contest
														the validity of NU Stock Images Marks nor will oppose or assist others in opposing any
														registration of any of the NU Stock Images Marks. You are not allowed to use any NU Stock
														Images Marks in connection with any of your Works. All goodwill accruing to the NU Stock
														Images Marks shall belong to NU Stock Images. You agree not to use NU Stock Images Marks in
														any manner that might tarnish, disparage, or reflect adversely on such trademarks or NU Stock
														Images. You agree not to use any NU Stock Images Marks or any variant thereof including
														misspellings as a domain name or as part of a domain name regardless of the top-level domain, or
														as a meta-tag, hidden text, keyword, or any other type of programming code or data. You cannot
														at any time, adopt or use, without NU Stock Images&#39; prior written consent any word or mark which
														is similar to or likely to be confused with NU Stock Images Marks. In addition, the look and feel of
														the Website, including all page headers, custom graphics, button icons and scripts, is the service
														mark, trademark and/or trade dress of NU Stock Images and may not be copied, imitated or used,
														in whole or in part, without NU Stock Images&#39; prior written permission. All other trademarks,
														registered trademarks, product names and company names or logos mentioned in the Website are
														the property of their respective owners. Reference to any products, services, processes or other
														information, by trade name, trademark, manufacturer, supplier, or otherwise does not constitute or
														imply endorsement, sponsorship or recommendation thereof by us.</p>
									</mat-expansion-panel>

									<mat-expansion-panel class="mt-3">
										<mat-expansion-panel-header>
										  <mat-panel-title>
											5. LINKS-
										  </mat-panel-title>
										</mat-expansion-panel-header>
										<p>NU Stock Images makes no claim or representation regarding and accepts no
											responsibility for, directly or indirectly, the quality, concept, nature or reliability of third-party
											websites accessible by hyperlink from the Website, or websites linking to our Website. Such sites
											are not under the control of NU Stock Images and NU Stock Images is not responsible for the
											contents of any linked site or any link contained in a linked site, or any review, changes or updates
											to such sites. NU Stock Images provides these links to you only as a convenience, and the
											inclusion of any link does not imply affiliation, endorsement or adoption by NU Stock Images of any
											site or any information contained therein. When you leave our Website, you should be aware that
											our terms and policies no longer govern. You should review the terms and policies, including
											privacy and data-gathering practices, of any site to which you navigate from the Website.
											Your participation, correspondence or business dealings with any third party found on or through
											the Website, regarding the payment and delivery of related goods and services, and any other
											terms, conditions, warranties or representations associated with such dealings, are solely between
											you and such third party, and you agree that NU Stock Images shall not be responsible or liable for
											any loss, damage or other matters of any sort incurred as the result of any such dealings.</p>
										</mat-expansion-panel>

										<mat-expansion-panel class="mt-3">
											<mat-expansion-panel-header>
											  <mat-panel-title>
												6. USER CONTENT-
											  </mat-panel-title>
											</mat-expansion-panel-header>
											<p>This Website may contain photographic images and content uploaded
												(&quot;User Content&quot;) in which you or third parties create, post or store any content, messages,
												comments, materials or other items on the Website. You are solely responsible for your use of
												such User Content and use them at your own risk. By posting any Content on the Website, you
												agree not to post, upload, transmit, distribute, store, create or otherwise publish through the
												Website any of the following:
												User Content that would infringe any copyright, trademark, patent, trade secret or other
												intellectual property and proprietary rights or contract rights of any party. By posting any User
												Content, you represent and warrant that you have the lawful right to transmit, distribute and
												reproduce such User Content;</p>
												<p>Any message, comment, data, information, photographic images, sound, text or other material that
													is unlawful, libelous, defamatory, obscene, pornographic, harmful to minors, indecent, lewd,
													suggestive, harassing, threatening, invasive of privacy or publicity rights, abusive, inflammatory,
													fraudulent or other objectionable;
													User Content that would constitute, encourage or provide instructions for a criminal offence,
													violate the rights of any party, or that would otherwise create liability or violate any local, state,
													national or international law;
													User Content that impersonates any person or entity or otherwise misrepresents your affiliation
													with a person or entity; Unsolicited promotions, political campaigning, advertising, junk mail, spam,
													chain letters;</p>
													<p>Private information of any third party, including, without limitation, addresses, phone numbers,
														email addresses and credit card numbers;
														Viruses, corrupted data or other harmful, disruptive or destructive files; and User Content that, in
														the sole judgment of NU Stock Images, is objectionable or which restricts or inhibits any person
														from using the Website or which may expose NU Stock Images or its users to any harm or liability
														of any type. Not to delete or revise any User Content posted by any third party.</p>
														<p>NU Stock Images takes no responsibility and assumes no liability for any User Content posted,
															stored or uploaded by you or any third party, or for any loss or damage thereto, nor is NU Stock
															Images liable for any mistakes, defamation, slander, libel, omissions, falsehoods, obscenity,
															pornographic you may encounter. Your use of the User Content is entirely at your own risk. As a
															provider of User Content, NU Stock Images is not liable for any statements, representations and
															User Content provided by its users. Although NU Stock Images has no obligation to screen, edit
															or monitor any of the User Content posted on the Website, NU Stock Images reserves the right
															and in its absolute discretion can remove, screen or edit any User Content posted on the Website
															at any time and for any reason without notice. You are solely responsible for creating backup
															copies of the User Content posted by you on the Website at your sole cost and expense. Any use
															of the Website or other provisions of the Website in violation of the foregoing violates these
															Terms and may result, among other things, termination and suspension of your account and your
															rights to use the Website. You acknowledge and agree that NU Stock Images may access, use or
															disclose any information about you or your use of this Website, including, without limitation, any
															User Content, personal information and other information required to comply with the law or any
															legal process; protect and defend the rights or property of NU Stock Images; or protect the
															safety of NU Stock Images, its affiliates, parent, their respective directors, employees, officers,
															customers or the public.</p>
															<p>If you post User Content to the Website, unless we indicate otherwise, you grant NU Stock
																Images and its affiliates and subsidiaries, a non-exclusive, royalty-free, perpetual, irrevocable and
																fully sub-licensable right to use, distribute, perform and publish, translate, create derivative works
																from, distribute, perform and display such User Content throughout the world in any media. You
																grant NU Stock Images, its affiliates and subsidiaries and sub-licensees the right to use the name
																that you submit in connection with such Content if they choose.</p>
																<p>If you post User Content to the Site, you represent and warrant that (a) you own and control all of
																	the rights to the User Content that you post or you otherwise have the right to post such User
																	Content to the Site; (b) the User Content is accurate and not misleading; and (c) use and posting
																	of the User Content you supply does not violate these Site Terms and will not violate any rights
																	of or cause injury to any person or entity.</p>
																	<p>NU Stock Images acts as a content integrator and is not responsible for the information and
																		Content provided by User(s) to be displayed on the Website. NU Stock Images does not have any
																		role in the development of the User Content.</p>
											</mat-expansion-panel>

											<mat-expansion-panel class="mt-3">
												<mat-expansion-panel-header>
												  <mat-panel-title>
													7. LIMITATION OF LIABILITY AND DISCLAIMER-
												  </mat-panel-title>
												</mat-expansion-panel-header>
												<p>This website and NU Stock Images
													content are provided &quot;as is&quot; and NU Stock Images and its directors, employees, content providers,
													agents and affiliates exclude, to the fullest extent permitted by applicable law, any warranty,
													express or implied, including, without limitation, any implied warranties of merchantability,
													satisfactory quality or fitness for a particular purpose. NU Stock Images will not be liable for any
													damages of any kind arising from the use of this website or NU Stock Images content, or the
													unavailability of the same, including, but not limited to lost profits, and direct, indirect, incidental,
													punitive and consequential damages. The functions embodied on or in the materials of this website
													are not warranted to be uninterrupted or without error. You, not NU Stock Images, assume the
													entire cost of all necessary servicing, repair or correction due to your use of this website or the
													NU Stock Images content. NU Stock Images makes no warranty that the website or content is
													free from infection by viruses or anything else that has contaminating or destructive properties.
													NU Stock Images uses reasonable efforts to ensure the accuracy, correctness and reliability of
													the information and content, but NU Stock Images makes no representations or warranties as to
													the content posted by the user(s) as to its accuracy, correctness, reliability and any other
													irregularity.</p>
													<p>
														In no event shall NU Stock Images, its affiliates, subsidiaries and their respective directors,
														employees, officers, members or agents be liable for any direct, special, indirect or consequential
														damages, or any other damages of any kind, including but not limited to loss of use, loss of profits
														or loss of data, whether in an action in contract, tort (including, but not limited to negligence) or
														otherwise, arising out of or in any way connected with the use of the website, the services, NU
														Stock Images image content or the materials contained in or accesses through the website,
														including without limitation any damages caused by or resulting from reliance by user on any
														information obtained from NU Stock Images, or that result from mistakes, omissions,
														interruptions, deletion of files or email, errors, defects, viruses, delays in operation or transmission
														or any failure of performance, whether or not resulting from acts of god, communications failure,
														theft, destruction or unauthorized access to NU Stock Images&#39; records, programs or services. In
														no event shall the aggregate liability of NU Stock Images, whether in contract, warranty, tort
														(including negligence, whether active, passive or imputed), product liability, strict liability or other
														theory, arising out of or relating to the use of the site exceed any compensation you pay, if any, to
														NU Stock Images for access to or use of the website.
														Some jurisdictions do not permit the exclusion or limitation of implied warranties or liability for
														certain categories of damages. therefore, some or all of the limitations above may not apply to you
														to the extent they are prohibited or superseded by state or national provisions.</p>
												</mat-expansion-panel>

												<mat-expansion-panel class="mt-3">
													<mat-expansion-panel-header>
													  <mat-panel-title>
														8. INDEMNIFICATION-
													  </mat-panel-title>
													</mat-expansion-panel-header>
													<p>You agree to indemnify and hold NU Stock Images, its affiliates,
														subsidiaries and their respective directors, employees, officers and agents harmless against all
														claims or liability asserted against NU Stock Images arising out of or in connection with any
														breach by you or anyone acting on your behalf of any of the Terms and Conditions of this
														Agreement. You shall defend, indemnify and hold NU Stock Images, its affiliates, subsidiaries and
														their respective directors, employees, agents and officers harmless from all damages, liabilities and
														expenses (including reasonable attorney&#39;s fees and permitted and authorized costs) arising out of
														breach of this Agreement as a result of claims by third parties relating to your use and act relating
														to this Agreement or any breach of this Agreement. Such indemnification is in addition to NU
														Stock Images&#39; right to terminate for a breach of the representations and warranties and is not a
														waiver or limitation of NU Stock Images&#39; other rights or remedies.</p>
													</mat-expansion-panel>

													<mat-expansion-panel class="mt-3">
														<mat-expansion-panel-header>
														  <mat-panel-title>
															9. GOVERNING LAW AND JURISDICTION-
														  </mat-panel-title>
														</mat-expansion-panel-header>
														<p>Any dispute, violation, controversy, contest or
															claim arising out of or relating to this Agreement or the breach, termination or validity thereof
															(hereinafter the &quot;Disputes&quot;) shall be resolved amicably in the first instance. The Parties agree to
															meet to resolve such dispute in good faith. Should such a solution not be reached, within [30] days
															from the date of commencement of the dispute, difference or disagreement or such longer period
															of time as may be mutually agreed by the Parties in writing, such dispute, difference or
															disagreement shall be referred to Arbitration and shall be finally resolved through arbitration
															conducted by a tribunal of three arbitrators, with the right of each party to appoint an arbitrator,
															who in turn will appoint a Presiding Arbitrator. The Presiding Arbitrator shall pass a speaking
															award. Any order/directions/awards of the majority of the tribunal shall be final and binding on
															both parties. The arbitration proceedings shall be in accordance with the Indian Arbitration and
															Conciliation Act 1996 and the Rules there under as at present in force. The seat of arbitration
															shall be Delhi, India. The arbitration shall be administered in accordance with its practice, rules
															and regulations. If the award passed in the Arbitration proceeding is not acceptable to any of the
															parties, then the Party is free to approach the appropriate court of law as agreed herein below.
															This Agreement shall be construed in accordance with and governed by the laws of India without
															giving effect to their conflict of laws principles or rules. The courts at Delhi, India shall have
															exclusive Jurisdiction.</p>
														</mat-expansion-panel>

														<mat-expansion-panel class="mt-3">
															<mat-expansion-panel-header>
															  <mat-panel-title>
																10. TERMINATION-
															  </mat-panel-title>
															</mat-expansion-panel-header>
															<p>Notwithstanding any of these Website terms and conditions, NU Stock
																Images reserves the right, without notice in its sole discretion, to terminate your account and/or
																block your use of the Website. Upon termination, you must destroy and cease to use all materials
																obtained from this Website, copies and related documentation thereof with immediate effect.</p>
															</mat-expansion-panel>

															<mat-expansion-panel class="mt-3">
																<mat-expansion-panel-header>
																  <mat-panel-title>
																	11. REMEDY FOR BREACH-
																  </mat-panel-title>
																</mat-expansion-panel-header>
																<p>You agree that any breach of this Agreement by you or your
																	representatives would cause NU Stock Images irreparable harm, injury, loss and damage, the
																	extent of which would be impossible to ascertain and for which monetary damages would not be an
																	adequate remedy. Accordingly, in addition to any other remedies NU Stock Images may have at
																	law or in equity, NU Stock Images shall be entitled to injunctive and other equitable relief in the
																	event of any such breach of this Agreement by you or your representatives.</p>
																</mat-expansion-panel>

																<mat-expansion-panel class="mt-3">
																	<mat-expansion-panel-header>
																	  <mat-panel-title>
																		12. SEVERABILITY-
																	  </mat-panel-title>
																	</mat-expansion-panel-header>
																	<p>If one or more of the provisions contained in this Agreement is found to be
																		invalid, illegal or enforceable in any respect, the validity, legality and enforceability of the remaining
																		provisions shall not be affected. Such provisions shall be revised only to the extent necessary to
																		make them enforceable.</p>
																	</mat-expansion-panel>

																	<mat-expansion-panel class="mt-3">
																		<mat-expansion-panel-header>
																		  <mat-panel-title>
																			13. WAIVER-
																		  </mat-panel-title>
																		</mat-expansion-panel-header>
																		<p>No action of NU Stock Images, other than express written waiver, may be
																			construed as a waiver of any provision of this Agreement. A delay on the part of NU Stock Images
																			of its rights or remedies will not operate as a waiver of such rights or remedies, and a single or
																			partial exercise by a party of any such rights or remedies will not preclude other or further
																			exercise of that right or remedy. A waiver of a right or remedy on any occasion will not be
																			construed as a bar to or waiver of rights or remedies on any other occasion. Any rights not
																			expressly granted herein are reserved.</p>
																		</mat-expansion-panel>

																		<mat-expansion-panel class="mt-3">
																			<mat-expansion-panel-header>
																			  <mat-panel-title>
																				14. NU Stock Images RESERVES-
																			  </mat-panel-title>
																			</mat-expansion-panel-header>
																			<p>NU Stock Images reserves the right to change any terms and
																				conditions contained in this Agreement or any policy or guideline of the Website, at any time and in
																				its sole discretion. Your continued use of the Website following the posting of such changes will
																				constitute your acceptance of such changes.</p>
																			</mat-expansion-panel>

																			<mat-expansion-panel class="mt-3">
																				<mat-expansion-panel-header>
																				  <mat-panel-title>
																					15. CANCELLATION POLICY-
																				  </mat-panel-title>
																				</mat-expansion-panel-header>
																				<p>NU Stock Images believes in helping its customers as far as
																					possible, and has, therefore, a liberal cancellation policy. Under this policy:
																					In case you feel that the product received is not as shown on the site or as per your expectations,
																					you must bring it to the notice of our customer service within 24 hours of receiving the product.
																					The Customer Service Team after looking into your complaint will take an appropriate decision.</p>
																					<p>PRIVACY POLICY
																						The online Registration Form asks visitors for demographic information (like their PIN code or
																						Telephone No.) . Demographic and profile data is also collected at the site. We use this data to
																						tailor our visitor&#39;s experience and services at our site, showing them content that we think they
																						might be interested in, and displaying the content according to their preferences.
																						The customer&#39;s contact information is used to contact the visitor when necessary. Users may
																						opt-out of receiving future mailings; see the choice/opt-out section below. We do not divulge the
																						visitor&#39;s information to any third party for commercial use. We may use the data for informing the
																						users of our services and offerings from time to time.</p>
																						<p>What information do you collect when I register for something?
																							NU Stock Images offers access to some services that require registration. We may use your
																							contact information to send notification to you of NU Stock Images products and services by e-
																							mail and/or postal mail. We also may share that information with our clients. Our clients may send
																							you promotional material that is related to the topic of the service for which you registered. We
																							may also use your IP address internally and share it with third parties.</p>
																							<p>What other information do you request?
																								We may also request your e-mail address or mailing address for the purpose of conducting a
																								survey or to provide additional services (for example, subscriptions to e-mail newsletters,
																								announcement lists or information about conferences and trade shows). Whenever we request the
																								identity of a visitor, we will clearly indicate the purpose of the inquiry before the information is
																								requested. We maintain a strict &quot;No-Spam&quot; policy that means that we do not sell, rent, or
																								otherwise give your e-mail address to a third-party, without your consent or as permitted by this
																								Privacy Policy. We may from time to time send e-mail announcing new NU Stock Images products
																								and services. If you choose to supply your postal address in an online form, you may receive postal
																								mailings from other third-party companies.</p>
																								<p>What information do you collect when I purchase something?
																									If you are purchasing something from a NU Stock Images media property, we and any third party
																									provider, need to know your name, e-mail address, mailing address, credit card number and
																									expiration date. This allows us, or third-party providers, to process and fulfill your order and to
																									notify you of your order status. This information may also be used by us to notify you of related
																									products and services. Credit card and e-mail information will not be shared or sold to third
																									parties for any purpose except as provided in this Privacy Policy without your express permission.
																									Wherever NU Stock Images collects personal information we make an effort to include a link to
																									this Privacy Policy on that page.</p>
																									<p>Will you disclose the information you collect to outside third parties?
																										NU Stock Images will disclose personal information and/or an IP address, when required by law or
																										in the good-faith belief that such action is necessary to:
																										1. Cooperate with the investigations of purported unlawful activities and conform to
																										the edicts of the law or comply with legal process served on NU Stock Images,
																										2. Protect and defend the rights or property of the NU Stock Images and related
																										properties, or visitors to the NU Stock Images and related properties,
																										3. Identify persons who may be violating the law, the NU Stock Images legal notice and
																										Web site User Agreement, the rights of third parties, or otherwise misusing the NU
																										Stock Images Network or its related properties,
																										4. Fulfill a user&#39;s order or request.
																										5. NU Stock Images uses reasonable precautions to keep the information disclosed to
																										us secure. NU Stock Images reserves the right to transfer all such information in
																										connection with the sale of all or part of NU Stock Images&#39; capital stock or assets.
																										Furthermore, we are not responsible for any breach of security or for any actions of
																										any third parties that receive the information. We are not responsible for such third-
																										party privacy policies or how they treat information about their users,
																										Choice Opt-Out/ Opt-in announcement List Privacy Policy
																										When you sign up for a NU Stock Images e-mail newsletter, you are given the opportunity to sign
																										up for targeted third-party mailings. Whether you want to receive these announcements is your
																										choice. You may stop the arrival of these announcement lists at any time by removing your e-mail
																										address from the list by forwarding the announcement list or by following the instructions on the
																										e-mails you receive.</p>
																										<p>By using the NU Stock Images site, you consent to the collection and use of information by NU
																											Stock Images as specified above. We reserve the right to modify this policy. If we decide to
																											change our Privacy Policy, we will post those changes on this page so that you are always aware
																											of what information we collect, how we use it, and under what circumstances we disclose it.</p>
																											<p>Please send any questions about NU Stock Images&#39; Privacy Policy to
																												https://nustockimages.com/ &quot;We as a merchant shall be under no liability whatsoever in respect
																												of any loss or damage arising directly or indirectly out of the decline of authorization for any
																												Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us
																												with our acquiring bank from time to time&quot;</p>
																				</mat-expansion-panel>
								
								
						  </mat-accordion>
					</div>
				</div>
			</div>
		</section>
	</div>
	

	<app-footer></app-footer>
</div>