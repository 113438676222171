import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { ViewChild } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';

import { Router } from '@angular/router';
// import { NgxCaptchaComponent } from '@ctrl/ngx-captcha';
declare let $:any
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

   contactUs:FormGroup
  shoot:FormGroup
  // aFormGroup:FormGroup
  content:any

  constructor(private fb:FormBuilder,private service : HttpService,private cdr: ChangeDetectorRef,private router:Router, private titleService: Title, private meta: Meta) {
    this.contactUs=this.fb.group({
      name:['',[Validators.required]],
      email: ['', [Validators.required,  Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      subject:['',[Validators.required]],
      message:['',[Validators.required]],

    })
    this.shoot=this.fb.group({
      fullname:['',[Validators.required]],
      email: ['', [Validators.required,  Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10)]],
      message:['',[Validators.required]],
      recaptcha:['',[Validators.required]]

    })
  
   }

  ngOnInit(): void {
    this.titleService.setTitle("NuStock: Contact Us")
    this.meta.updateTag({ name: 'description', content: 'Your inquiries matter. Contact us for any questions, queries or suggestions. Reach out and let’s create something extraordinary together.'}); 
    this.getContactusContent()
  }

  handleCaptchaResponse(response: any) {
    // Send the response to your server for verification
    // Implement your server-side verification logic here
  }

  handleReset() {
   
    this.shoot.get('recaptcha')?.setValue('');
  }
  submitShoot(){
    if(this.shoot.valid){
      const obj={
      fullname:this.shoot.value.fullname,
      email:this.shoot.value.email,
      phone:this.shoot.value.phone,
      message:this.shoot.value.message,
      }
      this.service.contactUsForShoot(obj).subscribe((res:any)=>{
        this.service.showSuccessMessage({message:'Sent successfully'})
        this.shoot.reset()
      
        $("#exampleModal").modal('hide');
        this.router.navigate(['/'], {
          skipLocationChange: true
        }).then(res => {
          this.router.navigate([`/contact-us`])
        })
     
      },(err)=> {
        this.service.showErrorMessage({message:err})
      })

     
    }else{
      this.shoot.markAllAsTouched()
    }
  }



  refreshComponent() {
    this.cdr.detectChanges();
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  getContactusContent(){
    this.service.getContactusContent().subscribe((resp:any) => {

      if(resp.code == 200){
        this.content=resp.data
        this.content.address = "A-155 (Basement), Sector 46, Noida 201303, Uttar Pradesh"
      }
    },(error: HttpErrorResponse) => {
      this.service.showSuccessMessage({
        message : error?.error?.errors?.msg
      })
    })
  }
  submit(){
    
    if(this.contactUs.valid){
      const obj={
        name:this.contactUs.value.name,
        email:this.contactUs.value.email,
        subject:this.contactUs.value.subject,
        message:this.contactUs.value.message,
      }
      this.service.contactUs(obj).subscribe((res:any)=>{
        this.service.showSuccessMessage({message:'sent successfully'})
        this.contactUs.reset()
      },(err)=> this.service
      .showErrorMessage({message:err}))
     
    }else{
      this.contactUs.markAllAsTouched()
    }
   

  }

}
