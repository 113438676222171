import { Component, OnInit } from '@angular/core';
import { FaqResponse } from 'src/app/models/model';
import { HttpService } from 'src/app/services/http/http.service';
import { Title,Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  FaqResponseData: any;

  constructor(private service:HttpService,private titleService: Title,private meta: Meta) { }

  ngOnInit(): void {
    this.meta.updateTag({ name: 'description', content: "Get clarity on NuStock's offerings and services. Explore our FAQ section for comprehensive answers to your queries on exceptional visual content."});
    this.meta.updateTag({ property: 'og:description', content: "Get clarity on NuStock's offerings and services. Explore our FAQ section for comprehensive answers to your queries on exceptional visual content."});
    this.titleService.setTitle("NuStock: FAQs")
    this.faq()
  }
   faq(){
    this.service.faq().subscribe((res:FaqResponse)=>{
      this.FaqResponseData=res.data
      
    })
   }
}
