import { NgModule } from "@angular/core";
import { BrowserModule, BrowserTransferStateModule } from "@angular/platform-browser";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
// import { CarouselModule } from "ngx-owl-carousel-o";
// import { MatTabsModule } from "@angular/material/tabs";
// import { MatIconModule } from "@angular/material/icon";
// import { MatExpansionModule } from "@angular/material/expansion";
// import { NgxDropzoneModule } from "ngx-dropzone";
// import { MatSelectModule } from "@angular/material/select";
// import { MatMenuModule } from "@angular/material/menu";
// import { MatChipsModule } from "@angular/material/chips";
// import { MatCheckboxModule } from "@angular/material/checkbox";
// import { MatTableModule } from "@angular/material/table";
// import { MatSortModule } from "@angular/material/sort";
// import { MatPaginatorModule } from "@angular/material/paginator";
// import { MatFormFieldModule } from "@angular/material/form-field";
// import { MatInputModule } from "@angular/material/input";
// import { MatStepperModule } from "@angular/material/stepper";
// import { MatAutocompleteModule } from "@angular/material/autocomplete";
// import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// import { HeaderComponent } from "./common/header/header.component";
// import { HomepageComponent } from "./views/homepage/homepage.component";
// import { FooterComponent } from "./common/footer/footer.component";
// import { PricingComponent } from "./views/pricing/pricing.component";
// import { ContactUsComponent } from "./views/contact-us/contact-us.component";
// import { PrivacyPolicyComponent } from "./views/privacy-policy/privacy-policy.component";
// import { TermsnconditionsComponent } from "./views/termsnconditions/termsnconditions.component";
// import { HeaderCmnComponent } from "./common/header-cmn/header-cmn.component";
// import { FaqComponent } from "./views/faq/faq.component";
// import { ImageDetailsComponent } from "./views/image-details/image-details.component";
// import { CheckoutComponent } from "./views/checkout/checkout.component";
// import { BuddingModelsComponent } from "./views/budding-models/budding-models.component";
// import { PhotoeventsListComponent } from "./views/photoevents-list/photoevents-list.component";
// import { EventFormComponent } from "./views/event-form/event-form.component";
// import { MatRadioModule } from "@angular/material/radio";
// import { LoginHeaderComponent } from "./common/login-header/login-header.component";
// import { ExploreComponent } from "./views/explore/explore.component";
// import { CategoriesComponent } from "./views/categories/categories.component";
// import { AboutUsComponent } from "./views/about-us/about-us.component";
// import { HowItWorksComponent } from "./views/how-it-works/how-it-works.component";
// import { TermsOfServiceComponent } from "./views/terms-of-service/terms-of-service.component";
// import { DashboardComponent } from "./views/dashboard/dashboard.component";
// import { SidebarComponent } from "./common/sidebar/sidebar.component";
// import { DbNotificationsComponent } from "./views/db-notifications/db-notifications.component";
// import { DbFooterComponent } from "./common/db-footer/db-footer.component";
// import { DbSavedComponent } from "./views/db-saved/db-saved.component";
// import { DbAddressesComponent } from "./views/db-addresses/db-addresses.component";
// import { DbAddAddressComponent } from "./views/db-add-address/db-add-address.component";
// import { DbEditAddressComponent } from "./views/db-edit-address/db-edit-address.component";
// import { DbAccountDetailsComponent } from "./views/db-account-details/db-account-details.component";
// import { DbOrdersComponent } from "./views/db-orders/db-orders.component";
// import { ViewOrderComponent } from "./views/view-order/view-order.component";
// import { DbContributionsComponent } from "./views/db-contributions/db-contributions.component";
// import { DbPayoutsComponent } from "./views/db-payouts/db-payouts.component";
// import { DbDownloadsComponent } from "./views/db-downloads/db-downloads.component";
// import { DbSubscriptionsComponent } from "./views/db-subscriptions/db-subscriptions.component";
// import { OwnedImagesComponent } from "./views/owned-images/owned-images.component";
// import { ContributeComponent } from "./views/contribute/contribute.component";
// import { BillingDetailsComponent } from "./views/billing-details/billing-details.component";
// import { ContributeStepsComponent } from "./views/contribute-steps/contribute-steps.component";
import { HomeModule } from "./home/home.module";
import { HttpService } from "./services/http/http.service";
import { NgxUiLoaderHttpModule, NgxUiLoaderModule, NgxUiLoaderService } from "ngx-ui-loader";
import { ReactiveFormsModule } from "@angular/forms";
import { UserModule } from "./user/user.module";
import { HelperService } from "./services/helper/helper.service";
// import { HeaderComponent } from "./common/header/header.component";
import { MatSnackBar } from '@angular/material/snack-bar';
import { CookieService } from "ngx-cookie-service";
import { HeaderCmnComponent } from "./common/header-cmn/header-cmn.component";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from '../environments/environment';
import { NgxCaptchaModule } from "ngx-captcha";
import { MatTabGroup } from '@angular/material/tabs';
import { ExploreSimilarTagsComponent } from './home/explore-similar-tags/explore-similar-tags.component';
import { ExploreSearchComponent } from './home/explore-search/explore-search.component';



@NgModule({
    declarations: [
        AppComponent,
        
        
        
        
       
        
        
       
  

    ],
    imports: [
      AngularFireModule.initializeApp(environment.firebase),
      AngularFireAuthModule,
      NgxCaptchaModule,
      ReactiveFormsModule,
        // BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgxUiLoaderModule,
        
        HomeModule,
        BrowserModule.withServerTransition({
          appId: "serverApp"
        }),
        BrowserTransferStateModule,
        
    ],
    
    providers: [{ 
        provide: HTTP_INTERCEPTORS, 
        useClass: HttpService, multi: true,
        
      },
      {
        provide: APP_BASE_HREF, useValue: '/' 
      } ,

      HelperService,
      NgxUiLoaderService, 
      CookieService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
