import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpRequest,
  HttpHandler,
  HttpResponse,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, tap, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { isPlatformBrowser } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';

import {
  Login,
  SnackBarMessage,
  Register,
  CMS,
  AddCookie,
  Pagination,
  register,
  CreateShippingAddress,
  CreatebillingAddress,
  ContactUs,
  notificationSettings,
  removeImageContribution,
  AddToWishList,
  DeleteCheckout,
} from '../../models/model';

// import * as ccavenue from 'ccavenue';

import { CookieService } from 'ngx-cookie-service';
import { EventEmitter } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  rfqData(arg0: { limit: number; offset: number }) {
    throw new Error('Method not implemented.');
  }

  SERVER_URL = environment.SERVER_URL;
  sideBarHeight: number = 0;
  page: any;
  profile: any;
  isLoading: any;

  public updateCartNumber: EventEmitter<any> = new EventEmitter<any>(); //event emitter (addcart 1)----
  public updateNotificationNumber: EventEmitter<any> = new EventEmitter<any>(); //event emitter (notification 1)----
  public updatewishlist: EventEmitter<any> = new EventEmitter<any>();
  public updateUserDetails: EventEmitter<any> = new EventEmitter<any>();
  public servicegetActiveCatUpdate: EventEmitter<any> = new EventEmitter<any>();
  public checkLoggedType: EventEmitter<any> = new EventEmitter<any>();
  user: any = {}

  headers = new HttpHeaders()
  constructor(
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private cookieService: CookieService, public afAuth: AngularFireAuth,
    @Inject(PLATFORM_ID) private platform: Object
  ) {
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getToken()}`
    })
  }

  public updateCartViaNumberApi(json: string): void { //event emitter (addcart 2)----
    this.updateCartNumber.emit(json)
  }
  public updateNotificationViaNumberApi(json: string): void { //event emitter (notification 2)----
    this.updateNotificationNumber.emit(json)
  }
  public updateWishlistApi(json: string): void { //event emitter (notification 2)----
    this.updatewishlist.emit(json)
  }
  public user_details(json: string): void { //event emitter (notification 2)----
    this.updateUserDetails.emit(json)
  }
  public servicegetActiveCat(json: any) {
    this.servicegetActiveCatUpdate.emit(json)
  }
  public LoggedType(json: any) {
    this.checkLoggedType.emit(json)
  }

  objToQueryString(obj: any): string {
    if (!obj) {
      return '';
    }

    const searchParams = new URLSearchParams();
    const params = obj;
    Object.keys(params).forEach((key) => searchParams.append(key, params[key]));

    return searchParams.toString();
  }

  isRequestFromBrowser() {
    if (!isPlatformBrowser(this.platform)) {
      // Need for SSR setup
      return false;
    } else {
      return true;
    }
  }

  getToken() {
    if (!this.isRequestFromBrowser()) {
      // Need for SSR setup
      return null;
    }

    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'yes'
    ) {
      return localStorage.getItem('token');
    }
    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'no'
    ) {
      return sessionStorage.getItem('token');
    } else {
      return null;
    }
  }
  routes = [
    "users/seller/product/search",
    "wp-json/wp/v2/posts"
  ]

  checkIgnoreRoute(routes: Array<any>, url: string): boolean {
    for (let item of routes) {
      if (url.includes(item)) {
        return true;
      }
    }

    return false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const started = Date.now();
    this.loader.start();
    const headers: any = {

    };


    if (!this.checkIgnoreRoute(this.routes, request.url)) {
      headers.session_id = this.getCookie('_session')

    }

    let ok: any;
    const token = this.getToken();

    if (token && !this.checkIgnoreRoute(this.routes, request.url)) {
      // make sure token is coming,
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,

          ...headers,
        },
      });
    } else {
      request = request.clone({
        setHeaders: headers,
      });
    }

    return next.handle(request).pipe(
      tap(
        // Succeeds when there is a response; ignore other events
        (event) => (ok = event instanceof HttpResponse ? 'succeeded' : ''),
        // Operation failed; error is an HttpErrorResponse
        (error) => (ok = error)
      ),
      // Log when response observable either completes or errors
      finalize(() => {
        this.loader.stop();
        const elapsed = Date.now() - started;
        if (ok == 'succeeded') {
          const msg = `${request.method} "${request.urlWithParams}"
          
           ${ok} in ${elapsed} ms.`;
          //  For make sure how much time consumed
        } else {

          if (ok['status'] == 401) {
            window.localStorage.clear();
            window.sessionStorage.clear();
            // logout from here
            // this.commonService.logout();
            this.router.navigate(['/']);

          } else {
            this.handleError(ok);
          }
        }
      })
    );
  }

  isAuthenticated() {
    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'yes'
    ) {
      return localStorage.getItem('isLogged') == 'true';
    }
    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'no'
    ) {
      return localStorage.getItem('isLogged') == 'true';
    } else {
      return false;
    }
  }
  loggedUserDetails() {
    if (!this.isRequestFromBrowser()) {
      // Need for SSR setup
      return null;
    }

    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'yes'
    ) {
      return JSON.parse(localStorage.getItem('user_details') || '{}');
    }
    if (localStorage.getItem('remember_me')) {
      return JSON.parse(sessionStorage.getItem('user_details') || '{}');
    } else {
      return null;
    }
  }

  setLoggedUserDetails(object: any) {
    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'yes'
    ) {
      localStorage.setItem('user_details', JSON.stringify(object));
    }
    if (localStorage.getItem('remember_me')) {
      sessionStorage.setItem('user_details', JSON.stringify(object));
    } else {
    }
  }

  setDataInLocalStorage(
    key: any,
    value: any,
    isObjectOrArray: boolean = false
  ) {
    if (isObjectOrArray) localStorage.setItem(key, value);

    if (isObjectOrArray) localStorage.setItem(key, JSON.stringify(value));

    return true;
  }

  getDataFromLocalStorage(key: any, isObjectOrArray: boolean = false): any {
    if (!localStorage.getItem(key)) return '';

    if (isObjectOrArray) return JSON.parse(localStorage.getItem(key) || '{}');

    return localStorage.getItem(key);
  }

  getDeviceId() {
    if (!localStorage['uid'] || localStorage['uid'] == 'undefined') {
      localStorage['uid'] = uuidv4()
    }
    return localStorage['uid']
  }



  showSuccessMessage(object: SnackBarMessage) {
    this._snackBar.open(
      object.message,
      object.action ? object.action : 'CLOSE',
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['custom-snackbar'],

      }
    );
  }

  showErrorMessage(object: SnackBarMessage) {
    this._snackBar.open(
      object.message,
      object.action ? object.action : 'CLOSE',
      {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['custom-snackbar'],
      }
    );
  }

  updateLoginDetails(details: any) {
    // whenever talent/talent edit name and name will reflect

    if (
      localStorage.getItem('remember_me') &&
      localStorage.getItem('remember_me') == 'yes'
    ) {
      return localStorage.setItem('admin_details', JSON.stringify(details));
    }
    if (localStorage.getItem('remember_me')) {
      return sessionStorage.setItem('admin_details', JSON.stringify(details));
    }
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = '';
    if (error.error && error.error.errors) {
      // client-side error and server side
      if (Array.isArray(error.error.errors.msg)) {
        // validation error message

        if (error.error.errors.msg.length) {
          const ob = error.error.errors.msg[0];
          if (ob.msg == 'IS_EMPTY') {
            errorMessage = `${ob.param} missing`;
          } else {
            errorMessage = ob.msg;
          }
        }
      } else {
        errorMessage = error.error.errors.msg;
      }
    } else {
      // server-side error
      if (error.status == 401) {
        // Unauthorised
        // this.commonService.logout();
        this.router.navigate(['/']);
      }
      else if (error.status === 429) {
        errorMessage = `Subscription Expired/ Limit Exceeded`;
      }
      else if (error.status == 430) {
        errorMessage = 'You Are not subscribed to this plan'
      }
      else
        errorMessage = `Something went wrong here`;
      // errorMessage = `${error.message}`;
    }
    // if (this.bulkUpload(error)) {
    //   return;
    // }
    this.showErrorMessage({
      message: errorMessage,
    });
    return throwError(errorMessage);
  }

  // coockies start---
  setCookies(obj: any) {
    this.cookieService.set(obj.key, obj.value)
  }

  getCookie(key: string): string {
    if (key == '_session' && !this.cookieService.get(key)) {
      this.setCookies({
        key: "_session",
        value: uuidv4()
      })
    }
    return this.cookieService.get(key);
  }
  deleteCookie(key: string): void {
    this.cookieService.delete(key);
  }
  // coockies end---


  //login
  login_data(data: Login): Observable<any> {
    const API_URL = `${this.SERVER_URL}login`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addShippingAddress(data: CreateShippingAddress): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/create/address`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addBillingAddress(data: CreatebillingAddress): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/create/address`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  editShippingAddress(data: CreateShippingAddress): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/update/address`;
    return this.http.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  editBillingAddress(data: CreatebillingAddress): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/update/address`;
    return this.http.patch(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  GetbillingAddress(data = 'billing'): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/address?type=${data}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }



  GetShippingAddress(data = 'shipping'): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/address?type=${data}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  register(data: Register): Observable<any> {
    const API_URL = `${this.SERVER_URL}register`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  privacyPolicy(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/cms/PrivacyPolicy`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  termOfService(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/cms/TermsofService`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  termAndCondition(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/cms/TermsAndConditions`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  // https://developers.promaticstechnologies.com:3008/users/get/faqs

  faq(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/faqs`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  categories(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/categories`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  imageTypes(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/image/types`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  // uploadCollection(data: any): Observable<any> {
  //   const API_URL = `${this.SERVER_URL}users/upload/single/collection`;
  //   return this.http.post(API_URL, data).pipe(
  //     map((res) => {
  //       return res;
  //     })
  //   );
  // }

  uploadCollection(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/upload/single/collection`;
    return this.http.post(API_URL, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((res) => {
        return res;
      })
    );
  }
  uploadCollectionVector(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/upload/vector/image`;
    return this.http.post(API_URL, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getUserCollection(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/my/contributes?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getUserProfile(data: any = {}): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/profile?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  updateUserProfile(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/profile`;
    return this.http.put(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  deleteUser(type: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/delete/user/${type}`;
    return this.http.delete(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCategoriesWithImages(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/categories/images?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getHomePageContent(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/home/page/content?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getMyShoots(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/my/shoots?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  createNewShoot(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/create/shoot`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  contactUs(data: ContactUs): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/create/contact/us`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  contactUsForShoot(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/request/custom/shoot`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getUserShootImage(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/shoot/images/${data.shoot_id}?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getAboutUs(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/about/us?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }


  getNotifiactionSetting(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/notification/settings`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    );
  }

  updateNotificationSetting(data: notificationSettings): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/update/notification/settings`;
    return this.http.patch(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  // My Contributuions
  reasonContributesApi(data: removeImageContribution): Observable<any> {
    const API_URL = `${this.SERVER_URL}`; //this work is still pending..
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  // Pricing home page----
  getSubscriptionPlan(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/getSubscriptionPlan`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getPricing(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/getSingleImagePrices`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getPricing1(obj: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/getSingleImagePrices?ids=${obj.ids}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  buyRights(obj: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/buy/exclusive/rights`;
    return this.http.post(API_URL, obj).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  addwhishlist(data: AddToWishList): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/add/wishlist`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      }))
  }

  getWishList(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/wishlist`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      }))
  }
  deleteWishList(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/delete/wishlist/${id}`;
    return this.http.delete(API_URL).pipe(
      map((res: any) => {
        return res
      }))
  }

  addCart(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/add/cart`;
    return this.http.post(API_URL, id).pipe(
      map((res: any) => {
        return res
      }))
  }

  getCart(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/cart`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      }))
  }

  deleteCartItem(obj: DeleteCheckout): Observable<any> {
    const query = this.objToQueryString(obj)
    const API_URL = `${this.SERVER_URL}users/remove/from/cart?${query}`;
    return this.http.delete(API_URL).pipe(

      map((res: any) => {
        return res
      }))
  }



  getImageDetail(id: any): Observable<any> {
    const user = JSON.parse(localStorage.getItem('user_details') || '{}');
    let url = `users/get/image/details/${id}`
    if (Object.keys(user).length !== 0)
      url =`users/get/image/details/${id}?user_id=${user._id}`
    const API_URL = `${this.SERVER_URL}${url}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCollectionImages(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/collection/images/${id}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  saveUnsaveImage(obj: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/save/unsave/image`;
    return this.http.post(API_URL, obj).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getExclusiveOffers(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/exclusive/offers`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  placeOrder(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/place/order`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  ownedImages(obj: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/owned/images?limit=${obj.limit}&offset=${obj.offset}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getOrders(obj: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/orders?limit=${obj.limit}&offset=${obj.offset}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getOrderdetails(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/order/items/${id.order_id}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getEvents(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/events`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getSingleEvents(id: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/get/event/${id.event_id}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  addEvent(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/apply/event`;
    return this.http.post(API_URL, data).pipe(
      map((res) => {
        return res;
      })
    );
  }
  searchImages(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/images?${query}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getCategoryBySlug(data: any): Observable<any> {

    const API_URL = `${this.SERVER_URL}users/category/${data}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getImages(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/images?offset=${data.offset}&limit=${data.limit}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }

  purchaseSubscription(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/purchase/subscription`;
    return this.http.post(API_URL, data).pipe(map((res) => {
      return res;
    })
    );
  }

  getImagesByUser(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/images?contributor_id=${data.contributor_id}&offset=${data.offset}&limit=${data.limit}`;
    return this.http.get(API_URL).pipe(

      map((res) => {
        return res;
      })
    );
  }
  // getImagesCategoriesKeywordAndSearch(data:any): Observable<any>{
  //  if(data.categories_ids){
  //   const API_URL = `${this.SERVER_URL}users/images?search=${data.search}&categories_ids=${data.categories_ids}&keywords=${data.keywords}`;
  //   return this.http.get(API_URL).pipe(

  //     map((res) => {
  //       return res;
  //     })
  //   );
  //  }else{
  //   const API_URL = `${this.SERVER_URL}users/images?search=${data.search}&keywords=${data.keywords}`;
  //   return this.http.get(API_URL).pipe(

  //     map((res) => {
  //       return res;
  //     })
  //   );
  //  }


  // }
  // getImagesCategoriesAndSearch(data:any): Observable<any>{
  //   if(data.categories_ids){
  //     const API_URL = `${this.SERVER_URL}users/images?search=${data.search}&categories_ids=${data.categories_ids}`;
  //     return this.http.get(API_URL).pipe(

  //       map((res) => {
  //         return res;
  //       })
  //     );
  //    }else{
  //     const API_URL = `${this.SERVER_URL}users/images?search=${data.search}`;
  //     return this.http.get(API_URL).pipe(

  //       map((res) => {
  //         return res;
  //       })
  //     );
  //    }
  // }

  getImagesByFilter(data: any): Observable<any> {
    if (data.categories_ids && data.search) {
      const API_URL = `${this.SERVER_URL}users/images?search=${data.search}&keywords=${data.keywords}&categories_ids=${data.categories_ids}`;
      return this.http.get(API_URL).pipe(

        map((res) => {
          return res;
        })
      );
    } if (data.search && !data.categories_ids) {
      const API_URL = `${this.SERVER_URL}users/images?search=${data.search}&keywords=${data.keywords}`;
      return this.http.get(API_URL).pipe(

        map((res) => {
          return res;
        })
      );
    } if (!data.search && data.categories_ids) {
      const API_URL = `${this.SERVER_URL}users/images?keywords=${data.keywords}&categories_ids=${data.categories_ids}`;
      return this.http.get(API_URL).pipe(

        map((res) => {
          return res;
        })
      );
    } else {
      const API_URL = `${this.SERVER_URL}users/images?keywords=${data.keywords}`;
      return this.http.get(API_URL).pipe(

        map((res) => {
          return res;
        })
      );
    }

  }

  getImagesByModal(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/images?${query}`;
    return this.http.get(API_URL).pipe(

      map((res) => {
        return res;
      })
    );
  }
  getSimilarImages(data: any): Observable<any> {
    const query = this.objToQueryString(data)

    const API_URL = `${this.SERVER_URL}users/images?${query}`;
    return this.http.get(API_URL).pipe(

      map((res) => {
        return res;
      })
    );
  }

  getSubscriptionDetail(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/my/subscription`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getSubscriptionPlanStatus(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/fetch/my/subscription/status`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  
  geDownloadImages(obj: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/downloaded/images/list?limit=${obj.limit}&offset=${obj.offset}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  billingDetails(object: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/payments?search=${object.search}&limit=${object.limit}&offset=${object.offset}`;
    return this.http.get(API_URL).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getNotifications(form: any): Observable<any> {
    // const API_URL = `${this.SERVER_URL}users/notifications?seen=${form.seen}&limit=${form.limit}&offset=${form.offset}`;
    const API_URL = `${this.SERVER_URL}users/notifications?limit=${form.limit}&offset=${form.offset}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  AuthLogin(provider: any) {
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        return result

      })
      .catch((error) => {
        return error;
      });
  }

  keyFilters(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/keywords/with/categories`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }




  socialLogin(form: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}social/login`;
    return this.http.post(API_URL, form).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getModels(form: any): Observable<any> {
    const query = this.objToQueryString(form)
    const API_URL = `${this.SERVER_URL}users/models?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  payment(form: any): Observable<any> {
    const query = this.objToQueryString(form)
    const API_URL = `${this.SERVER_URL}users/payment/link?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  relatedKeywordByImage(form: any): Observable<any> {
    const query = this.objToQueryString(form)
    const API_URL = `${this.SERVER_URL}users/related/keywords?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getPriceofExclusiverightsOfImage(form: any): Observable<any> {
    const query = this.objToQueryString(form)
    const API_URL = `${this.SERVER_URL}users/exclusive/rights/image/price?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getPaymentLinkOfExclusive(form: any): Observable<any> {
    const query = this.objToQueryString(form)
    const API_URL = `${this.SERVER_URL}users/get/exclusive/rights/image/paymentlink?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  ImaeRemoveRequest(form: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/remove/request/from/platform`;
    return this.http.post(API_URL, form).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  forgotPassword(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}forgot`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  resetPassword(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}reset`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getContactusContent(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/content/contact_us`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getPaymentLinkOfSubscription(form: any): Observable<any> {
    const query = this.objToQueryString(form)
    const API_URL = `${this.SERVER_URL}users/get/subscription/payment/link?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  newsLetter(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/subscribe/newsletter`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  seeAllNotification(data: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/seen/all/notifications`;
    return this.http.post(API_URL, data).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getblogs(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `https://blog.nustockimages.com/wp-json/wp/v2/posts?${query}&_embed&`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getLink(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/phonePay/payment/link/onetime?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getCountries(): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/getAllCountries`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getStates(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/getStatesByCountryCode?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  requestImageConversion(data: any): Observable<any> {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/sendemailforrequest?${query}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  getSEO(data: any) {
    const query = this.objToQueryString(data)
    const API_URL = `${this.SERVER_URL}users/category/${data}`;
    return this.http.get(API_URL).pipe(
      map((res: any) => {
        return res
      })
    )
  }


  buySubscription(plan: any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/create/subscription`;
    return this.http.post(API_URL, plan, { headers: this.headers }).pipe(
      map((res: any) => {
        return res
      })
    )
  }


  downloadImage(downloadImg: any) {
    const API_URL = `${this.SERVER_URL}users/download/subscription/image`;
    return this.http.post(API_URL, downloadImg, { headers: this.headers, responseType: 'blob' }).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  refreshPlan(planId:any): Observable<any> {
    const API_URL = `${this.SERVER_URL}users/update/my/subscription/status/${planId}`;
    return this.http.put(API_URL,{}).pipe(
      map((res: any) => {
        return res
      })
    )
  }
  

}  
