<header class="header">
    <!-- Top Bar -->
    <!-- Header Main -->
    <div class="header_main">
        <div class="custom_container">
            <nav class="navbar navbar-expand-lg navbar-dark bg-transparent fixed-top" id="nav_main">
                <a class="navbar-brand main_logo d-lg-none" href="#">
                    <img [src]="logoPath" alt="Nu Stock" routerLink="/" />
                </a>
                <span class="side_btn" style="font-size:30px;cursor:pointer" (click)="openNav('open')">&#9776;</span>
                <div class="navOverlay" *ngIf="showSidebar" (click)="openNav('close')"></div>

                <div id="mySidenav" class="sidenav" [ngClass]="showSidebar  ? 'widthfull' : 'wdthhalf'">

                    <span class="closebtn" (click)="openNav('close')">&times;</span>

                    <div id="sider-account-dtl">
                        <ul class="navbar-nav my-2 my-lg-0">
                            <li class="nav-item">
                                <a class="navbar-brand main_logo d-none" routerLink="/">
                                    <img [src]="logoPath" alt="Nu Stock" />
                                </a>
                            </li>

                            <li class="nav-item d-flex justify-content-start align-items-center">
                                <a class="nav-link" routerLink="/about-us">About Us </a>
                                <a class="nav-link" routerLink="/photoEventsList">Events</a>
                            </li>

                        </ul>

                        <div class="sign_btn sign-account-header text_signncolo">
                            <ul class="d-flex flex-column">
                                <li class="">
                                    <ng-container *ngIf="!isLoggedUser || this.user==null">
                                        <a href="#" class="rt_nav" data-toggle="modal" data-target="#sign_modl">
                                            <span class="sign_lck mr-2"><i class="fa fa-lock"></i></span>
                                            <span class="clr_chng" (click)="reset()">Login/Signup</span>
                                        </a>
                                    </ng-container>

                                    <ng-container *ngIf="isLoggedUser && this.user != null">
                                        <div class="dropdown ">

                                            <a class="text-white" href="#" data-toggle="dropdown" class="rt_nav">
                                                <span class="sign_lck mr-2"><i class="fa fa-user"></i></span>
                                                <span class="clr_chng" *ngIf="user && user.first_name"> {{ (
                                                    !user.display_name ||user.display_name === null) ? user.first_name :
                                                    user.display_name}} </span>
                                                <span class="clr_chng" *ngIf="user && !user.first_name">User</span>
                                            </a>
                                            <div class="dropdown-menu user_drpdwn">
                                                <a *ngIf="this.user?.contribution == true" class="dropdown-item"
                                                    routerLink="/user/dashboard">
                                                    <mat-icon> dashboard </mat-icon>
                                                    <span class="down_dhas">Dashboard</span>
                                                </a>
                                                <!-- <a class="dropdown-item" routerLink="/user/contribute">
                                                                                <mat-icon> add_circle </mat-icon>
                                                                                <span>Contributions</span>
                                                                            </a> -->
                                                <a class="dropdown-item" routerLink="/user/downloads">
                                                    <mat-icon> file_download </mat-icon>
                                                    <span class="down_dhas">Downloads</span>
                                                </a>
                                                <a class="dropdown-item" routerLink="/user/db-orders">
                                                    <mat-icon> library_add_check </mat-icon>
                                                    <span class="down_dhas">Orders</span>
                                                </a>
                                                <a class="dropdown-item" routerLink="/user/saved">
                                                    <mat-icon> bookmark </mat-icon>
                                                    <span class="down_dhas">Saved</span>
                                                </a>
                                                <div class="dropdown-divider"></div>
                                                <a class="dropdown-item" routerLink="/user/view-profile">
                                                    <mat-icon> style </mat-icon>
                                                    <span class="down_dhas">Account details</span>
                                                </a>
                                                <a class="dropdown-item" [style.cursor]="'pointer'">
                                                    <mat-icon> lock </mat-icon>
                                                    <span (click)="logout()" class="down_dhas">Logout</span>
                                                </a>
                                            </div>
                                        </div>
                                    </ng-container>

                                </li>
                                <li>
                                    <div class="dropdown ntfctn_dropdown">
                                        <a href="#" data-toggle="dropdown" class="rt_nav" (click)="showDropdown=true"
                                            (click)="seenNotifications()">
                                            <span class="d-flex ">
                                                <span class="sign_lck "><i class="fas fa-bell"></i></span>
                                                <span class="ml-2">Notification</span>
                                            </span>

                                        </a>

                                        <div class="divshop" *ngIf="this.unseenNotification != 0">
                                            <p>{{unseenNotification}}</p>
                                        </div>
                                        <div class="dropdown-menu user_drpdwn dropdown-menu-right"
                                            [ngClass]="{'show': showDropdown}">
                                            <div class="ntfctn_hdng dropdown-header">
                                                <h6 class="mb-0">Notifications</h6>
                                            </div>
                                            <div class="dropdwn_ntfctns">
                                                <a class="dropdown-item" href="#"
                                                    *ngFor="let notification of notificationDatalist">
                                                    <div class="ntfn_img">
                                                        <img src="https://images.pexels.com/photos/38196/pexels-photo-38196.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                            class="img-fluid" />
                                                    </div>
                                                    <div class="ntfc_cntnt">
                                                        <p class="mn_ntfcn">
                                                            {{notification?.title}}
                                                        </p>
                                                        <p class="ntfctn_tm">
                                                            {{notification?.updatedAt | date:'medium'}}
                                                        </p>
                                                    </div>
                                                    <div class="ntfctn_status">
                                                        <span></span>
                                                    </div>
                                                </a>


                                            </div>

                                            <div class="ntfctn_more dropdown-footer">
                                                <button class="btn btn_theme_filled" type="button"
                                                    (click)="paginate()">Load more</button>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li class="rnv_link">
                                    <a (click)="navigate()" routerLink="/checkout" class="rt_nav">
                                        <span class="d-flex">
                                            <span class="sign_lck"><i class="fas fa-shopping-cart"></i></span>
                                            <span class="ml-2">Checkout</span>
                                        </span>

                                    </a>
                                    <div class="divshop" *ngIf="this.cartlength != 0">
                                        <p>{{this.cartlength}}</p>
                                    </div>
                                </li>

                            </ul>
                        </div>

                        <div class="cntrbt_btn my-4">
                            <a (click)="contribute()" class="btn hdr_btn_outline">Contribute</a>
                        </div>
                    </div>
                    <div class="">
                        <!-- <h3 class="mt-1 ml-3 text-white">Categories</h3> -->
                    </div>
                    <!-- routerLink="/user/Explore/{{ cat._id }}/categoryImages" (click)="getCat(cat._id,cat.category_name)" -->

                    <!-- ---Catagories-Names--- -->

                    <a class="mouse " *ngFor="let cat of all_categories"
                        [ngClass]="activeMainCategory === cat._id ? 'active' : ''"
                       [routerLink]="['/stock-photos',cat?.category_slug]">
                        {{ cat.category_name }}
                    </a>


                    <!-- <ul class="sub_categories" *ngFor="let cat of all_categories"
                        [ngStyle]="{'display': activeMainCategory === cat._id ? 'block' : 'none'}">

                        <div class="sub_categories_header mb-4">
                            <span class="mouse p-0 pb-3" (click)="toggleSubCategoryMenu()">
                                <span class="close-side-drawer-button text-white">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14.022" height="15.475"
                                        viewBox="0 0 14.022 15.475" class="backto-main-menu">
                                        <path id="Polygon_1aa" data-name="Polygon 1aa" d="M8.626,14.719.889.7H16.364Z"
                                            transform="translate(14.719 -0.889) rotate(90)"></path>
                                    </svg>
                                    <span class="main-menu-side-bar sectiondata" style="font-size: 17px;">
                                        <b>Categories
                                            Menu</b></span>
                                </span>
                            </span>
                        </div>
                        <li class="sub_cata_list active">{{ cat.category_name }}</li>
                        <li class="sub_cata_list">
                            <a class="mouse nav_links" [routerLink]="['/stock-photos',cat?.category_slug]">View All</a>
                        </li>
                        <li class="sub_cata_list" *ngFor="let subCat of cat.sub_category">

                            <a class="mouse nav_links" [routerLink]="['/stock-photos',cat?.category_slug,subCat]">
                                {{ subCat}}
                            </a>
                        </li>

                    </ul> -->

                </div>
               
                <div class="collapse navbar-collapse" id="navbarScroll">
                    <ul class="navbar-nav my-2 my-lg-0">
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/about-us">About Us </a>
                        </li>
                        <!-- <li class="nav-item active">
                            <div class="dropdown">
                                <a class="nav-link" href="#" data-toggle="dropdown">Menu</a>
                                
                                <div class="dropdown-menu user_drpdwn">
                                    <a class="dropdown-item" href="#">
                                        <span>Contact</span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <span>About us</span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <span>Pricing</span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <span>FAQ</span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <span>Contribute</span>
                                    </a>
                                    <a class="dropdown-item" href="#">
                                        <span>My account</span>
                                    </a>
                                </div>
                            </div>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" href="#">Blog</a>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" routerLink="/pricing">Pricing</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" routerLink="/photoEventsList">Events</a>
                        </li>
                    </ul>
                    <a class="navbar-brand main_logo mx-auto d-none d-lg-block" routerLink="/">
                        <img [src]="logoPath" alt="Nu Stock" />
                    </a>
                    <div class="sign_btn text_signncolo">
                        <ul class="d-flex test">
                            <li>
                                <!-- <div class="dropdown ntfctn_dropdown">
                                    <a href="#" data-toggle="dropdown" class="rt_nav" (click)="showDropdown=true"
                                        (click)="seenNotifications()">
                                        <span class="sign_lck"><i class="fas fa-bell"></i></span>
                                    </a>
                                    <div class="divshop" *ngIf="this.unseenNotification != 0">
                                        <p>{{unseenNotification}}</p>
                                    </div>
                                    <div class="dropdown-menu user_drpdwn dropdown-menu-right"
                                        [ngClass]="{'show': showDropdown}">
                                        <div class="ntfctn_hdng dropdown-header">
                                            <h6 class="mb-0">Notifications</h6>
                                        </div>
                                        <div class="dropdwn_ntfctns">
                                            <a class="dropdown-item" href="#"
                                                *ngFor="let notification of notificationDatalist">
                                                <div class="ntfn_img">
                                                    <img src="https://images.pexels.com/photos/38196/pexels-photo-38196.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                                        class="img-fluid" />
                                                </div>
                                                <div class="ntfc_cntnt">
                                                    <p class="mn_ntfcn">
                                                        {{notification?.title}}
                                                    </p>
                                                    <p class="ntfctn_tm">
                                                        {{notification?.updatedAt | date:'medium'}}
                                                    </p>
                                                </div>
                                                <div class="ntfctn_status">
                                                    <span></span>
                                                </div>
                                            </a>


                                        </div>

                                        <div class="ntfctn_more dropdown-footer">
                                            <button class="btn btn_theme_filled" type="button" (click)="paginate()">Load
                                                more</button>
                                        </div>
                                    </div>
                                </div> -->
                            </li>
                            <li class="rnv_link">
                                <a (click)="navigate()" routerLink="/checkout" class="rt_nav">
                                    <span class="sign_lck"><i class="fas fa-shopping-cart"></i></span>
                                </a>
                                <div class="divshop" *ngIf="this.cartlength != 0">
                                    <p>{{this.cartlength}}</p>
                                </div>
                            </li>
                            <li>
                                <ng-container *ngIf="!isLoggedUser || this.user==null">
                                    <a class="rt_nav" data-toggle="modal" data-target="#sign_modl">
                                        <span class="sign_lck mr-2"><i class="fa fa-lock"></i></span>
                                        <span class="clr_chng" (click)="reset()">Login/Signup</span>
                                    </a>
                                </ng-container>

                                <ng-container *ngIf="isLoggedUser && this.user != null">
                                    <div class="dropdown">

                                        <a class="text-white" href="#" data-toggle="dropdown" class="rt_nav">
                                            <span class="sign_lck mr-2" *ngIf="preview_files.profile">
                                                <i>
                                                    <img [src]="preview_files.profile" alt="Profile image of user"
                                                        class="ProfilePic">
                                                </i>
                                            </span>

                                            <span class="clr_chng" *ngIf="user && user.first_name"> {{ (
                                                !user.display_name ||user.display_name === null) ? user.first_name :
                                                user.display_name}} </span>
                                            <span class="clr_chng" *ngIf="user && !user.first_name">User</span>
                                        </a>
                                        <div class="dropdown-menu user_drpdwn">
                                            <a *ngIf="this.user?.contribution == true" class="dropdown-item"
                                                routerLink="/user/dashboard">
                                                <mat-icon> dashboard </mat-icon>
                                                <span class="down_dhas">Dashboard</span>
                                            </a>
                                            <!-- <a class="dropdown-item" routerLink="/user/contribute">
                                                                            <mat-icon> add_circle </mat-icon>
                                                                            <span>Contributions</span>
                                                                        </a> -->
                                            <a class="dropdown-item" routerLink="/user/downloads">
                                                <mat-icon> file_download </mat-icon>
                                                <span class="down_dhas">Downloads</span>
                                            </a>
                                            <a class="dropdown-item" routerLink="/user/db-orders">
                                                <mat-icon> library_add_check </mat-icon>
                                                <span class="down_dhas">Orders</span>
                                            </a>
                                            <a class="dropdown-item" routerLink="/user/saved">
                                                <mat-icon> bookmark </mat-icon>
                                                <span class="down_dhas">Saved</span>
                                            </a>
                                            <div class="dropdown-divider"></div>
                                            <a class="dropdown-item" routerLink="/user/view-profile">
                                                <mat-icon> style </mat-icon>
                                                <span class="down_dhas">Account details</span>
                                            </a>
                                            <a class="dropdown-item" [style.cursor]="'pointer'">
                                                <mat-icon> lock </mat-icon>
                                                <span (click)="logout()" class="down_dhas">Logout</span>
                                            </a>
                                        </div>
                                    </div>
                                </ng-container>

                            </li>
                        </ul>
                    </div>

                    <!-- <div class="cntrbt_btn">
                        <a (click)="contribute()" class="btn hdr_btn_outline">Contribute</a>
                    </div> -->
                </div>
            </nav>
        </div>
    </div>
</header>
<!-- Modal -->
<div class="modal fade sign_modal" id="sign_modl" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-7">
                        <div class="sign_img">
                            <img src="https://images.pexels.com/photos/1438081/pexels-photo-1438081.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                                class="img-fluid" />
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="sign_tabs">
                            <div class="modl_close">
                                <button type="button" class="btn btn_close close" #refModal data-dismiss="modal"
                                    aria-label="Close" (click)="reset()">
                                    <i class="fas fa-times"></i>
                                </button>
                            </div>
                            <ul class="nav nav-pills mb-3 sign_tabs_ul" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-toggle="pill" href="#sign_in" role="tab"
                                        (click)="reset()">Login</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="pill" href="#sign_up" role="tab"
                                        (click)="reset()">Create Account</a>
                                </li>
                            </ul>
                            <div class="tab-content" id="pills-tabContent">
                                <div class="tab-pane fade show active" id="sign_in" role="tabpanel">
                                    <div class="signin_wrap">
                                        <div class="sign_show" [hidden]="isHide">
                                            <div class="signin_cntnt">
                                                <h3>Welcome back.</h3>
                                                <div class="socl_btns">
                                                    <a class="btn btn_theme_filled btn_grey mb-3"
                                                        (click)="GoogleAuth()">
                                                        <span class="btn_img mr-2"><img src="assets/images/search.png"
                                                                class="img-fluid" /></span>
                                                        <span type="button">Continue with
                                                            Google</span>
                                                    </a>
                                                    <!-- <a class="btn btn_theme_filled btn_grey" (click)="FacebookAuth()">
                                                        <span class="btn_img mr-2"><img src="assets/images/facebook.png"
                                                                class="img-fluid" /></span>
                                                        <span type="button">Continue with
                                                            Facebook</span>
                                                    </a> -->
                                                </div>
                                                <div class="or_div">
                                                    <span>OR</span>
                                                </div>
                                                <form class="sign_flds" [formGroup]="form2">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" placeholder="Email"
                                                            formControlName="email" />
                                                        <div
                                                            *ngIf="form2.controls['email'].touched && this.submitted == true">
                                                            <p class="danger err-msg"
                                                                *ngIf="!form2.controls['email'].valid && (form2.controls['email'].hasError('required'))">
                                                                Email is required*</p>
                                                            <p *ngIf="form2.controls['email'].hasError('pattern')"
                                                                class="danger err-msg"> Email is not valid*
                                                            </p>
                                                        </div>
                                                        <!-- <p *ngIf="form2.controls['email'].errors.email" class="alert alert-danger"> 
                                                        </p> Email is not valid -->
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="divppass">
                                                            <input type="password" class="form-control"
                                                                placeholder="Password" formControlName="password"
                                                                type="password" id="pswd" />

                                                            <p class="danger err-msg"
                                                                *ngIf="form2.controls['password'].hasError('required')  && (form2.controls['password'].touched)">
                                                                Password is required*</p>
                                                            <div class="diviconfa">
                                                                <span class="sign_lck mr-2">
                                                                    <i *ngIf="!this.show" class="fa fa-eye"
                                                                        aria-hidden="true"
                                                                        (click)="showPassword('pswd')"></i>
                                                                    <i *ngIf="this.show" class="fa fa-eye-slash"
                                                                        aria-hidden="true"
                                                                        (click)="showPassword('pswd')"></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="btnWrapper">

                                                        <div class="sign_actn pr-4">
                                                            <button type="submit" (click)="login()"
                                                                class="btn btn_theme_filled btn_grey">Login</button>
                                                        </div>

                                                        <div class="lost_pswrd text-center btnWrapper__login">
                                                            <a (click)="
                                                                    toggleDisplay()
                                                                " class="mb-0 btnWrapper__login--fogot">
                                                                <span class="mr-2"><i class="fa fa-lock"></i></span>
                                                                <span>Forgot password?</span>
                                                            </a>
                                                        </div>
                                                       
                                                    </div>

                                                   
                                                </form>
                                            </div>
                                            
                                        </div>
                                        <div class="rstpswd_show" [hidden]="isShow">
                                            <div class="signin_cntnt">
                                                <h3>
                                                    Request a password reset.
                                                </h3>
                                                <p>
                                                    Please enter your email
                                                    address. You will receive a
                                                    link to create a new
                                                    password via email.
                                                </p>
                                                <div class="sign_flds">
                                                    <div class="form-group">
                                                        <input type="email" class="form-control" placeholder="Email"
                                                            [formControl]="textInputFormControl" />
                                                    </div>
                                                    <div class="lospass">
                                                        <div class="lost_pswrd text-center">
                                                            <a (click)="
                                                                    toggleDisplay()
                                                                " class="lospass__btn">
                                                                <span class="mr-2"><i class="fa fa-arrow-left"></i></span>
                                                                Back to Sign
                                                                    in
                                                            </a>
                                                        </div>
                                                        <div class="sign_actn">
                                                            <button class="btn btn_theme_filled btn_grey"
                                                                [disabled]="!textInputFormControl" (click)="onSubmit()">Reset
                                                                password</button>
                                                        </div>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="sign_up" role="tabpanel">
                                    <div class="signin_wrap">
                                        <div class="signin_cntnt">
                                            <h3>Create your account.</h3>
                                            <div class="socl_btns">
                                                <a class="btn btn_theme_filled btn_grey mb-3" (click)="GoogleAuth()">
                                                    <span class="btn_img mr-2"><img src="assets/images/search.png"
                                                            class="img-fluid" /></span>
                                                    <span>Continue with
                                                        Google</span>
                                                </a>
                                                <!-- <a class="btn btn_theme_filled btn_grey" (click)="FacebookAuth()">
                                                    <span class="btn_img mr-2"><img src="assets/images/facebook.png"
                                                            class="img-fluid" /></span>
                                                    <span>Continue with
                                                        Facebook</span>
                                                </a> -->
                                            </div>
                                            <div class="or_div">
                                                <span>OR</span>
                                            </div>
                                            <form class="sign_flds" [formGroup]="form1">
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <input type="email" class="form-control" placeholder="Email"
                                                            formControlName="email" />
                                                        <div *ngIf="form1.controls['email'].touched">
                                                            <p class="danger err-msg"
                                                                *ngIf="!form1.controls['email'].valid && (form1.controls['email'].hasError('required'))">
                                                                Email is required*</p>
                                                            <p *ngIf="form1.controls['email'].hasError('pattern') "
                                                                class="danger err-msg"> Email is not valid*
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <input type="text" class="form-control"
                                                            placeholder="Phone Number" maxlength="10"
                                                            formControlName="phone" (keypress)="numberOnly($event)" />

                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.phone.errors?.required   && (form1.controls['phone'].dirty || form1.controls['phone'].touched)">
                                                            Phone number is required*</p>
                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.phone.errors?.pattern   && (form1.controls['phone'].dirty || form1.controls['phone'].touched)">
                                                            Invalid Phone number*</p>
                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.phone.errors?.minlength   && (form1.controls['phone'].dirty || form1.controls['phone'].touched)">
                                                            Please enter 10 numbers*</p>


                                                    </div>
                                                </div>
                                                <div class="form-row">
                                                    <div class="form-group col-md-6">
                                                        <input type="text" class="form-control" placeholder="First Name"
                                                            formControlName="first_name"
                                                            oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" />

                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.first_name.errors?.required   && (form1.controls['first_name'].dirty || form1.controls['first_name'].touched)">
                                                            First Name is required*</p>
                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.first_name.errors?.pattern   && (form1.controls['first_name'].dirty || form1.controls['first_name'].touched)">
                                                            Invalid Field*</p>

                                                    </div>
                                                    <div class="form-group col-md-6">
                                                        <input type="text" class="form-control" placeholder="Last Name"
                                                            formControlName="last_name"
                                                            oninput="this.value = !this.value.trim() ? this.value.trim() : this.value" />
                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.last_name.errors?.required   && (form1.controls['last_name'].dirty || form1.controls['last_name'].touched)">
                                                            Last Name is required*</p>
                                                        <p class="danger err-msg"
                                                            *ngIf="form1.controls.last_name.errors?.pattern   && (form1.controls['last_name'].dirty || form1.controls['last_name'].touched)">
                                                            Invalid Field*</p>

                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <div class="divppass">
                                                        <input type="password" class="form-control"
                                                            placeholder="Password" formControlName="password"
                                                            id="pswd1" />
                                                        <div *ngIf="form1.controls['password'].touched">
                                                            <p class="danger err-msg "
                                                                *ngIf="form1.controls['password'].hasError('required')  && !form1.controls['password2'].valid ">
                                                                Password is required*</p>
                                                            <p class="danger err-msg "
                                                                *ngIf="form1.controls['password'].hasError('pattern') ">
                                                                The password must be at least 8 characters long, and
                                                                contain at least 1 capital letter, 1 lowercase letter
                                                                and 1 numeric character.*
                                                            </p>
                                                        </div>
                                                        <div class="diviconfa">
                                                            <span class="sign_lck mr-2">
                                                                <i *ngIf="!this.show1" class="fa fa-eye"
                                                                    aria-hidden="true"
                                                                    (click)="showPassword('pswd1')"></i>
                                                                <i *ngIf="this.show1" class="fa fa-eye-slash"
                                                                    aria-hidden="true"
                                                                    (click)="showPassword('pswd1')"></i>
                                                            </span>
                                                        </div>

                                                    </div>

                                                </div>
                                                <div class="form-group">
                                                    <div class="divppass">
                                                        <input type="password" class="form-control"
                                                            placeholder="Confirm Password" formControlName="password2"
                                                            id="pswd2" />


                                                        <div *ngIf="form1.controls['password2'].touched">
                                                            <p class="danger err-msg "
                                                                *ngIf="!form1.controls['password2'].valid && (form1.controls['password2'].hasError('required'))">
                                                                Please
                                                                confirm password*</p>
                                                            <p class="danger err-msg "
                                                                *ngIf="form1.hasError('pwdNotSame')">
                                                                Confirm passwords don't match*
                                                            </p>
                                                        </div>
                                                        <div class="diviconfa">
                                                            <span class="sign_lck mr-2">
                                                                <i *ngIf="!this.show2" class="fa fa-eye"
                                                                    aria-hidden="true"
                                                                    (click)="showPassword('pswd2')"></i>
                                                                <i *ngIf="this.show2" class="fa fa-eye-slash"
                                                                    aria-hidden="true"
                                                                    (click)="showPassword('pswd2')"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="form-group">
                                                    <div class="form-check terms_check">
                                                        <input class="form-check-input" type="checkbox"
                                                            formControlName="check" />
                                                        <label class="form-check-label">
                                                            Please indicate that you have read and agree to the <span><a
                                                                    href="/terms-of-service"><u>Terms and
                                                                        Conditions</u></a> and <a
                                                                    href="/privacy-policy"><u>Privacy
                                                                        Policy</u></a></span>
                                                        </label>

                                                        <div *ngIf="form1.controls['check'].touched">
                                                            <p class="danger err-msg "
                                                                *ngIf="form1.controls['check'].hasError('required')">
                                                                terms and
                                                                conditions is required*
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </form>

                                        </div>
                                        <div class="sign_actn pr-4">
                                            <button type="submit" class="btn btn_theme_filled btn_grey"
                                                (click)="submit()">Create Account</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>